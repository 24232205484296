import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '@app/models/generic-select.model';
import {LookupsDataService} from '@services/lookups-data.service';
import {ContextModel} from '@app/models/context.model';
import {EnvironmentsService} from '@services/environments.service';
import {ContextService} from '@services/context.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserRegistrationLinkAccountModel, UserRegistrationLogLinkFailureModel} from '@app/models/user-registration.model';
import {take, takeUntil} from 'rxjs/operators';
import {UserRegistrationService} from '@services/user-registration.service';
import {MessageService} from 'primeng/api';
import {Subject} from 'rxjs';
import {SharedImports} from '@shared/shared-imports';
import {UserAccountService} from '@services/user-account.service';
import {transformDateTimeToDateOnly} from '@shared/utilities/form.utility';
import {AuthService} from '@app/core/services/auth.service';
import {UnlinkedOrganizationsModel} from '@app/models/lookups.model';
import {Title} from '@angular/platform-browser';

@Component({
  templateUrl: './link-account.component.html',
  styleUrls: ['./link-account.component.scss'],
  standalone: true,
  imports: [SharedImports]
})
export class LinkAccountComponent implements OnInit {
  mainForm: FormGroup;
  mainFormUnlinked: FormGroup;
  qpTenantIdEncoded: string;
  qpUserNameEncoded: string;
  tenant: string;
  logo: string;
  verifyPath: boolean = true;
  unlinkedPath: boolean = false;
  isVerifying: boolean = false;
  orgData: UnlinkedOrganizationsModel;
  inputObjOrganization: GenericSelectModel;
  setOrganizationId: number;
  ulDOB: string;
  ulMemberNumber: string;
  ulPhoneNumber: string;
  ulPostalCode: string;

  private ngUnsubscribe = new Subject();

  constructor(private fb: FormBuilder, private lookupsDataService: LookupsDataService,
              private environmentsService: EnvironmentsService, private contextService: ContextService,
              private route: ActivatedRoute, private userRegistrationService: UserRegistrationService,
              private messageService: MessageService, private router: Router,
              private authService: AuthService, private userAccountService: UserAccountService,
              private titleService: Title) {
    this.mainForm = this.fb.group({
      dateOfBirth: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
      primaryPhoneNumber: new FormControl(null, Validators.required),
      memberNumber: new FormControl(null, Validators.maxLength(50))
    });
    this.mainFormUnlinked = this.fb.group({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      organization: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.getBaseUrl().then(() => {
      this.route.queryParams.subscribe({
        next: (params) => {
          // get the query parameters
          if (params && params.TenantIdEncoded) {
            this.qpTenantIdEncoded = params.TenantIdEncoded;
          } else {
            this.qpTenantIdEncoded = null;
          }
          this.orgData = {
            StringValue: this.qpTenantIdEncoded
          };
          if (params && params.UserNameEncoded) {
            this.qpUserNameEncoded = params.UserNameEncoded;
          } else {
            this.qpUserNameEncoded = null;
          }
        }
      });
    });
  }

  getBaseUrl() {
    return new Promise((resolve, reject) => {

      this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
        if (rtnTenant) {
          this.setUrl(rtnTenant);
        }
      });


      // switch (true) {
      //   case environment.environmentName === 'dev':
      //   case environment.environmentName === 'staging':
      //   case environment.environmentName === 'demo':
      //     this.environmentsService.getEnvironment(environment.environmentName).then((rtnTenant) => {
      //       if (rtnTenant) {
      //         this.setUrl(rtnTenant);
      //       }
      //     });
      //     break;
      //   case environment.local
      //   && environment.environmentName !== 'dev'
      //   && environment.environmentName !== 'demo'
      //   && environment.environmentName !== 'staging':
      //     if (this.tenant) {
      //       this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //         if (rtnTenant) {
      //           this.setUrl(rtnTenant);
      //         }
      //       });
      //     }
      //     break;
      //   case !environment.local:
      //     this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //       if (rtnTenant) {
      //         this.setUrl(rtnTenant);
      //       }
      //     });
      //     break;
      // }


      // if(environment.environmentName === 'demo') {
      //   this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.environmentName === 'dev') {
      //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.local && environment.environmentName !== 'dev' && environment.environmentName !== 'demo') {
      //   this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //     if(rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(!environment.local) {
      //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // }
      resolve(null);
    });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.logo = this.contextService.contextObject.tenantLogo;
  }

  initOrganization(orgData: UnlinkedOrganizationsModel): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: false
    };
    this.lookupsDataService.getMembershipOnlyOrganizationsLookupData(orgData).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  setOrganizationData(event: any) {
    if (event && event[0]) {
      this.setOrganizationId = (event[0].ID !== null) ? event[0].ID : null;
    } else {
      this.setOrganizationId = null;
    }
    this.mainFormUnlinked.get('organization').setValue(this.setOrganizationId);
    this.mainFormUnlinked.markAsDirty();
  }

  verify(): void {
    // try to verify what user provided with what exists in the data
    this.isVerifying = true;
    const data: UserRegistrationLinkAccountModel = {
      DOB: this.mainForm.get('dateOfBirth').value,
      MemberNumber: this.mainForm.get('memberNumber').value,
      Zip: this.mainForm.get('postalCode').value,
      PhoneNumber: this.mainForm.get('primaryPhoneNumber').value
    };
    if (data) {
      this.userRegistrationService.linkAccount(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your account has been linked. Welcome!', life: 3000});
            setTimeout(() => {
              this.userAccountService.getUserMetaData()
                .pipe(take(1))
                .subscribe({
                  next: (umData) => {
                    this.authService.loginSuccess(umData, true, this.contextService.contextObject.okta_cookie_redirect);
                  }
                });
            }, 3500);
          }, error: (e) => {
            if (e && e.error && e.error.Message && e.error.Message === 'Account could not be matched') {
              this.messageService.add({severity: 'error', summary: 'Match Failed', detail: 'The account could not be matched based on the details provided. Please provide the following so that your organization can manually link your account. Thank you.', life: 4500});
              setTimeout(() => {
                this.ulDOB = this.mainForm.get('dateOfBirth').value;
                this.ulMemberNumber = this.mainForm.get('memberNumber').value;
                this.ulPhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
                this.ulPostalCode = this.mainForm.get('postalCode').value;
                this.initOrganization(this.orgData);
                this.verifyPath = false;
                this.unlinkedPath = true;
              }, 5000);
            } else {
              this.messageService.add({severity: 'error', summary: 'Failure', detail: 'Please Try Again.', life: 2000});
            }
            this.isVerifying = false;
          }
        });
    }
  }

  attemptToLink() {
    // attempting to link on further information, after original data could not be linked/verified
    this.isVerifying = true;
    const data: UserRegistrationLogLinkFailureModel = {
      // PersonIDEncoded: null,
      UserNameEncoded: this.qpUserNameEncoded,
      TenantIDEncoded: this.qpTenantIdEncoded,
      DOB_D: transformDateTimeToDateOnly(this.ulDOB),
      OrgID: this.mainFormUnlinked.get('organization').value,
      MemberNumber: this.ulMemberNumber,
      PhoneNumber: this.ulPhoneNumber,
      FirstName: this.mainFormUnlinked.get('firstName').value,
      LastName: this.mainFormUnlinked.get('lastName').value,
      Zip: this.ulPostalCode
    };
    if (data) {
      this.userRegistrationService.logLinkFailure(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({severity: 'success', summary: 'Information Received', detail: 'Your information has been received. Once your account has been linked appropriately, you will be notified. Many thanks!', life: 5500});
            setTimeout(() => {
              // this.router.navigate(['/login']);
              this.authService.logout();
            }, 6000);
          }, error: (e) => {
            this.messageService.add({severity: 'error', summary: 'Failure', detail: e, life: 3500});
            setTimeout(() => {
              this.authService.logout();
            }, 4000);
          }
        });
    }
  }
}
