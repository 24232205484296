import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from '@shared/guards/can-activate.guard';
import {CanDeactivateGuard} from '@services/can-deactivate-guard.service';
import {PreloadModulesStrategy} from './core/strategies/preload-modules.strategy';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/dashboard'},

  {path: 'login', data: {preload: true}, loadChildren: () => import('./login/login-routing.module').then(m => m.LoginRoutingModule)},

  {path: 'public', loadChildren: () => import('./public/public-routing.module').then(m => m.PublicRoutingModule)},

  {
    path: 'dashboard', canActivate: [AuthGuardService],
    loadChildren: () => import('./dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule)
  },

  {
    path: 'widget-dashboard', canActivate: [AuthGuardService],
    loadChildren: () => import('./dashboard-widgets/dashboard-widgets-routing.module').then(m => m.DashboardWidgetsRoutingModule)
  },

  {
    path: 'snapshots', canActivate: [AuthGuardService],
    loadChildren: () => import('./snapshots/snapshots-routing.module').then(m => m.SnapshotsRoutingModule)
  },

  {
    path: 'security-questions', canActivate: [AuthGuardService],
    loadChildren: () => import('./security-questions/security-questions-routing.module').then(m => m.SecurityQuestionsRoutingModule)
  },

  {
    path: 'reset-password', canActivate: [AuthGuardService],
    loadChildren: () => import('./reset-password-authenticated/reset-password-authenticated-routing.module').then(m => m.ResetPasswordAuthenticatedRoutingModule)
  },

  {
    path: 'admin/restore-person', canActivate: [AuthGuardService],
    loadChildren: () => import('./person-restore/person-restore-routing.module').then(m => m.PersonRestoreRoutingModule)
  },

  {
    path: 'membership/account-adjustments', canActivate: [AuthGuardService],
    loadChildren: () => import('./account-adjustments/account-adjustments-routing.module').then(m => m.AccountAdjustmentsRoutingModule)
  },

  {
    path: 'membership/accounts', canActivate: [AuthGuardService],
    loadChildren: () => import('./accounts/accounts-routing.module').then(m => m.AccountsRoutingModule)
  },

  {
    path: 'alerts', canActivate: [AuthGuardService],
    loadChildren: () => import('./alerts/alerts-routing.module').then(m => m.AlertRoutingModule)
  },

  {
    path: 'my-time', canActivate: [AuthGuardService],
    loadChildren: () => import('./my-time/my-time-routing.module').then(m => m.MyTimeRoutingModule)
  },

  {
    path: 'membership/billing', canActivate: [AuthGuardService],
    loadChildren: () => import('./billing/billing-routing.module').then(m => m.BillingRoutingModule)
  },

  {
    path: 'membership/billing-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./billing-templates/billing-templates-routing.module').then(m => m.BillingTemplatesRoutingModule)
  },

  {
    path: 'membership/billing-configuration', canActivate: [AuthGuardService],
    loadChildren: () => import('./billing-configuration/billing-configuration-routing.module').then(m => m.BillingConfigurationRoutingModule)
  },

  {
    path: 'membership/disperse', canActivate: [AuthGuardService],
    loadChildren: () => import('./disperse/disperse-routing.module').then(m => m.DisperseRoutingModule)
  },

  {
    path: 'training/bulk-time', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-time/bulk-time-routing.module').then(m => m.BulkTimeRoutingModule)
  },

  {
    path: 'membership/bulk-updates', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-updates/bulk-updates-routing.module').then(m => m.BulkUpdatesRoutingModule)
  },

  {
    path: 'training/bulk-updates', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-updates/bulk-updates-routing.module').then(m => m.BulkUpdatesRoutingModule)
  },

  {
    path: 'training/bulk-custom-fields', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-custom-fields/bulk-custom-fields-routing.module').then(m => m.BulkCustomFieldsRoutingModule)
  },

  {
    path: 'membership/bulk-suspension', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-suspension/bulk-suspension-routing.module').then(m => m.BulkSuspensionRoutingModule)
  },

  {
    path: 'membership/bulk-receipts', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-receipts/bulk-receipts-routing.module').then(m => m.BulkReceiptsRoutingModule)
  },

  {
    path: 'membership/balance-report', canActivate: [AuthGuardService],
    loadChildren: () => import('./balance-report/balance-report-routing.module').then(m => m.BalanceReportRoutingModule)
  },

  {
    path: 'membership/cases', canActivate: [AuthGuardService],
    loadChildren: () => import('./cases/cases-routing.module').then(m => m.CasesRoutingModule)
  },

  {
    path: 'membership/case-workflows', canActivate: [AuthGuardService],
    loadChildren: () => import('./case-workflows/case-workflows-routing.module').then(m => m.CaseWorkflowsRoutingModule)
  },

  {
    path: 'membership/contracts', canActivate: [AuthGuardService],
    loadChildren: () => import('./contracts/contracts-routing.module').then(m => m.ContractRoutingModule)
  },

  {
    path: 'membership/contributions', canActivate: [AuthGuardService],
    loadChildren: () => import('./contributions/contributions-routing.module').then(m => m.ContributionsRoutingModule)
  },

  {
    path: 'membership/organizations', canActivate: [AuthGuardService],
    loadChildren: () => import('./organizations/organizations-routing.module').then(m => m.OrganizationRoutingModule)
  },

  {
    path: 'membership/missing-data', canActivate: [AuthGuardService],
    loadChildren: () => import('./missing-data/missing-data-routing.module').then(m => m.MissingDataRoutingModule)
  },

  {
    path: 'membership/officer-directory', canActivate: [AuthGuardService],
    loadChildren: () => import('./officer-directory/officer-directory-routing.module').then(m => m.OfficerDirectoryRoutingModule)
  },

  {
    path: 'membership/membership-cards', canActivate: [AuthGuardService],
    loadChildren: () => import('./batch-membership-cards/batch-membership-cards-routing.module').then(m => m.BatchMembershipCardsRoutingModule)
  },

  {
    path: 'reports', canActivate: [AuthGuardService],
    loadChildren: () => import('./reports/reports-routing.module').then(m => m.ReportsRoutingModule)
  },

  {
    path: 'reports-landing', canActivate: [AuthGuardService],
    loadChildren: () => import('./reports-landing-page/reports-landing-page-routing.module').then(m => m.ReportsLandingPageRoutingModule)
  },

  {
    path: 'report-builder', canActivate: [AuthGuardService],
    loadChildren: () => import('./report-builder/report-builder-routing.module').then(m => m.ReportBuilderRoutingModule)
  },

  {
    path: 'training/classes', canActivate: [AuthGuardService],
    loadChildren: () => import('./classes-modules/classes-routing.module').then(m => m.ClassesRoutingModule)
  },

  {
    path: 'training/training-modules', canActivate: [AuthGuardService],
    loadChildren: () => import('./training-modules/training-modules-routing.module').then(m => m.TrainingModulesRoutingModule)
  },

  {
    path: 'membership/communications', canActivate: [AuthGuardService],
    loadChildren: () => import('./communications/communications-routing.module').then(m => m.CommunicationRoutingModule)
  },

  {
    path: 'training/communications', canActivate: [AuthGuardService],
    loadChildren: () => import('./communications/communications-routing.module').then(m => m.CommunicationRoutingModule)
  },

  {
    path: 'organize/communications', canActivate: [AuthGuardService],
    loadChildren: () => import('./communications/communications-routing.module').then(m => m.CommunicationRoutingModule)
  },

  {
    path: 'organize/project-reports', canActivate: [AuthGuardService],
    loadChildren: () => import('./project-reports/project-reports-routing.module').then(m => m.ProjectReportsRoutingModule)
  },


  {
    path: 'training/courses', canActivate: [AuthGuardService],
    loadChildren: () => import('./courses/courses-routing.module').then(m => m.CoursesRoutingModule)
  },

  {
    path: 'membership/email-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./email-templates/email-templates-routing.module').then(m => m.EmailTemplatesRoutingModule)
  },

  {
    path: 'membership/report-builder', canActivate: [AuthGuardService],
    loadChildren: () => import('./report-builder/report-builder-routing.module').then(m => m.ReportBuilderRoutingModule)
  },

  {
    path: 'training/email-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./email-templates/email-templates-routing.module').then(m => m.EmailTemplatesRoutingModule)
  },

  {
    path: 'organize/email-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./email-templates/email-templates-routing.module').then(m => m.EmailTemplatesRoutingModule)
  },

  {
    path: 'membership/employers', canActivate: [AuthGuardService],
    loadChildren: () => import('./employers/employers-routing.module').then(m => m.EmployerRoutingModule)
  },

  {
    path: 'membership/events', canActivate: [AuthGuardService],
    loadChildren: () => import('./events/events-routing.module').then(m => m.EventsRoutingModule)
  },

  {
    path: 'membership/event-instances', canActivate: [AuthGuardService],
    loadChildren: () => import('./event-instances/event-instances-routing.module').then(m => m.EventInstancesRoutingModule)
  },

  {
    path: 'membership/event-locations', canActivate: [AuthGuardService],
    loadChildren: () => import('./event-locations/event-locations-routing.module').then(m => m.EventLocationsRoutingModule)
  },

  {
    path: 'training/facets', canActivate: [AuthGuardService],
    loadChildren: () => import('./facets/facets-routing.module').then(m => m.FacetsRoutingModule)
  },

  {
    path: 'membership/form-tracker', canActivate: [AuthGuardService],
    loadChildren: () => import('./form-tracker/form-tracker-routing.module').then(m => m.FormTrackerRoutingModule)
  },

  {
    path: 'training/students-certifications', canActivate: [AuthGuardService],
    loadChildren: () => import('./students-certifications/students-certifications-routing.module').then(m => m.StudentsCertificationsRoutingModule)
  },

  {
    path: 'membership/mail-merge-batches', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-batches/mail-merge-batches-routing.module').then(m => m.MailMergeBatchesRoutingModule)
  },

  {
    path: 'training/mail-merge-batches', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-batches/mail-merge-batches-routing.module').then(m => m.MailMergeBatchesRoutingModule)
  },

  {
    path: 'organize/mail-merge-batches', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-batches/mail-merge-batches-routing.module').then(m => m.MailMergeBatchesRoutingModule)
  },

  {
    path: 'employment', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-employment/user-employment-routing.module').then(m => m.UserEmploymentRoutingModule)
  },

  {
    path: 'education', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-education/user-education-routing.module').then(m => m.UserEducationRoutingModule)
  },

  {
    path: 'communications', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-communications/user-communications-routing.module').then(m => m.UserCommunicationsRoutingModule)
  },

  {
    path: 'points', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-points/user-points-routing.module').then(m => m.UserPointsRoutingModule)
  },

  {
    path: 'membership/mail-merge-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-templates/mail-merge-templates-routing.module').then(m => m.MailMergeTemplatesRoutingModule)
  },

  {
    path: 'training/mail-merge-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-templates/mail-merge-templates-routing.module').then(m => m.MailMergeTemplatesRoutingModule)
  },

  {
    path: 'organize/mail-merge-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-templates/mail-merge-templates-routing.module').then(m => m.MailMergeTemplatesRoutingModule)
  },

  {
    path: 'organize/merge-duplicates', canActivate: [AuthGuardService],
    loadChildren: () => import('./merge-duplicates/merge-duplicates-routing.module').then(m => m.MergeDuplicatesRoutingModule)
  },

  {
    path: 'membership/member-counts', canActivate: [AuthGuardService],
    loadChildren: () => import('./member-counts/member-counts-routing.module').then(m => m.MemberCountsRoutingModule)
  },

  {
    path: 'membership/merge-duplicates', canActivate: [AuthGuardService],
    loadChildren: () => import('./merge-duplicates/merge-duplicates-routing.module').then(m => m.MergeDuplicatesRoutingModule)
  },

  {
    path: 'membership/personnel-files', canActivate: [AuthGuardService],
    loadChildren: () => import('./personnel-files/personnel-files-routing.module').then(m => m.PersonnelFilesRoutingModule)
  },

  {
    path: 'membership/roster', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster/roster-routing.module').then(m => m.RosterRoutingModule)
  },

  {
    path: 'add-profile', canActivate: [AuthGuardService],
    loadChildren: () => import('./add-profile-with-dup-check/add-profile-with-dup-check-routing.module').then(m => m.AddProfileWithDupCheckRoutingModule)
  },

  {
    path: 'profiles', canActivate: [AuthGuardService],
    loadChildren: () => import('./profiles/profiles-routing.module').then(m => m.ProfilesRoutingModule)
  },

  {
    path: 'membership/global-roster', canActivate: [AuthGuardService],
    loadChildren: () => import('./global-roster/global-roster-routing.module').then(m => m.GlobalRosterRoutingModule)
  },

  {
    path: 'membership/survey-builder', canActivate: [AuthGuardService],
    loadChildren: () => import('./survey-builder/survey-builder-routing.module').then(m => m.SurveyBuilderRoutingModule)
  },

  {
    path: 'training/survey-builder', canActivate: [AuthGuardService],
    loadChildren: () => import('./survey-builder/survey-builder-routing.module').then(m => m.SurveyBuilderRoutingModule)
  },

  {
    path: 'organize/survey-builder', canActivate: [AuthGuardService],
    loadChildren: () => import('./survey-builder/survey-builder-routing.module').then(m => m.SurveyBuilderRoutingModule)
  },

  {
    path: 'training/training-centers', canActivate: [AuthGuardService],
    loadChildren: () => import('./training-centers/training-centers-routing.module').then(m => m.TrainingCentersRoutingModule)
  },

  {
    path: 'training/apprentice-hours-import', canActivate: [AuthGuardService],
    loadChildren: () => import('./apprentice-hours-import/apprentice-hours-import-routing.module').then(m => m.ApprenticeHoursImportRoutingModule)
  },

  {
    path: 'civic', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-civic/civic-routing.module').then(m => m.CivicRoutingModule)
  },

  {
    path: 'user-agreement', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-agreement/user-agreement-routing.module').then(m => m.UserAgreementRoutingModule)
  },

  {
    path: 'user-help', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-help/user-help-routing.module').then(m => m.UserHelpRoutingModule)
  },

  {
    path: 'about', canActivate: [AuthGuardService],
    loadChildren: () => import('./about/about-routing.module').then(m => m.AboutRoutingModule)
  },

  {
    path: 'release-notes', canActivate: [AuthGuardService],
    loadChildren: () => import('./release-notes/release-notes-routing.module').then(m => m.ReleaseNotesRoutingModule)
  },

  {
    path: 'privacy-policy', canActivate: [AuthGuardService],
    loadChildren: () => import('./privacy-policy/privacy-policy-routing.module').then(m => m.PrivacyPolicyRoutingModule)
  },

  {
    path: 'training/programs', canActivate: [AuthGuardService],
    loadChildren: () => import('./programs/programs-routing.module').then(m => m.ProgramsRoutingModule)
  },

  {
    path: 'organize/prospects', canActivate: [AuthGuardService],
    loadChildren: () => import('./prospects/prospects-routing.module').then(m => m.ProspectsRoutingModule)
  },

  {
    path: 'organize/campaigns', canActivate: [AuthGuardService],
    loadChildren: () => import('./campaigns/campaigns-routing.module').then(m => m.CampaignsRoutingModule)
  },

  {
    path: 'wall', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-wall/user-wall-routing.module').then(m => m.UserWallRoutingModule)
  },

  {
    path: 'select-options', canActivate: [AuthGuardService],
    loadChildren: () => import('./select-options/select-options-routing.module').then(m => m.SelectOptionsRoutingModule)
  },

  {
    path: 'select-options-landing', canActivate: [AuthGuardService],
    loadChildren: () => import('./select-options-landing-page/select-options-landing-page-routing.module').then(m => m.SelectOptionsLandingPageRoutingModule)
  },

  {
    path: 'settings', canActivate: [AuthGuardService],
    loadChildren: () => import('./settings/settings-routing.module').then(m => m.SettingsRoutingModule)
  },

  {
    path: 'training/students', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster-student/roster-student-routing.module').then(m => m.RosterStudentRoutingModule)
  },

  {
    path: 'training/global-students', canActivate: [AuthGuardService],
    loadChildren: () => import('./global-roster-student/global-roster-student-routing.module').then(m => m.GlobalRosterStudentRoutingModule)
  },

  {
    path: 'training/roster-student-add', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster-student-add/roster-student-add-routing.module').then(m => m.RosterStudentAddRoutingModule)
  },

  {
    path: 'account', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-account/user-account-routing.module').then(m => m.UserAccountRoutingModule)
  },

  {
    path: 'documents', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-documents/user-documents-routing.module').then(m => m.UserDocumentsRoutingModule)
  },

  {
    path: 'google-drive', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-google-drive/user-google-drive-routing.module').then(m => m.UserGoogleDriveRoutingModule)
  },

  {
    path: 'profile', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-profile/user-profile-routing.module').then(m => m.UserProfileRoutingModule)
  },

  {
    path: 'account', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-account/user-account-routing.module').then(m => m.UserAccountRoutingModule)
  },

  {
    path: 'surveys', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-surveys/user-surveys-routing.module').then(m => m.UserSurveysRoutingModule)
  },

  {
    path: 'time', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-time/user-time-routing.module').then(m => m.UserTimeRoutingModule)
  },

  {
    path: 'users', canActivate: [AuthGuardService],
    loadChildren: () => import('./users/users-routing.module').then(m => m.UsersRoutingModule)
  },

  {
    path: 'admin/duplicate-data-tool', canActivate: [AuthGuardService],
    loadChildren: () => import('./duplicate-data-tool/duplicate-data-tool-routing.module').then(m => m.DuplicateDataToolRoutingModule)
  },

  {
    path: 'unlinked-users', canActivate: [AuthGuardService],
    loadChildren: () => import('./unlinked-users/unlinked-users-routing.module').then(m => m.UnlinkedUsersRoutingModule)
  },

  {
    path: 'admin/help-setup', canActivate: [AuthGuardService],
    loadChildren: () => import('./help-setup/help-setup-routing.module').then(m => m.HelpSetupRoutingModule)
  },

  {
    path: 'tenant-admin-landing', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-admin-landing-page/tenant-admin-landing-page-routing.module').then(m => m.TenantAdminLandingPageRoutingModule)
  },

  {
    path: 'tenant-admin-options', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-admin-options/tenant-admin-options-routing.module').then(m => m.TenantAdminOptionsRoutingModule)
  },

  {
    path: 'admin/pathways', canActivate: [AuthGuardService],
    loadChildren: () => import('./pathways/pathways-routing.module').then(m => m.PathwaysRoutingModule)
  },

  {
    path: 'admin/roles', canActivate: [AuthGuardService],
    loadChildren: () => import('./roles/roles-routing.module').then(m => m.RolesRoutingModule)
  },

  {
    path: 'membership/roster-queries', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster-queries/roster-queries-routing.module').then(m => m.RosterQueriesRoutingModule)
  },

  {
    path: 'training/roster-queries', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster-queries/roster-queries-routing.module').then(m => m.RosterQueriesRoutingModule)
  },

  {
    path: 'organize/roster-queries', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster-queries/roster-queries-routing.module').then(m => m.RosterQueriesRoutingModule)
  },

  {
    path: 'admin/type-positions', canActivate: [AuthGuardService],
    loadChildren: () => import('./type-positions/type-positions-routing.module').then(m => m.TypePositionsRoutingModule)
  },

  {
    path: 'membership/vitals', canActivate: [AuthGuardService],
    loadChildren: () => import('./vitals/vitals-routing.module').then(m => m.VitalsRoutingModule)
  },

  {
    path: 'membership/worksites', canActivate: [AuthGuardService],
    loadChildren: () => import('./worksites/worksites-routing.module').then(m => m.WorksiteRoutingModule)
  },

  {
    path: 'membership/billing-distribution', canActivate: [AuthGuardService],
    loadChildren: () => import('./billing-distribution/billing-distribution-routing.module').then(m => m.BillingDistributionRoutingModule)
  },

  {
    path: 'membership/stipend-setup', canActivate: [AuthGuardService],
    loadChildren: () => import('./stipend-setup/stipend-setup-routing.module').then(m => m.StipendSetupRoutingModule)
  },

  {
    path: 'membership/payments-received', canActivate: [AuthGuardService],
    loadChildren: () => import('./payments-received/payments-received-routing.module').then(m => m.PaymentsReceivedRoutingModule)
  },

  {
    path: 'membership/payment-files-export', canActivate: [AuthGuardService],
    loadChildren: () => import('./payment-files-export/payment-files-export-routing.module').then(m => m.PaymentFilesExportRoutingModule)
  },

  {
    path: 'membership/cope', canActivate: [AuthGuardService],
    loadChildren: () => import('./cope/cope-routing.module').then(m => m.CopeRoutingModule)
  },

  {
    path: 'page-not-found', canActivate: [AuthGuardService],
    loadChildren: () => import('./page-not-found/page-not-found-routing.module').then(m => m.PageNotFoundRoutingModule)
  },

  {
    path: 'membership/billing-adjustments', canActivate: [AuthGuardService],
    loadChildren: () => import('./billing-adjustments/billing-adjustments-routing.module').then(m => m.BillingAdjustmentsRoutingModule)
  },

  {
    path: 'membership/payment-files', canActivate: [AuthGuardService],
    loadChildren: () => import('./payment-files/payment-files-routing.module').then(m => m.PaymentFilesRoutingModule)
  },

  {
    path: 'membership/pct-billing', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-billing/pct-billing-routing.module').then(m => m.PctBillingRoutingModule)
  },

  {
    path: 'membership/pct-adjustments', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-adjustments/pct-adjustments-routing.module').then(m => m.PctAdjustmentsRoutingModule)
  },

  {
    path: 'membership/pct-configuration', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-configuration/pct-configuration-routing.module').then(m => m.PctConfigurationRoutingModule)
  },

  {
    path: 'membership/pct-disperse', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-disperse/pct-disperse-routing.module').then(m => m.PctDisperseRoutingModule)
  },

  {
    path: 'membership/pct-payment-details', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-payment-details/pct-payment-details.routing.module').then(m => m.PctPaymentDetailsRoutingModule)
  },

  {
    path: 'membership/pct-payment-received', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-payment-received/pct-payment-received-routing.module').then(m => m.PctPaymentReceivedRoutingModule)
  },

  {
    path: 'membership/pct-payments', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-payments/pct-payments.routing.module').then(m => m.PctPaymentsRoutingModule)
  },

  {
    path: 'membership/stipends', canActivate: [AuthGuardService],
    loadChildren: () => import('./stipends/stipends.routing.module').then(m => m.StipendsRoutingModule)
  },

  {
    path: 'membership/retiree-service', canActivate: [AuthGuardService],
    loadChildren: () => import('./retiree-service/retiree-service.routing.module').then(m => m.RetireeServiceRoutingModule)
  },

  {
    path: 'membership/years-of-service', canActivate: [AuthGuardService],
    loadChildren: () => import('./years-of-service/years-of-service.routing.module').then(m => m.YearsOfServiceRoutingModule)
  },

  {
    path: 'membership/convention-registration-receipts', canActivate: [AuthGuardService],
    loadChildren: () => import('./convention-registration-receipts/convention-registration-receipts.routing.module').then(m => m.ConventionRegistrationReceiptsRoutingModule)
  },

  {
    path: 'membership/new-members', canActivate: [AuthGuardService],
    loadChildren: () => import('./new-members/new-members.routing.module').then(m => m.NewMembersRoutingModule)
  },

  {
    path: 'membership/ballots', canActivate: [AuthGuardService],
    loadChildren: () => import('./ballots/ballots-routing.module').then(m => m.BallotsRoutingModule)
  },

  {
    path: 'officer-directory', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-officer-directory/user-officer-directory-routing.module').then(m => m.UserOfficerDirectoryRoutingModule)
  },

  {
    path: 'training/class-payments', canActivate: [AuthGuardService],
    loadChildren: () => import('./class-payments/class-payments-routing.module').then(m => m.ClassPaymentsRoutingModule)
  },

  {
    path: 'training/employers', canActivate: [AuthGuardService],
    loadChildren: () => import('./employers/employers-routing.module').then(m => m.EmployerRoutingModule)
  },

  {
    path: 'training/training-stipends', canActivate: [AuthGuardService],
    loadChildren: () => import('./training-stipends/training-stipend-batches-routing.module').then(m => m.TrainingStipendBatchesRoutingModule)
  },

  {path: '**', pathMatch: 'full', redirectTo: '/page-not-found'} // catch any unfound routes and redirect to home page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadModulesStrategy})],
  exports: [RouterModule],
  providers: [PreloadModulesStrategy, CanDeactivateGuard]
})
export class AppRoutingModule {
}
