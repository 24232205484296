import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {VerifyAddressModel} from '@app/models/addresses.model';

@Injectable({
  providedIn: 'root',
})
export class MapsService {
  constructor(private http: HttpClient) {
  }

  verifyAddress(addressData: VerifyAddressModel): Observable<VerifyAddressModel> {
    const key = '12513235913956914';
    return this.http.get<any>(`https://us-street.api.smartystreets.com/street-address?auth-id=${key}&agent=smartystreets&address-type=${addressData.addressType}&street=${addressData.street}&street2=${addressData.street2}&city=${addressData.city}&state=${addressData.state}&zipcode=${addressData.zipcode}&geocode=true`);
  }
}
