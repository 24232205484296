import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GenericSelectModel} from '@app/models/generic-select.model';
import {PrimeTableFilterModel} from '@app/models/table-filter.model';
import {ContextService} from '@services/context.service';
import {TenantConfigDataService} from '@services/tenant-config-data.service';
import {listFilterUtility} from '@shared/utilities/list-filter.utility';
import {TenantFilterDefinitions} from '../../definitions/tenant-filter.definitions';
import {SharedFilterModel} from '@app/models/filter.model';
import {FilterListLookupService} from '@services/filter-list-lookups.service';
import {transformDateTimeToDateOnly} from '@shared/utilities/form.utility';
import {AuxiliaryFilterGroup} from '@app/models/roster-query-primeng.model';
import {PageRowCountUtility} from '@shared/utilities/page-row-count.utility';

export interface FilterArrayModel {
  index: number;
  value: any;
}

export interface DateRangeArrayModel {
  index: number;
  startDate?: string;
  endDate?: string;
}

@Component({
  selector: 'app-list-filters',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
})

export class ListFilterComponent implements OnInit, OnChanges {
  //shared
  activeFilters: SharedFilterModel[] = [];
  genericInputObjArray: GenericSelectModel[] = [];
  activeGlobalFilter: FilterArrayModel[] = [];
  activeBooleanFilter: FilterArrayModel[] = [];
  activeHasAmountFilter: FilterArrayModel[] = [];
  activeMyDataFilter: FilterArrayModel[] = [];
  activeDateRangeFilter: DateRangeArrayModel[] = [];
  selectedGenericItems: any[] = [];
  currentFilter = new PrimeTableFilterModel();
  tcSTRINGREPLACETYPE: string;
  setRosterQueryId: number;
  dataIsReady: boolean = false;
  items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

  @Input() filterPage: string;
  @Input() context: string = 'membership';
  @Input() InterfaceObjectEnum: string = 'ROSTER';
  @Input() isLast: boolean;
  @Input() ignoreSavedFilters: boolean;

  @Output() emitFilter = new EventEmitter<any>();
  @Output() lastDone: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private contextService: ContextService, private tenantConfigDataService: TenantConfigDataService,
              private tenantFilterDefinitions: TenantFilterDefinitions,
              private filterListService: FilterListLookupService, private changeDetectorRef: ChangeDetectorRef,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterPage) {
      if (this.filterPage) {
        this.tcSTRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');
        // console.log('current' + this.filterPage + 'Filters');
        this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
        this.currentFilter.sortOrder = 1;
        this.currentFilter.first = 0;
        this.currentFilter.filters = {};
        this.currentFilter.AuxFilters = [];
        this.addDatePlaceholders();
        this.processExistingFilters(false); //gets existing filter or clear filters
      }
    }
  }

  processExistingFilters(clearExistingFilters: boolean) {
    this.activeFilters = null;
    this.changeDetectorRef.detectChanges();

    if (sessionStorage.getItem('current' + this.filterPage + 'Filters') && !this.ignoreSavedFilters
      && sessionStorage.getItem('current' + this.filterPage + 'Filters') !== 'null'
      && this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_RememberFilters')?.Value === 'Yes' || clearExistingFilters) {
      const activeFilters: SharedFilterModel[] = this.tenantFilterDefinitions.getTenantPageFilters(this.filterPage);
      const savedFilters: PrimeTableFilterModel = JSON.parse(sessionStorage.getItem('current' + this.filterPage + 'Filters'));
      //clear default
      activeFilters.forEach(activeFilter => {
        if (activeFilter.type === 'multi') {
          activeFilter.default = [];
        }
      });
      //replace with saved filters

      activeFilters.forEach((activeFilter, index) => {
        if (!clearExistingFilters) {
          savedFilters.filters[activeFilter.field]?.forEach(savedFilter => {
            if (activeFilter.type === 'multi') {
              if (!activeFilter.default) {
                activeFilter.default = [];
              }
              activeFilter.default.push(savedFilter.value);
            }
            if (activeFilter.type === 'text') {
              activeFilter.default = savedFilter.value;
              this.activeGlobalFilter.push({index, value: savedFilter.value}); //for display on front end only
              if (activeFilter.default) {
                this.currentFilter.filters[activeFilter.field] = [];
                this.currentFilter.filters[activeFilter.field].push({value: activeFilters[index].default, matchMode: 'contains', operator: 'or'});
              }
            }
            if (activeFilter.type === 'boolean') {
              activeFilter.default = savedFilter.value;
              this.activeBooleanFilter.push({index, value: savedFilter.value}); //for display on front end only
              if (activeFilter.default) {
                this.currentFilter.filters[activeFilter.field] = [];
                this.currentFilter.filters[activeFilter.field].push({value: activeFilters[index].default, matchMode: 'equals', operator: 'or'});
              }
            }
            // for populating date filters in the future.
            // if(activeFilter.type === 'dateRange') {
            //   activeFilter.default = savedFilter.value;
            //   this.activeDateRangeFilter.push({index, startDate: savedFilter.value, endDate: savedFilter.endValue }); //for display on front end only
            //   if(this.activeDateRangeFilter.find(date => date.index === index)) {
            //     const tempDateRange = this.activeDateRangeFilter.find(date => date.index === index);
            //     this.currentFilter.filters[activeFilter.field] = [];
            //     this.currentFilter.filters[activeFilter.field].push({value: tempDateRange.startDate, endValue: tempDateRange.endDate, matchMode: 'between', operator: 'or'});
            //   }
            // }
          });
          //aux filters
          if (savedFilters.AuxFilters?.length > 0) {
            if (this.currentFilter.AuxFilters.length === 0) {
              this.currentFilter.AuxFilters.push({
                Enumerator: savedFilters.AuxFilters[0].Enumerator,
                FilterPrimeNG: null,
              });
            }
            if (!this.currentFilter.AuxFilters[0].FilterPrimeNG) {
              this.currentFilter.AuxFilters[0].FilterPrimeNG = {} as AuxiliaryFilterGroup;
              this.currentFilter.AuxFilters[0].FilterPrimeNG.filters = savedFilters.AuxFilters[0].FilterPrimeNG.filters;
              this.currentFilter.AuxFilters[0].FilterPrimeNG.globalOperator = 'and';
            }
            if (activeFilter.type === 'multi' && activeFilter.auxTable) {
              // console.log(activeFilter);
              savedFilters.AuxFilters[0].FilterPrimeNG.filters[activeFilter.field].forEach(savedFilter => {
                if (!activeFilter.default) {
                  activeFilter.default = [];
                }
                activeFilter.default.push(savedFilter.value);
              });
            }
          }
        } else {
          activeFilter.default = null;
        }
      });
      this.activeFilters = activeFilters;
      this.manageTiming();
    } else { //otherwise just use defaults
      this.activeFilters = this.tenantFilterDefinitions.getTenantPageFilters(this.filterPage);
      // console.log(this.activeFilters);
      this.manageTiming();
    }
  }

  manageTiming() {
    if (!this.activeFilters.find(x => x.type === 'multi')) {
      this.dataIsReady = true;
    }
  }

  processAuxFilters(index, filter, isMulti) {
    if (this.currentFilter.AuxFilters.length === 0) {
      this.currentFilter.AuxFilters.push({
        Enumerator: this.activeFilters[index].auxTable,
        FilterPrimeNG: null,
      });
    }
    if (!this.currentFilter.AuxFilters[0].FilterPrimeNG) {
      this.currentFilter.AuxFilters[0].FilterPrimeNG = {} as AuxiliaryFilterGroup;
      this.currentFilter.AuxFilters[0].FilterPrimeNG.filters = {[this.activeFilters[index].field]: []};
      this.currentFilter.AuxFilters[0].FilterPrimeNG.globalOperator = 'and';
    }
    if (!this.currentFilter.AuxFilters[0].FilterPrimeNG.filters[this.activeFilters[index].field]) {
      this.currentFilter.AuxFilters[0].FilterPrimeNG.filters[this.activeFilters[index].field] = [];
    }
    if (!this.currentFilter.AuxFilters[0].FilterPrimeNG.filters[this.activeFilters[index].field].find(x => x.value === filter)) {
      this.currentFilter.AuxFilters[0].FilterPrimeNG.filters[this.activeFilters[index].field].push({
        value: filter,
        matchMode: 'equals',
        operator: 'or'
      });
    }
    if (this.currentFilter.AuxFilters[0].FilterPrimeNG.filters.Position && !this.currentFilter.AuxFilters[0].FilterPrimeNG.filters.Current) {
      this.currentFilter.AuxFilters[0].FilterPrimeNG.filters.CurrentPosition = [];
      this.currentFilter.AuxFilters[0].FilterPrimeNG.filters.CurrentPosition.push({
        matchMode: 'equals',
        operator: 'or',
        value: 'true'
      });
    }
  }

  // loadAuxDefaults() {
  //   const activeFilters:SharedFilterModel[] = this.tenantFilterDefinitions.getTenantPageFilters(this.filterPage);
  //   activeFilters.forEach((activeFilter, index) => {
  //     if(activeFilter.auxTable) {
  //       if(activeFilter.default && activeFilter.type === 'text') {
  //         this.activeGlobalFilter.push(activeFilter.default);
  //        this.updateTextFilter(index, true, false, activeFilter.default, true);
  //       }
  //     }
  //   });
  // }

  initGenericMulti(disable: boolean, index: number): void {
    // we load the full object into the selection array for multi here. We have to decide to use id or description for filtering
    //add placeholders for text type

    let genericInputObj = {} as GenericSelectModel;
    genericInputObj = {
      labelText: null,
      optionLabel: 'Description',
      optionValue: this.activeFilters[index].valueOption,
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.activeFilters[index].default,
      disabled: disable
    };
    if (this.activeFilters[index]) {
      this.filterListService.getFilterLookups(this.activeFilters[index], this.InterfaceObjectEnum).then(res => {
        if (this.activeFilters[index].field === 'OrganizationID' ||
          this.activeFilters[index].field === 'MembershipAncestorOrganizationID' ||
          this.activeFilters[index].field === 'MembershipAncestorOrganizationId' ||
          this.activeFilters[index].field === 'Status' ||
          this.activeFilters[index].field === 'StatusAlias') {
          genericInputObj.customSelect = 'ShowInactiveIcon';
        }
        genericInputObj.data = res;
        genericInputObj.index = index;
        genericInputObj = Object.assign({}, genericInputObj);
        this.genericInputObjArray.push(genericInputObj);
        if (this.activeFilters[index].default && !this.activeFilters[index].auxTable) {
          this.currentFilter.filters[this.activeFilters[index].field] = [];
          this.activeFilters[index].default.forEach(x => {
            this.currentFilter.filters[this.activeFilters[index].field].push({value: x, matchMode: 'equals', operator: 'or'});
          });
        }
        if (index === this.activeFilters.indexOf(this.activeFilters.slice().reverse().find(x => x.type === 'multi'))) {
          this.dataIsReady = true;
        }
      });
    }
  }

  getGenericMultiData(event, index): void {
    if (event) {
      if (!this.activeFilters[index].auxTable) {
        this.currentFilter.filters[this.activeFilters[index].field] = [];
      }
      if (event?.length === 0 && this.activeFilters[index].auxTable) {
        this.currentFilter.AuxFilters = [];
      }
      event.forEach(filter => {
        if (!this.activeFilters[index].auxTable) {
          this.currentFilter.filters[this.activeFilters[index].field].push({value: filter[this.activeFilters[index].valueOption], matchMode: 'equals', operator: 'or'});
        } else {
          this.processAuxFilters(index, filter[this.activeFilters[index].valueOption], true);
        }
      });
    } else {
      this.selectedGenericItems = null;
    }
  }

  getCurrentValue(index, type, startDate?: boolean, filter?: any) {
    if (type === 'boolean') {
      if (this.activeBooleanFilter.find(x => x.index === index)) {
        return this.activeBooleanFilter.find(x => x.index === index).value;
      } else {
        return filter?.default;
      }
    }
    if (type === 'text') {
      if (this.activeGlobalFilter.find(x => x.index === index)) {
        return this.activeGlobalFilter.find(x => x.index === index).value;
      } else {
        return null;
      }
    }
    if (type === 'dateRange') {
      if (this.activeDateRangeFilter.find(x => x.index === index)) {
        if (startDate) {
          return this.activeDateRangeFilter.find(x => x.index === index).startDate !== undefined ? this.activeDateRangeFilter.find(x => x.index === index).startDate :
            null;
        } else {
          return this.activeDateRangeFilter.find(x => x.index === index).endDate !== undefined ? this.activeDateRangeFilter.find(x => x.index === index).endDate :
            null;
        }
      } else {
        return null;
      }
    }
  }

  addDatePlaceholders() {
    this.activeFilters.forEach((filter, index) => {
      if (filter.type === 'dateRange') {
        this.activeDateRangeFilter.push({index, startDate: null, endDate: null});
      }
    });
  }

  updateTextFilter(index, updateFilter: boolean, isLast: boolean, event, isDefault): void {
    const fieldValue = isDefault ? event : event.target.value;
    if (!this.activeFilters[index].auxTable) {
      if (this.activeGlobalFilter.find(x => x.index === index)) {
        this.activeGlobalFilter.find(x => x.index === index).value = event.target.value;
      } else {
        this.activeGlobalFilter.push({index, value: event.target.value});
      }
      this.currentFilter.filters[this.activeFilters[index].field] = [];
      this.currentFilter.filters[this.activeFilters[index].field].push({value: this.activeGlobalFilter.find(x => x.index === index).value, matchMode: 'contains', operator: 'or'});
    } else {
      this.processAuxFilters(index, fieldValue, false);
      updateFilter = false;
    }

    if (updateFilter || isLast) {
      this.filterItems(true);
    }
  }

  updateBooleanFilter(index, updateFilter: boolean, isLast: boolean, event): void {
    if (this.activeBooleanFilter.find(x => x.index === index)) {
      this.activeBooleanFilter.find(x => x.index === index).value = event;
    } else {
      this.activeBooleanFilter.push({index, value: event});
    }
    if (this.activeBooleanFilter.find(x => x.index === index).value === null) {
      delete this.currentFilter.filters[this.activeFilters[index].field];
    } else {
      this.currentFilter.filters[this.activeFilters[index].field] = [];
      this.currentFilter.filters[this.activeFilters[index].field].push({value: this.activeBooleanFilter.find(x => x.index === index).value, matchMode: 'equals', operator: 'or'});
    }

    if (updateFilter || isLast) {
      this.filterItems(true);
    }
  }

  updateHasAmountFilter(index, updateFilter: boolean, isLast: boolean, event): void {
    if (this.activeHasAmountFilter.find(x => x.index === index)) {
      this.activeHasAmountFilter.find(x => x.index === index).value = event;
    } else {
      this.activeHasAmountFilter.push({index, value: event});
    }
    if (this.activeHasAmountFilter.find(x => x.index === index).value === null) {
      delete this.currentFilter.filters[this.activeFilters[index].field];
    } else {
      this.currentFilter.filters[this.activeFilters[index].field] = [];
      if (this.activeHasAmountFilter.find(x => x.index === index).value === 'HasAmount') {
        this.currentFilter.filters[this.activeFilters[index].field].push({value: 0, matchMode: 'gt', operator: 'or'});
      } else {
        this.currentFilter.filters[this.activeFilters[index].field].push({value: 0, matchMode: 'equals', operator: 'or'});
      }
    }

    if (updateFilter || isLast) {
      this.filterItems(true);
    }
  }

  updateMyDataFilter(index, updateFilter: boolean, isLast: boolean, event): void {
    if (this.activeMyDataFilter.find(x => x.index === index)) {
      this.activeMyDataFilter.find(x => x.index === index).value = event;
    } else {
      this.activeMyDataFilter.push({index, value: event});
    }
    if (this.activeMyDataFilter.find(x => x.index === index).value === null) {
      delete this.currentFilter.filters[this.activeFilters[index].field];
    } else {
      this.currentFilter.filters[this.activeFilters[index].field] = [];
      if (this.activeMyDataFilter.find(x => x.index === index).value === 'Yes') {
        this.currentFilter.filters[this.activeFilters[index].field].push({value: this.contextService.contextObject.PersonId, matchMode: 'equals', operator: 'or'});
      } else if (this.activeMyDataFilter.find(x => x.index === index).value === 'No') {
        this.currentFilter.filters[this.activeFilters[index].field].push({value: this.contextService.contextObject.PersonId, matchMode: 'notequals', operator: 'or'});
      }
    }

    if (updateFilter || isLast) {
      this.filterItems(true);
    }
  }

  updateDateFilter(index, updateFilter: boolean, isStart: boolean, event) {
    const dateValue = event.target ? transformDateTimeToDateOnly(event.target.value) : transformDateTimeToDateOnly(event);
    if (!this.currentFilter.filters[this.activeFilters[index].field]) {
      this.currentFilter.filters[this.activeFilters[index].field] = [];
    }
    if (this.activeDateRangeFilter.find(x => x.index === index)) {
      if (isStart) {
        this.activeDateRangeFilter.find(x => x.index === index).startDate = dateValue;
      } else {
        this.activeDateRangeFilter.find(x => x.index === index).endDate = dateValue;
      }
    } else {
      if (isStart) {
        this.activeDateRangeFilter.push({index, startDate: dateValue});
      } else {
        this.activeDateRangeFilter.push({index, endDate: dateValue});
      }
    }
    if (isStart) {
      if (this.currentFilter.filters[this.activeFilters[index].field].find(existingFilter => existingFilter.matchMode === 'dateafter')) {
        this.currentFilter.filters[this.activeFilters[index].field]
          .find(existingFilter => existingFilter.matchMode === 'dateafter').value = this.activeDateRangeFilter.find(x => x.index === index).startDate;
      } else {
        this.currentFilter.filters[this.activeFilters[index].field].push({
          value: this.activeDateRangeFilter.find(x => x.index === index).startDate,
          matchMode: 'dateafter',
          operator: 'and'
        });
      }
    } else {
      if (this.currentFilter.filters[this.activeFilters[index].field].find(existingFilter => existingFilter.matchMode === 'datebefore_ignoretime')) {
        this.currentFilter.filters[this.activeFilters[index].field]
          .find(existingFilter => existingFilter.matchMode === 'datebefore_ignoretime').value = this.activeDateRangeFilter.find(x => x.index === index).endDate;
      } else {
        this.currentFilter.filters[this.activeFilters[index].field].push({
          value: this.activeDateRangeFilter.find(x => x.index === index).endDate,
          matchMode: 'datebefore_ignoretime',
          operator: 'and'
        });
      }
    }

    if (updateFilter) {
      this.filterItems(true);
      this.activeDateRangeFilter.find(x => x.index === index).startDate = event;
    }
  }

  getIndexData(index) {
    return this.genericInputObjArray.find(x => x.index === index);
  }

  public resetFilter() {
    this.currentFilter.filters = {};
    this.currentFilter.AuxFilters = [];
    this.activeGlobalFilter = [];
    this.activeDateRangeFilter = [];
    this.activeBooleanFilter = [];
    this.genericInputObjArray = [];
    this.processExistingFilters(true);
    sessionStorage.removeItem('current' + this.filterPage + 'Filters');
    this.emitFilter.emit(this.currentFilter);
  }

  filterItems(saveFilter) {
    this.emitFilter.emit(this.currentFilter);
    if (saveFilter && !this.ignoreSavedFilters) {
      sessionStorage.setItem('current' + this.filterPage + 'Filters', JSON.stringify(this.currentFilter));
    }
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }
}
