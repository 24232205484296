import {Component, OnInit} from '@angular/core';
import {ContextService} from '@services/context.service';
import {Router} from '@angular/router';
import {SharedImports} from '@shared/shared-imports';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  standalone: true,
  imports: [SharedImports]
})
export class PublicComponent implements OnInit {
  constructor(public contextService: ContextService, private router: Router) {
  }

  ngOnInit(): void {
    if (!this.router.url.includes('/two-factor-auth')) {
      sessionStorage.clear();
    }
  }
}
