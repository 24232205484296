import {Injectable} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {InterfaceObjectHelpService} from './interface-object-help.service';
import {Subject} from 'rxjs';
import {InterfaceObjectHelp} from '@app/models/interface-object-help.model';
import {PrimeTableFilterModel} from '@app/models/table-filter.model';

@Injectable({
  providedIn: 'root',
})
export class LearnMoreService {
  currentFilter = new PrimeTableFilterModel();
  private ngUnsubscribe = new Subject();

  constructor(private interfaceObjectHelpService: InterfaceObjectHelpService) {
    this.currentFilter.filters = {};
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
  }

  getLearnMoreContentDataCached(interfaceObjectTypeEnum: string, interfaceObjectDesc: string, filterData, parentObjectEnum?: string): any {
    return new Promise((resolve, reject) => {
      if (sessionStorage.getItem('getHelp') === null || sessionStorage.getItem('getHelp') === undefined) {
        this.interfaceObjectHelpService.getInterfaceObjectHelpKGrid()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              sessionStorage.setItem('getHelp', JSON.stringify(res));
              let rtn: InterfaceObjectHelp;
              if (interfaceObjectTypeEnum === 'APP') {
                rtn = res.Data.filter(x => x.PageObjectEnumerator === interfaceObjectDesc && (x.ElementObjectTypeEnumerator === interfaceObjectTypeEnum || x.ElementObjectTypeEnumerator === null))[0];
              } else if (interfaceObjectTypeEnum === 'APPSECTION') {
                rtn = res.Data.filter(x => x.ElementObjectDescription === interfaceObjectDesc && x.ElementObjectTypeEnumerator === interfaceObjectTypeEnum && x.PageObjectEnumerator === parentObjectEnum)[0];
              } else {
                rtn = null;
              }
              resolve(rtn);
            }, error: (e) => {
              reject(e);
            }
          });
      } else {
        const res = JSON.parse(sessionStorage.getItem('getHelp'));
        sessionStorage.setItem('getHelp', JSON.stringify(res));
        let rtn: InterfaceObjectHelp;
        if (interfaceObjectTypeEnum === 'APP') {
          rtn = res.Data.filter(x => x.PageObjectEnumerator === interfaceObjectDesc && (x.ElementObjectTypeEnumerator === interfaceObjectTypeEnum || x.ElementObjectTypeEnumerator === null))[0];
        } else if (interfaceObjectTypeEnum === 'APPSECTION') {
          rtn = res.Data.filter(x => x.ElementObjectDescription === interfaceObjectDesc && x.ElementObjectTypeEnumerator === interfaceObjectTypeEnum && x.PageObjectEnumerator === parentObjectEnum)[0];
        } else {
          rtn = null;
        }
        resolve(rtn);
      }

    });
  }

  getLearnMoreContentData(dataMode: string, filterData): any {
    return new Promise((resolve, reject) => {
      this.interfaceObjectHelpService.getInterfaceObjectHelpPrimeNG(dataMode, filterData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            resolve(res.Data[0]);
          }, error: (e) => {
            reject(e);
          }
        });
    });
  }
}
