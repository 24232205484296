import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {YesNoLookupModel} from '@app/models/lookups.model';

@Component({
  selector: 'app-boolean-checkbox',
  templateUrl: './boolean-checkbox.component.html'
})
export class BooleanCheckboxComponent implements OnInit, OnChanges {
  optionValues: YesNoLookupModel[] = [];
  @Input() selectedValue;
  @Input() isBoolean: boolean = true;
  @Input() value: any;
  @Input() nullable: boolean = false;
  @Output() emitData = new EventEmitter<any>();
  yesNoBoolean: YesNoLookupModel[] = [{
    ID: 1,
    Description: 'Yes',
    YesNoValue: true
  }, {
    ID: 2,
    Description: 'No',
    YesNoValue: false
  }
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.optionValues = this.yesNoBoolean;
    this.yesNoBoolean.push({
      ID: null,
      Description: '--',
      YesNoValue: null
    });
  }

  emit(event: any) {
    this.emitData.emit(event.value);
  }
}
