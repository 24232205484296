import {Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {environment} from 'src/environments/environment';
import {AuthService} from '../core/services/auth.service';
import {GoogleRecaptchaService} from '@services/google-recaptcha.service';
import {GenericSelectModel} from '@app/models/generic-select.model';
import {UserAccountPreLoginModel} from '@app/models/user-account.model';
import {UserAccountService} from '@services/user-account.service';
import {UserAccountDataService} from '@services/user-account-data.service';
import {EnvironmentsService} from '@services/environments.service';
import {ContextModel} from '@app/models/context.model';
import {ContextService} from '@services/context.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemService} from '@services/system.service';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {enforcePasswordValidation} from '@shared/utilities/component-manager-utility';
// import {Userpilot} from 'userpilot';
import {TwoFactorAuthComponent} from '../public/two-factor-auth/two-factor-auth.component';
import {DialogService} from 'primeng/dynamicdialog';
import {SharedImports} from '@shared/shared-imports';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [SharedImports]
})

export class LoginComponent implements OnInit, OnDestroy {
  lForm: FormGroup;
  loggedIn: boolean = false;
  rememberMe: boolean = false;
  passwordType: string = 'password';
  logo: string;
  isLoading: boolean = false;
  showSignUp: boolean = false;
  needHelp: boolean = false;
  multiTenant: boolean = false;
  showMultiTenant: boolean = false;
  resetButton: boolean = true;
  tenant: string;
  context = new ContextModel();
  refreshPage;
  title: string = 'Engage';
  domainFound: boolean = true;
  reRouteUrl: string;
  inputObjTenant: GenericSelectModel;
  setTenantId: string;
  public _enforcePasswordValidation = enforcePasswordValidation;
  private _userAccountPreLoginModel: UserAccountPreLoginModel;
  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private userAccountService: UserAccountService,
              private messageService: MessageService, private googleRecaptchaService: GoogleRecaptchaService,
              private userAccountDataService: UserAccountDataService, private environmentsService: EnvironmentsService,
              private activatedRoute: ActivatedRoute, private contextService: ContextService, private router: Router,
              private systemService: SystemService, private titleService: Title, @Inject(DOCUMENT) private document: Document,
              private dialogService: DialogService, private _renderer2: Renderer2
  ) {
    this.lForm = this.formBuilder.group({
      name: new FormControl(localStorage.getItem('rememberMe'), [Validators.required, Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      password: new FormControl(null, [Validators.required,
        this.regexValidator(new RegExp('^.*[A-Za-z0-9$@#!%*?&].{9,}'), {length: true}),
        this.regexValidator(new RegExp('^.*[a-z]'), {lowercase: true}),
        this.regexValidator(new RegExp('^.*[A-Z]'), {uppercase: true}),
        this.regexValidator(new RegExp('^.*[0-9]'), {number: true}),
        this.regexValidator(new RegExp('^.*[$@#!%*?&]'), {special: true}),
        this.regexValidator(new RegExp('^(?!.*([a-zA-Z0-9$@!%^*?&])\\1{2,})'), {repeats: true})
      ]),
      // password: new FormControl('', [Validators.required,
      //   Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])(?!.*([a-zA-Z0-9])\\1{2,})[A-Za-zd$@$!%*?&].{9,}')]),
      showPassword: new FormControl(false),
      rememberMe: new FormControl(!!localStorage.getItem('rememberMe'))
    }, {
      validator: this.customValidator('name', 'password')
    });
    sessionStorage.clear();

    this.activatedRoute.queryParams.pipe(take(2)).subscribe({
      next: (params) => {
        this.tenant = params.tenant;
        if (params.reRouteUrl) {
          this.reRouteUrl = params.reRouteUrl + '?selectedTab=' + params.selectedTab;
        }
        this.getBaseUrl();
      }
    });
  }

  ngOnInit(): void {
    this.onChanges();
    this.domainFound = !!(this.contextService.contextObject.tenantName);
    if (!this.domainFound) {
      console.log('Session Context Object', this.contextService.contextObject);
    }
    // console.log('environment = ' + environment);
    this.refreshPage = setTimeout(() => {
      location.reload();
      console.log('refreshing');
    }, 1680000);
  }

  onChanges(): void {
    this.lForm.controls.showPassword.valueChanges.subscribe({
      next: (val) => {
        this.passwordType = val === true ? 'text' : 'password';
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    clearTimeout(this.refreshPage);
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  login() {
    // if (this.needHelp === false && !this.lForm.invalid) {
    if (this.needHelp === false && !this.isFormValid()) {
      this.isLoading = true;
      if (this.lForm.controls.rememberMe.value === true) {
        localStorage.setItem('rememberMe', this.lForm.controls.name.value);
      } else {
        localStorage.removeItem('rememberMe');
      }
      // call on recaptcha v3
      this.googleRecaptchaService.recaptchaChallenge().then(() => {
        // check for multi-tenancy
        if (this.multiTenant && !this.setTenantId) {
          // present tenant select
          this._userAccountPreLoginModel = {
            UserName: encodeURIComponent(this.lForm.controls.name.value),
            Password: this.lForm.controls.password.value,
            ClientType: 'web',
            TenantName: environment.tenantEnum
          };
          this.initTenant(this._userAccountPreLoginModel);
        } else {
          // continue to signin
          this.signIn();
        }
      }).catch(() => {
        this.isLoading = false;
      });
    }
  }

  public signIn(validationCode?: string) {
    // if (this.needHelp === false && !this.lForm.invalid) {
    if (this.needHelp === false && (!this.isFormValid() || validationCode)) {
      this.isLoading = true;
      this.googleRecaptchaService.recaptchaChallenge().then(() => {
        let stringLogin = 'username=' + encodeURIComponent(this.lForm.controls.name.value) +
          '&password=' + encodeURIComponent(this.lForm.controls.password.value) + '&grant_type=password&client_id=' +
          `${this.contextService.contextObject.tenantName}_web`;

        if (validationCode && validationCode !== '1111111') {
          stringLogin = stringLogin + '&tfa_code=' + validationCode;
        } else {
          stringLogin = 'username=' + encodeURIComponent(this.lForm.controls.name.value) +
            '&password=' + encodeURIComponent(this.lForm.controls.password.value) + '&grant_type=password&client_id=' +
            `${this.contextService.contextObject.tenantName}_web`;
        }

        this.userAccountService.login(stringLogin)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.contextService.setContextPerspective(['MEMBERSHIP']);
              const contextObject: ContextModel = this.contextService.contextObject;
              contextObject.accessToken = res.access_token;
              contextObject.clientId = res['as:client_id'];
              contextObject.refreshToken = res.refresh_token;
              if (this.contextService.contextObject.tenantName.toUpperCase() === 'IUPAT') {
                contextObject.okta_cookie_redirect = res.okta_cookie_redirect;
              }

              if (sessionStorage.getItem('newRelic')) {
                this.loadNewRelic();
              }

              this.contextService.contextObject = contextObject;
              //redirect to specific url in params

              this.systemService.clearUserCache()
                .pipe(take(1))
                .subscribe({
                  next: () => {
                    // get user meta data to branch user appropriately
                    this.userAccountService.getUserMetaData()
                      .pipe(take(1))
                      .subscribe({
                        next: (umData) => {
                          if (umData && umData.PersonId && umData.PersonId.toString() !== '0' && umData.PersonId !== 0) {
                            // if login is successful and person is linked/exists
                            contextObject.isLoggedIn = true;
                            contextObject.isMobile = this.mobile();
                            contextObject.UserAgreement = umData.UserAgreement;
                            this.contextService.contextObject = contextObject;
                            this.loggedIn = true;

                            if (!umData.UserAgreement && this.contextService.contextObject.tenantName === 'IUPAT') {
                              // force the user-agreement if not previously agreed
                              this.router.navigateByUrl('user-agreement');
                            } else {
                              this.authService.loginSuccess(umData, true, contextObject.okta_cookie_redirect, this.reRouteUrl);
                            }
                          } else {
                            // if login is successful, but no personid is linked
                            if (umData.EmailVerified) {
                              // if e-mail has been verified, try to link account
                              this.authService.loginSuccess(umData, false, contextObject.okta_cookie_redirect);
                              this.router.navigate(['public/link-account'], {
                                queryParams: {
                                  TenantIdEncoded: umData.TenantIdEncoded,
                                  UserNameEncoded: umData.UserNameEncoded
                                }
                              });
                            } else {
                              // if not, prompt to re-register
                              this.router.navigate(['public/user-registration'], {queryParams: {reregister: 'true'}});
                            }
                          }
                          if (this.multiTenant && this.setTenantId) {
                            this.environmentsService.getEnvironment(this.contextService.contextObject.tenantName);
                          }

                          const today = new Date();
                          let roleDesc = null;
                          switch (true) {
                            case umData.Administrator === true:
                              roleDesc = 'Administrator';
                              break;
                            case umData.SuperUser === true:
                              roleDesc = 'SuperUser';
                              break;
                            default:
                              roleDesc = 'User';
                              break;
                          }
                          // Userpilot.identify(
                          //   umData.UserId,
                          //   {
                          //     name: umData.PersonName,
                          //     email: umData.UserName,
                          //     first_name: umData.PersonName.split(' ').slice(0, -1).join(' '),
                          //     last_name: umData.PersonName.split(' ').slice(-1).join(' '),
                          //     created_at: today.toLocaleString(),
                          //     role: roleDesc,
                          //     app_language: 'en',
                          //     platform_version: 'v2',
                          //     plan_tier: 'original', //later this would be our actual tier
                          //     company_name: this.contextService.contextObject.tenantName,
                          //     company: {
                          //       id: this.contextService.contextObject.tenantName, // Required, used to identify the company
                          //       name: this.contextService.contextObject.tenantName
                          //     }
                          //   }
                          // );
                        }
                      });
                  }
                });
            }, error: (e) => {
              console.log(e.error);
              if (e.error && e.error.error === 'missing_tfa_code') {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Requires 2FA',
                  detail: e.error.error_description
                });
                this.openTwoFactor();
              } else if (e.error) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Login Failed',
                  detail: e.error.error_description
                });
              }
              this.isLoading = false;
            }
          });
      }).catch(() => {
        this.isLoading = false;
      });
    }
  }

  openTwoFactor() {
    const ref = this.dialogService.open(TwoFactorAuthComponent, {
      data: {
        isLogin: true,
        twoFactorAuth: true,
      },
      header: 'Two Factor Authentication',
      width: '70%',
      height: '50%'
    });
    ref.onClose.subscribe({
      next: (res) => {
        if (res) {
          this.signIn(res);
        } else if (res === null) {
          this.signIn();
        }
      }
    });
  }

  loadNewRelic() {
    const script = this._renderer2.createElement('script');
    script.src = sessionStorage.getItem('newRelic');
    this._renderer2.appendChild(this.document.head, script);
  }

  mobile() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
        'Android'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  isFormValid() {
    this.resetButton = false;
    if (this._enforcePasswordValidation()) {
      // prevent login until valid password is entered
      if (this.multiTenant && this.inputObjTenant) {
        this.resetButton = true;
        return this.lForm.invalid || this.setTenantId === null;
      } else {
        this.resetButton = true;
        return this.lForm.invalid;
      }
    } else {
      // allow login without valid password
      if (this.multiTenant && this.inputObjTenant) {
        this.resetButton = true;
        return !(this.lForm.get('name').value) || !(this.lForm.get('password').value) || this.setTenantId === null;
      } else {
        this.resetButton = true;
        return !(this.lForm.get('name').value) || !(this.lForm.get('password').value);
      }
    }
  }

  initTenant(modelData: UserAccountPreLoginModel): any {
    this.inputObjTenant = {
      labelText: 'Client',
      optionValue: 'ID',
      optionLabel: 'TenantName',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.context.tenantName,
      data: null
    };
    this.userAccountDataService.preLoginData(modelData).then((lookupData) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Client Unknown',
        detail: 'Please select a client to continue.'
      });
      this.showMultiTenant = true;
      const regex: RegExp = /ClientId/g;
      const newLookupData = JSON.stringify(lookupData).replace(regex, 'ID');
      this.inputObjTenant.data = JSON.parse(newLookupData);
      this.inputObjTenant = Object.assign({}, this.inputObjTenant);
      this.isLoading = false;
    }).catch(e => {
      if (e && e.error && e.error.error_description) {
        this.messageService.add({severity: 'error', summary: 'Login Failed', detail: e.error.error_description});
      }
      this.isLoading = false;
    });
  }

  setTenantData(event: any) {
    if (event && event[0]) {
      if (event[0].ID === null) {
        this.setTenantId = null;
        this.showSignUp = false;
      } else {
        this.setTenantId = event[0].ID;
        environment.tenantEnum = this.setTenantId.replace('_web', '').toUpperCase();
        this.showSignUp = true;
      }
    } else {
      this.setTenantId = null;
      this.showSignUp = false;
    }
    const context = this.contextService.contextObject;
    context.clientId = this.setTenantId;
    context.tenantName = environment.tenantEnum;
    this.contextService.contextObject = context;
    this.isFormValid();
  }

  getBaseUrl() {
    if ((window.location.href.includes('engageinterface-ang-development.azurewebsites.net') || window.location.href.includes('localhost') ||
      window.location.href.includes('dev.engageapp.net')) && window.location.href.includes('?tenant=')) {
      const tenantName = window.location.href.split('?tenant=')[1];
      this.environmentsService.getEnvironment(tenantName).then((rtnTenant) => {
        if (rtnTenant) {
          this.setUrl(rtnTenant);
        }
      });
    } else {
      this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
        if (rtnTenant) {
          this.setUrl(rtnTenant);
        }
      });
    }


    // return new Promise((resolve, reject) => {
    //   switch (true) {
    //     case environment.environmentName === 'dev':
    //     case environment.environmentName === 'staging':
    //     case environment.environmentName === 'demo':
    //       this.environmentsService.getEnvironment(environment.environmentName).then((rtnTenant) => {
    //         if (rtnTenant) {
    //           this.setUrl(rtnTenant);
    //         }
    //       });
    //       break;
    //     case environment.local
    //       && environment.environmentName !== 'dev'
    //       && environment.environmentName !== 'demo'
    //       && environment.environmentName !== 'staging':
    //       if (this.tenant) {
    //         this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
    //           if (rtnTenant) {
    //             this.setUrl(rtnTenant);
    //           }
    //         });
    //       }
    //       break;
    //  case !environment.local:
    //     this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
    //       if (rtnTenant) {
    //         this.setUrl(rtnTenant);
    //       }
    //     });
    //    break;
    //    }


    // if (environment.environmentName === 'demo') {
    //   if(environment.environmentName === 'demo') {
    //     this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
    //       if (rtnTenant) {
    //         this.setUrl(rtnTenant);
    //       }
    //     });
    //   }
    // } else if (environment.environmentName === 'dev') {
    //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
    //     if (rtnTenant) {
    //       this.setUrl(rtnTenant);
    //     }
    //   });
    // } else if (environment.local && environment.environmentName !== 'dev' && environment.environmentName !== 'demo') {
    //   if(this.tenant) {
    //     this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
    //       if (rtnTenant) {
    //         this.setUrl(rtnTenant);
    //       }
    //     });
    //   }
    // } else if (!environment.local) {
    //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
    //     if (rtnTenant) {
    //       this.setUrl(rtnTenant);
    //     }
    //   });
    // }
    // resolve(null);
    // });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.apiBaseUrlV2 = rtnTenant.apiBaseUrlV2;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.supportEmail = rtnTenant.supportEmail;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.multiTenant = this.contextService.contextObject.multiTenant;
    this.logo = this.contextService.contextObject.tenantLogo;
    if (!this.multiTenant) {
      this.setTenantId = this.contextService.contextObject.tenantName;
      this.showSignUp = true;
    }
  }

  customValidator(unControlName: string, pswdControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[unControlName];
      const matchingControl = formGroup.controls[pswdControlName];
      if (matchingControl.errors && !matchingControl.errors?.customValidator) {
        return;
      }
      const am: number = control.value.indexOf('@');
      const username: string = control.value.substring(0, am);
      const domain: string = control.value.substring(am + 1, control.value.length);
      if (matchingControl.value.indexOf(username) > -1 || matchingControl.value.indexOf(domain) > -1) {
        matchingControl.setErrors({customValidator: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
