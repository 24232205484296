import {GenericSelectModel} from '@app/models/generic-select.model';
import {LookupModel} from '@app/models/lookups.model';
import {TenantConfigDataService} from './tenant-config-data.service';
import {LookupsDataService} from './lookups-data.service';

import {Injectable} from '@angular/core';

import {SharedFilterModel} from '@app/models/filter.model';

@Injectable({
  providedIn: 'root',
})

export class FilterListLookupService {
  genericInputObj = {} as GenericSelectModel;
  STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
  STRINGREPLACEOCCUPATION = this.tenantConfigDataService.getStringValue('STRINGREPLACEOCCUPATION');
  STRINGREPLACECLASSIFICATION = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
  STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');

  constructor(private tenantConfigDataService: TenantConfigDataService, private lookupsDataService: LookupsDataService) {
  }

  public getFilterLookups(filterListParams: SharedFilterModel, interfaceObjectEnum: string) {
    return new Promise((resolve, reject) => {
      switch (filterListParams.field) {
        // case 'ContractName': {
        //   this.lookupsDataService.getContractsLookupData(0).then((lookupData) => {
        //     this.genericInputObj.data = lookupData;
        //     resolve(this.genericInputObj.data);
        //   });
        //   break;
        // }
        case 'WorkflowStatusDescription': {
          this.lookupsDataService.getWorkflowStatusesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'CampaignTypeDescription': {
          this.lookupsDataService.getCampaignTypesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'WorkflowTypeDescription': {
          resolve([
            {
              ID: 1,
              Description: 'Grievance',
              Abbreviation: 'Grievance'
            },
            {
              ID: 2,
              Description: 'Discipline',
              Abbreviation: 'Discipline'
            }]);
          break;
        }
        case 'PostingStatus': {
          resolve([
            {
              ID: 1,
              Description: 'Posted',
              Abbreviation: 'Posted'
            },
            {
              ID: 2,
              Description: 'Unposted',
              Abbreviation: 'Unposted'
            }]);
          break;
        }
        case 'AccessLevel': {
          resolve([
            {
              ID: 1,
              Description: 'Officer Access',
              Abbreviation: 'Officer Access'
            },
            {
              ID: 2,
              Description: 'Member Access',
              Abbreviation: 'Member Access'
            },
            {
              ID: 3,
              Description: 'Member Dues Only Access',
              Abbreviation: 'Member Dues Only Access'
            }
          ]);
          break;
        }
        case 'CampaignStatusDescription': {
          this.lookupsDataService.getCampaignStatusesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'ProgramTypeDescription': {
          this.lookupsDataService.getProgramTypesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'SubjectAreaDescription': {
          this.lookupsDataService.getSubjectAreasLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'CourseTypeDescription': {
          this.lookupsDataService.getCourseTypesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'TrainingStipendPaymentTypeDescription': {
          this.lookupsDataService.getTrainingStipendPaymentTypesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'FrequencyEnumerator': {
          this.lookupsDataService.getTrainingStipendFrequencyLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Channel': {
          resolve([
            {
              ID: 'email',
              Rank: 0,
              Description: 'Email',
              Active: true,
              Enum: null
            },
            {
              ID: 'text message',
              Rank: 0,
              Description: 'Text Message',
              Active: true,
              Enum: null
            }]);
          break;
        }
        case 'CategoryAlias': {
          this.lookupsDataService.getSharableCategoriesLookupData('document', '', false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'ContractTypeID': {
          this.lookupsDataService.getContractTypesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'EventDescription': {
          this.lookupsDataService.getEventsLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'OrganizationCategoryDescription': {
          this.lookupsDataService.getOrganizationCategoriesLookupData(true).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'ContextDescription': {
          this.lookupsDataService.getContextsLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'OrganizationID' : {
          this.lookupsDataService.getOrganizationsByObjectAccessLookupData(interfaceObjectEnum, false, filterListParams.context, false, false, false, true).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'StatusAlias' : {
          this.lookupsDataService.getPersonProfileStatusesLookupData(filterListParams.context, false, true).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Positions' :
        case 'Position' : {
          this.lookupsDataService.getPositionsByAccessLookupData(interfaceObjectEnum, filterListParams.category, false, true).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'EmployerName' :
        case 'Employer' : {
          // TODO: for now, overwrite the students interface object enum with roster so employers return
          const ioe: string = (interfaceObjectEnum === 'STUDENTS') ? 'ROSTER' : interfaceObjectEnum;
          this.lookupsDataService.getEmployersLookupByEnumData(ioe, false, true).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'TypeAlias' :
        case 'MemberTypeAlias' : {
          this.lookupsDataService.getPersonProfileTypesLookupData(filterListParams.context, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'MembershipAncestorOrganizationID' :
        case 'MembershipAncestorOrganizationId' : {
          this.lookupsDataService.getOrganizationsDataPerspectiveMembershipLookupData().then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'ProgramDescription' : {
          this.lookupsDataService.getAllProgramsPicklistLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'StudentGroupID' : {
          this.lookupsDataService.getStudentGroupsEnumLookupData(interfaceObjectEnum, null, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'TradeID':
        case 'PrimaryTrade' : {
          this.lookupsDataService.getTradesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Status' : {
          this.lookupsDataService.getOrganizationStatusesLookupData(filterListParams.category, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });

          break;
        }
        case 'StateProvince' : {
          this.lookupsDataService.getCountriesLookupData().then((lookupData: any[]) => {
            const setCountryId = lookupData.find(x => x.Description === 'United States').ID;
            if (setCountryId) {
              this.lookupsDataService.getStatesLookupData(setCountryId, false).then((lookupDataNew) => {
                this.genericInputObj.data = lookupDataNew;
                resolve(this.genericInputObj.data);
              });
            }
          });
          break;
        }
        case 'CountryID' : {
          this.lookupsDataService.getCountriesLookupData().then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'LCMSDescription': {
          this.lookupsDataService.getLCMSSystemsLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'CaseTypeDescription' : {
          this.lookupsDataService.getCaseTypesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'CaseSubjectDescription' : {
          this.lookupsDataService.getCaseSubjectsLookupData(null, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
      }
    });
  }
}


