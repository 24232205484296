import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {
  UserAccountPreLoginModel, UserAccountPreLoginTenantModel, UserAccountVerifyTokenModel,
  UserAccountMetaDataModel, UserAccountExternalAccountsModel, UserAccountPerspectiveMetaDataModel,
  UserAccountPasswordResetEmailModel, UserAccountPasswordResetModel, UserAccountRequestSecurityQuestionModel,
  UserAccountChangeTemporaryPasswordModel, UserAccountChangePasswordModel, UpdateUserAccountSecurityQuestionsModel,
  UserAccountUnsubscribeModel, UserBannerModel, UserTfaModel
} from '@app/models/user-account.model';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  login(loginInfo): Observable<any> {
    return this.http.post(`${this.contextService.contextObject.apiBaseUrlV1}/token`, loginInfo);
  }

  putAccountTfa(modelData): Observable<UserTfaModel> {
    return this.http.put<UserTfaModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/tfa`, modelData);
  }

  getAccountTfa(): Observable<UserTfaModel> {
    return this.http.get<UserTfaModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/tfa`);
  }

  preLogin(modelData: UserAccountPreLoginModel): Observable<UserAccountPreLoginTenantModel[]> {
    return this.http.post<UserAccountPreLoginTenantModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/prelogin`, modelData);
  }

  refreshLogin(modelData: string): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/token`, modelData);
  }

  logout(): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/logout`, null);
  }

  verifyToken(modelData: UserAccountVerifyTokenModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/verifytoken`, modelData);
  }

  getUserPersonId(): Observable<string> {
    return this.http.get<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/getuserpersonid`);
  }

  getUserMetaData(): Observable<UserAccountMetaDataModel> {
    return this.http.get<UserAccountMetaDataModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/getusermeta`);
  }

  getReleaseNoteCount(): Observable<number> {
    return this.http.get<number>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/getreleasenotecount`);
  }

  getExternalAccountLookup(tenantName: string, identifier: string, expirationdate: string): Observable<UserAccountExternalAccountsModel> {
    return this.http.get<UserAccountExternalAccountsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/externalaccountlookup?tenantname=${tenantName}&identifier=${identifier}&expirationdate=${expirationdate}`
    );
  }

  getPerspectiveMetaData(): Observable<UserAccountPerspectiveMetaDataModel[]> {
    return this.http.get<UserAccountPerspectiveMetaDataModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/getperspectivemeta`);
  }

  hasSecurityQuestionResponses(encodedUserName: string): Observable<string> {
    return this.http.get<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/hassecurityquestionresponses?username=${encodedUserName}`);
  }

  generatePasswordResetEmail(modelData: UserAccountPasswordResetEmailModel): Observable<string> {
    return this.http.post<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/forgotpasswordemail`, modelData);
  }

  resetPassword(modelData: UserAccountPasswordResetModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/resetpassword`, modelData);
  }

  requestSecurityQuestion(modelData: UserAccountRequestSecurityQuestionModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/requestsecurityquestion`, modelData);
  }

  changePassword(modelData: UserAccountChangePasswordModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/changepassword`, modelData);
  }

  changeTemporaryPassword(modelData: UserAccountChangeTemporaryPasswordModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/changetemppassword`, modelData);
  }

  updateSecurityQuestions(modelData: UpdateUserAccountSecurityQuestionsModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/updatemysecurityquestions`, modelData);
  }

  unsubscribe(modelData: UserAccountUnsubscribeModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/unsubscribe`, modelData);
  }

  getUserBanner(): Observable<UserBannerModel> {
    return this.http.get<UserBannerModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/banner`);
  }

  userAgreement(): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/agreementconsent`, null);
  }
}
