import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ContextService} from '@services/context.service';
import {YesNoLookupModel} from '@app/models/lookups.model';

@Component({
  selector: 'app-filter-my-data-select',
  templateUrl: './filter-my-data.component.html'
})
export class FilterMyDataComponent implements OnInit, OnChanges {
  optionValues: YesNoLookupModel[] = [];
  personId: number;
  yesNo: YesNoLookupModel[];
  @Input() selectedValue;
  @Input() optionName: string;
  @Input() value: any;
  @Input() nullable: boolean = false;
  @Output() emitData = new EventEmitter<any>();


  constructor(private contextService: ContextService) {
  }

  ngOnInit(): void {
    this.personId = this.contextService.contextObject.PersonId;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.yesNo = [{
      ID: 1,
      Description: 'Show My ' + this.optionName + ' Only',
      YesNoValue: 'Yes'
    }, {
      ID: 2,
      Description: 'Show Others ' + this.optionName + ' Only',
      YesNoValue: 'No'
    }
    ];
    this.optionValues = this.yesNo;
    if (changes.nullable && this.nullable) {
      this.value = null;
      this.yesNo.unshift({
        ID: 0,
        Description: '--',
        YesNoValue: null
      });
      this.selectedValue = null;
    }
  }

  emit(event: any) {
    this.emitData.emit(event.value);
  }

}
