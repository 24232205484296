import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';
import {Userpilot} from 'userpilot';
import {RosterGridModel, RosterInvitationStatisticsGridModel, RosterListModel, RosterPositionsGridModel, RosterVoterGridModel} from '@app/models/roster.model';

// import { RosterGridModel, RosterListModel, RosterAttributesGridModel, RosterVoterGridModel,
//   RosterCertificationsGridModel, RosterPositionsGridModel, RosterInvitationStatisticsGridModel } from '@app/models/roster.model';
// import { RosterPointsGridModel } from '@app/models/points.model';
// import { TableFilterModel } from '@app/models/table-filter.model';

@Injectable({
  providedIn: 'root',
})
export class RosterService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getRosterPrimeNG(dataMode: string, filterData, rosterQueryId?: number, routeOrganizationId?: number): Observable<RosterGridModel> {
    Userpilot.track('Member Roster List');
    const rq = (rosterQueryId) ? rosterQueryId : 0;
    const url = (routeOrganizationId && routeOrganizationId !== this.contextService.contextObject.RootMemberOrgID) ? `&routeorganizationid=${routeOrganizationId}` : '';
    return this.http.post<RosterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/detailedroster/grid/${rq}?mode=${dataMode}&perspectiveContext=MEMBERSHIP${url}`, filterData);
  }

  getRosterLitePrimeNG(dataMode: string, filterData, rosterQueryId?: number, routeOrganizationId?: number, globalView?: boolean): Observable<RosterListModel> {
    Userpilot.track('Member Roster List');
    const rq = (rosterQueryId) ? rosterQueryId : 0;
    const url = (routeOrganizationId && routeOrganizationId !== this.contextService.contextObject.RootMemberOrgID) ? `&routeorganizationid=${routeOrganizationId}` : '';
    const global = (globalView === true) ? '&showall=true' : '';
    return this.http.post<RosterListModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/literoster/grid/${rq}?mode=${dataMode}&perspectiveContext=MEMBERSHIP${url}${global}`, filterData);
  }

  getRosterExpandedExport(filterData, rosterQueryId?: number): Observable<ArrayBuffer> {
    const url = (rosterQueryId) ? rosterQueryId : 0;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http.post<ArrayBuffer>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/export/primeng/${url}?filetype=xlsx`, filterData, requestOptions);
    ///api/1.0/superuser/roster/export/primeng/{queryid:int}
  }

  getRosterAttributesGridData(filterData, rosterQueryId?: number,): Observable<RosterPositionsGridModel> {

    const url = (rosterQueryId) ? rosterQueryId : 0;
    return this.http.post<RosterPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/detailedroster/attributegrid/${url}`, filterData);
  }

  getRosterVoterGridData(filterData, rosterQueryId?: number): Observable<RosterVoterGridModel> {
    Userpilot.track('Member Roster Voter List');
    const rq: number = (rosterQueryId) ? rosterQueryId : 0;
    return this.http.post<RosterVoterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/detailedroster/votergrid/${rq}`, filterData);
  }

  getRosterSurveyGridData(filterData, rosterQueryId?: number): Observable<RosterVoterGridModel> {
    Userpilot.track('Member Roster Survey List');
    const rq: number = (rosterQueryId) ? rosterQueryId : 0;
    return this.http.post<RosterVoterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/detailedroster/surveygrid/${rq}`, filterData);
  }

  getRosterPositionsGridData(filterData, rosterQueryId?: number): Observable<RosterPositionsGridModel> {
    Userpilot.track('Member Roster Position List');
    const rq: number = (rosterQueryId) ? rosterQueryId : 0;
    return this.http.post<RosterPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/positiongrid/${rq}`, filterData);
  }

  getRosterInvitationStatisticsPrimeNG(dataMode: string, filterData): Observable<RosterInvitationStatisticsGridModel> {
    return this.http.post<RosterInvitationStatisticsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/invitationstats?mode=${dataMode}`, filterData);
  }

  getRosterInvitationStatisticsPrimeNGSelectedOptions(dataMode: string, rosterQueryId: number, filterData): Observable<RosterInvitationStatisticsGridModel> {
    return this.http.post<RosterInvitationStatisticsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/invitationstats?mode=${dataMode}&rosterqueryid=${rosterQueryId}`, filterData);
  }

  getRosterMembershipCard(personId: number, regenerate: string, templateId: number) {
    if (regenerate === 'regenerate') {
      return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/membershipcard?regenerate=true&templateId=${templateId}`);
    } else if (regenerate === 'view') {
      return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/membershipcard`);
    }
  }

  getRosterMemberForms(personId: number, square9FormEnum: string) {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/person/${personId}/square9form/${square9FormEnum}`);
  }


}
