import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {transformDateTimeToDateOnly} from '@shared/utilities/form.utility';

@Component({
  selector: 'app-universal-date-picker',
  templateUrl: './universal-date-picker.component.html'
})
export class UniversalDatePickerComponent implements OnInit, OnChanges {
  value;
  @Input() selectedValue;
  @Input() disabled: boolean;
  @Input() useMinMax: boolean;
  @Input() minValue: Date;
  @Input() maxValue: Date;
  @Input() nullable: boolean = false;
  @Output() emitData = new EventEmitter<any>();

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedValue) {
      if (this.selectedValue && !this.value) {
        this.value = new Date(this.selectedValue);
      } else if (!this.selectedValue) {
        this.value = null;
      }
      this.changeDetector.detectChanges();
    }
  }

  emit(event, type) {
    console.log(event);
    if (this.useMinMax && (this.value < this.minValue || this.value > this.maxValue)) {

    }
    if (type === 'select') {
      this.emitData.emit(transformDateTimeToDateOnly(event));
    } else {
      //   console.log(transformDateTimeToDateOnly(event.target.value));
      this.emitData.emit(event.target.value);
    }
  }
}
