import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {GenericSelectModel} from '@app/models/generic-select.model';
import {LookupsDataService} from '@services/lookups-data.service';
import {environment} from '../../../environments/environment';
import {ContextModel} from '@app/models/context.model';
import {EnvironmentsService} from '@services/environments.service';
import {ContextService} from '@services/context.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserRegistrationLogLinkFailureModel, UserRegistrationModel, UserRegistrationVerifyInvitationModel} from '@app/models/user-registration.model';
import {takeUntil} from 'rxjs/operators';
import {UserRegistrationService} from '@services/user-registration.service';
import {MessageService} from 'primeng/api';
import {Subject} from 'rxjs';
import {SharedImports} from '@shared/shared-imports';
import {UserAccountVerifyTokenModel} from '@app/models/user-account.model';
import {UserAccountService} from '@services/user-account.service';
import {transformDateTimeToDateOnly} from '@shared/utilities/form.utility';
import {ConfirmValidationUtility} from '@shared/utilities/confirm-validation.utility';
import {Title} from '@angular/platform-browser';

@Component({
  templateUrl: './verify-invitation.component.html',
  styleUrls: ['./verify-invitation.component.scss'],
  standalone: true,
  imports: [SharedImports]
})
export class VerifyInvitationComponent implements OnInit {
  mainForm: FormGroup;
  mainFormExpired: FormGroup;
  mainFormUnlinked: FormGroup;
  qpusername: string;
  qptoken: string;
  qppersonid: string;
  qptid: string;
  tenant: string;
  logo: string;
  verifyPath: boolean = true;
  expiredPath: boolean = false;
  unlinkedPath: boolean = false;
  passwordType = 'password';
  passwordConfirmType = 'password';
  requirements: boolean = false;
  inputObjQuestion1: GenericSelectModel;
  setQuestion1Id: number;
  inputObjQuestion2: GenericSelectModel;
  setQuestion2Id: number;
  inputObjQuestion3: GenericSelectModel;
  setQuestion3Id: number;
  showQuestion1Id: boolean = true;
  showQuestion2Id: boolean = true;
  showQuestion3Id: boolean = true;
  isVerifying: boolean = false;
  ulDOB: string;
  ulMemberNumber: string;
  ulPhoneNumber: string;

  private ngUnsubscribe = new Subject();

  constructor(private fb: FormBuilder, private lookupsDataService: LookupsDataService,
              private environmentsService: EnvironmentsService, private contextService: ContextService,
              private route: ActivatedRoute, private userRegistrationService: UserRegistrationService,
              private messageService: MessageService, private router: Router,
              private userAccountService: UserAccountService, private confirmValidationUtility: ConfirmValidationUtility,
              private titleService: Title) {
    this.mainForm = this.fb.group({
      password: new FormControl(null, [Validators.required,
        this.regexValidator(new RegExp('^.*[A-Za-z0-9$@#!%*?&].{9,}'), {length: true}),
        this.regexValidator(new RegExp('^.*[a-z]'), {lowercase: true}),
        this.regexValidator(new RegExp('^.*[A-Z]'), {uppercase: true}),
        this.regexValidator(new RegExp('^.*[0-9]'), {number: true}),
        this.regexValidator(new RegExp('^.*[$@#!%*?&]'), {special: true}),
        this.regexValidator(new RegExp('^(?!.*([a-zA-Z0-9$@#!%*?&])\\1{2,})'), {repeats: true})
      ]),
      // password: new FormControl('', [Validators.required, Validators.maxLength(100),
      //   Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])(?!.*([a-zA-Z0-9])\\1{2,})[A-Za-zd$@$!%*?&].{9,}')]),
      showPassword: new FormControl(false),
      passwordConfirm: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      showPasswordConfirm: new FormControl(false),
      question1: new FormControl(null, Validators.required),
      answer1: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(100)]),
      question2: new FormControl(null, Validators.required),
      answer2: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(100)]),
      question3: new FormControl(null, Validators.required),
      answer3: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(100)]),
      dateOfBirth: new FormControl(null, Validators.required),
      primaryPhoneNumber: new FormControl(null, Validators.required),
      memberNumber: new FormControl(null, Validators.maxLength(50))
    }, {
      validator: this.confirmValidationUtility.ConfirmedValidator('password', 'passwordConfirm')
    });
    this.mainFormExpired = this.fb.group({
      username: new FormControl(null, [Validators.required, Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$'), Validators.maxLength(100)]),
      password: new FormControl(null, [Validators.required,
        this.regexValidator(new RegExp('^.*[A-Za-z0-9$@#!%*?&].{9,}'), {length: true}),
        this.regexValidator(new RegExp('^.*[a-z]'), {lowercase: true}),
        this.regexValidator(new RegExp('^.*[A-Z]'), {uppercase: true}),
        this.regexValidator(new RegExp('^.*[0-9]'), {number: true}),
        this.regexValidator(new RegExp('^.*[$@#!%*?&]'), {special: true}),
        this.regexValidator(new RegExp('^(?!.*([a-zA-Z0-9$@#!%*?&])\\1{2,})'), {repeats: true})
      ]),
      // password: new FormControl('', [Validators.required, Validators.maxLength(100),
      //   Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])(?!.*([a-zA-Z0-9])\\1{2,})[A-Za-zd$@$!%*?&].{9,}')]),
      showPassword: new FormControl(false),
      passwordConfirm: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      showPasswordConfirm: new FormControl(false)
    }, {
      validator: [this.confirmValidationUtility.ConfirmedValidator('password', 'passwordConfirm'),
        this.customValidator('username', 'password')]
    });
    this.mainFormUnlinked = this.fb.group({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.getBaseUrl().then(() => {
      this.route.queryParams.subscribe({
        next: (params) => {
          // get the query parameters
          if (params && params.username) {
            this.qpusername = params.username;
          } else {
            this.qpusername = null;
          }
          if (params && params.token) {
            this.qptoken = params.token;
          } else {
            this.qptoken = null;
          }
          if (params && params.personid) {
            this.qppersonid = params.personid;
          } else {
            this.qppersonid = null;
          }
          if (params && params.tid) {
            this.qptid = params.tid;
          } else {
            this.qptid = null;
          }
          // check the validity of the token, it can expire
          this.verifyToken().then((res) => {
            if (res === 'Valid') {
              // if valid, show verify
              this.verifyPath = true;
              this.expiredPath = false;
              this.mainForm.get('showPassword').valueChanges.subscribe({
                next: (val) => {
                  this.passwordType = val === true ? 'text' : 'password';
                }
              });
              this.mainForm.get('showPasswordConfirm').valueChanges.subscribe({
                next: (val) => {
                  this.passwordConfirmType = val === true ? 'text' : 'password';
                }
              });
              this.initQuestion1(false);
              this.initQuestion2(false);
              this.initQuestion3(false);
            } else {
              // if invalid, show expired
              this.verifyPath = false;
              this.expiredPath = true;
              this.mainFormExpired.get('showPassword').valueChanges.subscribe({
                next: (val) => {
                  this.passwordType = val === true ? 'text' : 'password';
                }
              });
              this.mainFormExpired.get('showPasswordConfirm').valueChanges.subscribe({
                next: (val) => {
                  this.passwordConfirmType = val === true ? 'text' : 'password';
                }
              });
            }
          }, () => {
            // if invalid, show expired
            this.verifyPath = false;
            this.expiredPath = true;
            this.mainFormExpired.get('showPassword').valueChanges.subscribe({
              next: (val) => {
                this.passwordType = val === true ? 'text' : 'password';
              }
            });
            this.mainFormExpired.get('showPasswordConfirm').valueChanges.subscribe({
              next: (val) => {
                this.passwordConfirmType = val === true ? 'text' : 'password';
              }
            });
          });
        }
      });
    });
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  getBaseUrl() {
    return new Promise((resolve, reject) => {
      switch (true) {
        case environment.environmentName === 'dev':
        case environment.environmentName === 'staging':
        case environment.environmentName === 'demo':
          this.environmentsService.getEnvironment(environment.environmentName).then((rtnTenant) => {
            if (rtnTenant) {
              this.setUrl(rtnTenant);
            }
          });
          break;
        case environment.local
        && environment.environmentName !== 'dev'
        && environment.environmentName !== 'demo'
        && environment.environmentName !== 'staging':
          if (this.tenant) {
            this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
              if (rtnTenant) {
                this.setUrl(rtnTenant);
              }
            });
          }
          break;
        case !environment.local:
          this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
            if (rtnTenant) {
              this.setUrl(rtnTenant);
            }
          });
          break;
      }
      // if(environment.environmentName === 'demo') {
      //   this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.environmentName === 'dev') {
      //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.local && environment.environmentName !== 'dev' && environment.environmentName !== 'demo') {
      //   this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //     if(rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(!environment.local) {
      //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // }
      resolve(null);
    });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.logo = this.contextService.contextObject.tenantLogo;
  }

  toggleRequirements() {
    this.requirements = this.requirements !== true;
  }

  initQuestion1(disable: boolean): void {
    this.inputObjQuestion1 = {
      labelText: 'Question #1',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getSecurityQuestionsLookupData(1).then((lookupData) => {
      this.inputObjQuestion1.data = lookupData;
      this.inputObjQuestion1 = Object.assign({}, this.inputObjQuestion1);
    });
  }

  getQuestion1Data(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setQuestion1Id = event[0].ID;
    } else {
      this.setQuestion1Id = null;
    }
    this.mainForm.get('question1').setValue(this.setQuestion1Id);
  }

  initQuestion2(disable: boolean): void {
    this.inputObjQuestion2 = {
      labelText: 'Question #2',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getSecurityQuestionsLookupData(2).then((lookupData) => {
      this.inputObjQuestion2.data = lookupData;
      this.inputObjQuestion2 = Object.assign({}, this.inputObjQuestion2);
    });
  }

  getQuestion2Data(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setQuestion2Id = event[0].ID;
    } else {
      this.setQuestion2Id = null;
    }
    this.mainForm.get('question2').setValue(this.setQuestion2Id);
  }

  initQuestion3(disable: boolean): void {
    this.inputObjQuestion3 = {
      labelText: 'Question #3',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getSecurityQuestionsLookupData(3).then((lookupData) => {
      this.inputObjQuestion3.data = lookupData;
      this.inputObjQuestion3 = Object.assign({}, this.inputObjQuestion3);
    });
  }

  getQuestion3Data(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setQuestion3Id = event[0].ID;
    } else {
      this.setQuestion3Id = null;
    }
    this.mainForm.get('question3').setValue(this.setQuestion3Id);
  }

  verifyToken() {
    // check the status of the token
    return new Promise((resolve, reject) => {
      const data: UserAccountVerifyTokenModel = {
        UserNameEncoded: this.qpusername,
        Token: this.qptoken,
        TokenTypeID: 2,
        TenantIDEncoded: this.qptid
      };
      this.userAccountService.verifyToken(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error: (e) => {
            console.log(e);
            reject(e);
          }
        });
    });
  }

  verify(): void {
    // try to verify what user provided with what exists in the data
    this.isVerifying = true;
    const data: UserRegistrationVerifyInvitationModel = {
      UserNameEncoded: this.qpusername,
      PersonIDEncoded: this.qppersonid,
      TenantIDEncoded: this.qptid,
      Password: this.mainForm.get('password').value,
      ConfirmPassword: this.mainForm.get('passwordConfirm').value,
      DOB_D: transformDateTimeToDateOnly(this.mainForm.get('dateOfBirth').value),
      MemberNumber: this.mainForm.get('memberNumber').value,
      PhoneNumber: this.mainForm.get('primaryPhoneNumber').value,
      QuestionID1: this.mainForm.get('question1').value,
      QuestionResponse1: this.mainForm.get('answer1').value,
      QuestionID2: this.mainForm.get('question2').value,
      QuestionResponse2: this.mainForm.get('answer2').value,
      QuestionID3: this.mainForm.get('question3').value,
      QuestionResponse3: this.mainForm.get('answer3').value
    };
    if (data) {
      this.userRegistrationService.verifyInvitation(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (msg) => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: msg, life: 4500});
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 5000);
          }, error: (e) => {
            if (e && e.error && e.error.Message && e.error.Message === 'Account could not be matched') {
              this.ulDOB = this.mainForm.get('dateOfBirth').value;
              this.ulMemberNumber = this.mainForm.get('memberNumber').value;
              this.ulPhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
              this.verifyPath = false;
              this.unlinkedPath = true;
            } else {
              this.messageService.add({severity: 'error', summary: 'Failure', detail: 'Please try again. Thank you.'});
            }
            this.isVerifying = false;
          }
        });
    }
  }

  signUp() {
    // trying to register as a new user, token had expired
    this.isVerifying = true;
    const data: UserRegistrationModel = {
      UserName: this.mainFormExpired.get('username').value,
      Password: this.mainFormExpired.get('password').value,
      ConfirmPassword: this.mainFormExpired.get('passwordConfirm').value,
      TenantName: this.contextService.contextObject.tenantName
    };
    if (data) {
      this.userRegistrationService.registerUser(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (msg) => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: msg, life: 4500});
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 5000);
          }, error: (e) => {
            this.messageService.add({severity: 'error', summary: 'Failure', detail: e, life: 4500});
            this.isVerifying = false;
          }
        });
    }
  }

  attemptToLink() {
    // attempting to link on further information, after original data could not be linked/verified
    this.isVerifying = true;
    const data: UserRegistrationLogLinkFailureModel = {
      PersonIDEncoded: this.qppersonid,
      UserNameEncoded: this.qpusername,
      TenantIDEncoded: this.qptid,
      DOB_D: transformDateTimeToDateOnly(this.ulDOB),
      OrgID: 0,
      MemberNumber: this.ulMemberNumber,
      PhoneNumber: this.ulPhoneNumber,
      FirstName: this.mainFormUnlinked.get('firstName').value,
      LastName: this.mainFormUnlinked.get('lastName').value,
      Zip: ''
    };
    if (data) {
      this.userRegistrationService.logLinkFailure(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({severity: 'success', summary: 'Information Received', detail: 'Your information has been received. Once your account has been linked appropriately, you will be notified. Many thanks!', life: 5500});
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 6000);
          }, error: (e) => {
            this.messageService.add({severity: 'error', summary: 'Failure', detail: e, life: 4500});
            this.isVerifying = false;
          }
        });
    }
  }

  customValidator(unControlName: string, pswdControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[unControlName];
      const matchingControl = formGroup.controls[pswdControlName];
      if (matchingControl.errors && !matchingControl.errors?.customValidator) {
        return;
      }
      const am: number = control.value.indexOf('@');
      const username: string = control.value.substring(0, am);
      const domain: string = control.value.substring(am + 1, control.value.length);
      if (matchingControl.value.indexOf(username) > -1 || matchingControl.value.indexOf(domain) > -1) {
        matchingControl.setErrors({customValidator: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
