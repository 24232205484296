<div class="menubar header-color">
  <div class="menu-container">
    <div class="main-logo pointer" [pTooltip]="'Home'" (click)="resetWarning(); goToDashboard();">
    </div>
    @if (parentShowBurger) {
      <i class="pi pi-bars hamburger" (click)="openCloseNav()"></i>
    }
    <div class="right-dropdown">
      @if (showWorkingAs) {
        <div class="search-container margin-top-5">
          <div class="p-inputgroup w-full md:w-30rem">
            <!--        <input type="text" pInputText [placeholder]="placeholder" />-->
            @if (hasMultipleContext) {
              <span class="p-inputgroup-addon pointer" (click)="toggleContext();" pTooltip="Select Member or Student"
                    tooltipPosition="bottom">
              @switch (tenantContext) {
                @case ('training') {
                  <i class="fas fa-user-graduate"></i>
                }
                @case ('membership') {
                  <i class="fas fa-user"></i>
                }
              }
            </span>
            }
            <p-autoComplete [(ngModel)]="selectedMember" [suggestions]="suggestions"
                            (completeMethod)="searchMembers($event)"
                            field="MemberNumber" [placeholder]="placeholder" tabindex="0">
              <ng-template let-member pTemplate="item">
                <div class="flex align-items-center gap-2" (click)="getMember(member)">
                  @if (!isNameSearch) {
                    <div>{{ member.MemberNumber }} - {{ member.FullName }}</div>
                  } @else {
                    <div>{{ member.FullName }} - {{ member.MemberNumber }}</div>
                  }
                </div>
              </ng-template>
            </p-autoComplete>
            @if (searching) {
              <span class="p-inputgroup-addon pointer"><span class="fa fa-spinner search-spinner"></span></span>
            }
            <span class="p-inputgroup-addon pointer" (click)="toggleSearch();">{{ buttonIcon }}</span>
            <span class="p-inputgroup-addon pointer"><i class="fas fa-times" (click)="selectedMember = null"></i></span>
          </div>
        </div>
      }
      @if (showMenuSelect) {
        <i class="fas fa-ellipsis-h" (click)="menuSelected()"></i>
      }
      @if (isSuperUser) {
        <i class="fas fa-eye eye" (click)="openDataPerspective($event); menuOpen = false; alertsOpen = false;"></i>
      }
      @if (dataPerspectiveOpen) {
        <div class="dataperspective-content">
          <app-data-perspective-dropdown (showDropDown)="ShowDropDown()"></app-data-perspective-dropdown>
          <div class="p-fluid p-grid">
            @if (showWorkingAs) {
              <div class="col-md-12 col-sm-12 col-xs-12">
                <label>Working As:</label>
                <p-dropdown [styleClass]="'working-as-dropdown'" [options]="options" optionLabel="description"
                            optionValue="description"
                            [(ngModel)]="selectedWorkingAs" (onChange)="processClient($event)"></p-dropdown>
              </div>
            }
          </div>
        </div>
      }
      @if (isSuperUser) {
        <i class="fas fa-bell alert-bell" pBadge [value]="alertCount.toString()"
           (click)="toggleAlertMenu(); menuOpen = false; dataPerspectiveOpen = false;"></i>
      }
      @if (personPhotoSrc) {
        <img class="profile-img" ngSrc="{{personPhotoSrc}}" alt="" width="34" height="34"
             (click)="toggleMenu($event); alertsOpen = false; dataPerspectiveOpen = false;" [priority]="true"/>
      }
      @if (menuOpen) {
        <div class="dropdown-content">
          <div class="menu-link" routerLink="profile"><i class="fas fa-user"></i> My Profile</div>
          <div class="menu-link settings" routerLink="settings"><i class="fas fa-user-cog"></i> Settings & Security
          </div>
<!--          for later bk-->
          <div class="menu-link settings" (click)="contactSupport()"><i class="fas fa-user-headset"></i> Contact Support</div>
          <div class="menu-link profile-btn" (click)="logOut()"><i class="pi pi-power-off"></i> Log Out</div>
          <div class="divider-line"></div>
          <div class="mb-10">Welcome back, {{ personName }}.</div>
          <div class="mb-10">(Logged in since {{ loggedIn }})</div>
          <div class="divider-line"></div>
        </div>
      }
    </div>
  </div>
  <div class="toast-container">
    <p-toast [baseZIndex]="16000" position="top-center"></p-toast>
  </div>
</div>
