import {Injectable} from '@angular/core';
// import {ContextModel} from '@app/models/context.model';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  // context: ContextModel = this.contextObject;
  private contextDataPerspective$ = new BehaviorSubject<string>('');
  context$ = this.contextDataPerspective$.asObservable();

  set contextObject(value) {
    sessionStorage.setItem('contextObject', JSON.stringify(value));
  }

  get contextObject() {
    return JSON.parse(sessionStorage.getItem('contextObject'));
  }

  setContextPerspective(setContext: string[]) {
    // clear out existing selections, and save
    const resetContext = this.contextObject;
    resetContext.selOrganizationId = null;
    resetContext.selOrganizationDesc = null;
    this.contextObject = resetContext;
    // fire on route change
    let useContext: string;
    const newContext = resetContext;
    newContext.pageContexts = setContext;
    if (setContext.indexOf(newContext.selContext) > -1) {
      useContext = newContext.selContext;
    } else {
      useContext = setContext[0];
    }
    newContext.selContext = useContext;
    switch (useContext) {
      case 'MEMBERSHIP':
        newContext.selOrganizationId = newContext.selectedMOrgId;
        newContext.selOrganizationDesc = newContext.selectedMOrgDesc;
        break;
      case 'TRAINING':
        newContext.selOrganizationId = newContext.selectedTOrgId;
        newContext.selOrganizationDesc = newContext.selectedTOrgDesc;
        break;
      case 'ORGANIZE':
        newContext.selOrganizationId = newContext.selectedOOrgId;
        newContext.selOrganizationDesc = newContext.selectedOOrgDesc;
        break;
    }
    this.contextDataPerspective$.next(newContext.selOrganizationDesc);
    this.contextObject = newContext;
  }
}
