import {InterfaceObjectsModel} from '@app/models/interface-objects.model';
import {Injectable} from '@angular/core';

@Injectable()
export class pagePermissionsUtility {
  static checkPageRead(permissions: InterfaceObjectsModel[], value: string): boolean {
    return permissions.filter(e => e.ObjectEnumerator === value).length > 0;
  }

  static checkPageWrite(permissions: InterfaceObjectsModel[], value: string): boolean {
    const filterPermission: InterfaceObjectsModel[] = permissions.filter(e => e.ObjectEnumerator === value);
    return filterPermission[0] && filterPermission[0].Write === true;
  }

  static checkPageAdd(permissions: InterfaceObjectsModel[], value: string): boolean {
    const filterPermission: InterfaceObjectsModel[] = permissions.filter(e => e.ObjectEnumerator === value);
    return filterPermission[0] && filterPermission[0].HasAdd === true && filterPermission[0].CanAdd === true;
  }

  static checkPageExportPii(permissions: InterfaceObjectsModel[], value: string): boolean {
    const filterPermission: InterfaceObjectsModel[] = permissions.filter(e => e.ObjectEnumerator === value);
    return filterPermission[0] && filterPermission[0].ShowCanExportPii === true && filterPermission[0].CanExportPii === true;
  }

}
