import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { ToastModule } from 'primeng/toast';
import { SharedImports} from '@shared/shared-imports';
import { LoginRoutingModule } from './login-routing.module';


@NgModule({
    imports: [ReactiveFormsModule, RecaptchaV3Module, ToastModule, SharedImports, LoginRoutingModule, LoginRoutingModule.components],
    providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdPAREaAAAAAPDjF6ZnZDYJmmmdoabb31FDlhMJ' }]
})
export class LoginModule { }
