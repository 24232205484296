import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';
import {RosterQueryExistingModel} from '@app/models/roster-query-primeng.model';
import {CopyRosterQueryModel, CreateRosterQueryFiltersModel, CreateRosterQueryModel, RosterQueriesListModel, RosterQueriesModel, RosterQueryModel, UpdateRosterQueryModel} from '@app/models/roster-queries.model';

@Injectable({
  providedIn: 'root',
})
export class RosterQueryService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  // getRosterQueryKGrid(rosterQueryId: number): Observable<RosterQueryRosterViewGridModel> {
  //   return this.http.get<RosterQueryRosterViewGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/grid/${rosterQueryId}`);
  // }

  getRosterQueriesPrimeNG(dataMode: string, orgId: number, context: string, filterData): Observable<RosterQueriesListModel> {
    return this.http.post<RosterQueriesListModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/distribution/grid/${orgId}?mode=${dataMode}&context=${context}`, filterData);
  }

  // getStudentRosterQueryKGrid(rosterQueryId: number): Observable<RosterQueryStudentViewGridModel> {
  //   return this.http.get<RosterQueryStudentViewGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentrosterquery/grid/${rosterQueryId}`);
  // }

  // getRosterQueryAttributeKGrid(organizationId: number): Observable<RosterQueryAttributeGridModel> {
  //   return this.http.get<RosterQueryAttributeGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/attributegrid/${organizationId}`);
  // }

  // getRosterQueryVoterKGrid(organizationId: number): Observable<RosterQueryVoterGridModel> {
  //   return this.http.get<RosterQueryVoterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/votergrid/${organizationId}`);
  // }

  // getRosterQueryUnionPositionKGrid(organizationId: number): Observable<RosterQueryVoterGridModel> {
  //   return this.http.get<RosterQueryVoterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/unionpositiongrid/${organizationId}`);
  // }

  // getRosterQueryTrainingPositionKGrid(organizationId: number): Observable<RosterQueryVoterGridModel> {
  //   return this.http.get<RosterQueryVoterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/trainingpositiongrid/${organizationId}`);
  // }

  // getRosterQueryCommunicationKGrid(organizationId: number): Observable<RosterQueryVoterGridModel> {
  //   return this.http.get<RosterQueryVoterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/communicationstatusgrid/${organizationId}`);
  // }

  // getRosterQuerySurveyResponseKGrid(organizationId: number): Observable<RosterQueryVoterGridModel> {
  //   return this.http.get<RosterQueryVoterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/surveyresponsegrid/${organizationId}`);
  // }

  // getRosterQueryCertificationKGrid(organizationId: number): Observable<RosterQueryVoterGridModel> {
  //   return this.http.get<RosterQueryVoterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/certificationrosterquery/grid/${organizationId}`);
  // }

  getRosterQuery(rosterQueryId: number): Observable<RosterQueryModel> {
    return this.http.get<RosterQueryModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/${rosterQueryId}`);
  }

  getRosterQueryPrimeNg(rosterQueryId: number): Observable<RosterQueryExistingModel> {
    return this.http.get<RosterQueryExistingModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/${rosterQueryId}`);
  }

  createRosterQueryPrimeNg(modelData: RosterQueryExistingModel): Observable<any> {
    return this.http.post<RosterQueryExistingModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/create`, modelData);
  }

  updateRosterQueryPrimeNg(rosterQueryId: number, modelData: RosterQueryExistingModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/${rosterQueryId}`, modelData);
  }

  createRosterQuery(modelData: CreateRosterQueryFiltersModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/create`, modelData);
  }

  createRosterQueryNoFilters(modelData: CreateRosterQueryModel): Observable<any> {
    return this.http.post<CreateRosterQueryModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/create`, modelData);
  }

  updateRosterQuery(rosterQueryId: number, modelData: UpdateRosterQueryModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/${rosterQueryId}`, modelData);
  }

  deleteRosterQuery(rosterQueryId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/remove/${rosterQueryId}`);
  }

  copyRosterQuery(rosterQueryId: number, modelData: CopyRosterQueryModel): Observable<RosterQueriesModel> {
    return this.http.post<RosterQueriesModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/${rosterQueryId}/copy`, modelData);
  }

  uploadRosterQueryImportFile(rosterQueryId: number, format: string, clearExisting: boolean, formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/rosterquery/${rosterQueryId}/upload?format=${format}&clearExisting=${clearExisting}`, formData);
  }
}
