import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {LookupsService} from '@services/lookups.service';

@Injectable()
export class InactiveStatusUtility {
  constructor(private lookupsService: LookupsService) {
  }

  public getPersonProfileInactiveStatuses(context: string): any {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileStatusesLookup(context, false)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            const rtn: string[] = [];
            res.forEach(x => {
              if (x.Active !== true) {
                rtn.push(x.Description);
              }
            });
            resolve(rtn);
          }, error:
            (e) => {
              console.warn(e);
              reject([]);
            }
        });
    });
  }

  public getPersonProfileActiveStatuses(context: string): any {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileStatusesLookup(context, false)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            const rtn: string[] = [];
            res.forEach(x => {
              if (x.Active === true) {
                rtn.push(x.Description);
              }
            });
            resolve(rtn);
          }, error:
            (e) => {
              console.warn(e);
              reject([]);
            }
        });
    });
  }

  public getTrainingStatuses(scope: string): any {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTrainingStatusesLookup(scope)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            const rtn: string[] = [];
            res.forEach(x => {
              if (x.Active !== true) {
                rtn.push(x.Description);
              }
            });
            resolve(rtn);
          }, error:
            (e) => {
              console.warn(e);
              reject([]);
            }
        });
    });
  }
}

