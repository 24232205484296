import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';
import {tenantConfigsModel} from '@app/models/context.model';

@Injectable({
  providedIn: 'root',
})
export class TenantConfigService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getTenantConfigAll(): Observable<tenantConfigsModel[]> {
    return this.http.get<tenantConfigsModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/all`);
  }

  getTenantConfigValue(enumerator: string): Observable<string> {
    return this.http.get<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/value/${enumerator}`);
  }

  getTenantConfigBoolean(enumerator: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/boolean/${enumerator}`);
  }

  getTenantConfigValueByOrganization(enumerator: string, organizationId: number): Observable<string> {
    return this.http.get<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/value/${enumerator}/byorganization/${organizationId}`);
  }

  getTenantConfigBooleanByOrganization(enumerator: string, organizationId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/boolean/${enumerator}/byorganization/${organizationId}`);
  }

  getTenantConfigValueByOrganizationGrouped(enumerator: string, organizationId: number): Observable<string> {
    return this.http.get<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/value/${enumerator}/byorganization/${organizationId}?usegrouporgid=true`);
  }

  getTenantConfigBooleanByOrganizationGrouped(enumerator: string, organizationId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/boolean/${enumerator}/byorganization/${organizationId}?usegrouporgid=true`);
  }
}
