import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {SharedImports} from '@shared/shared-imports';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';

import {UserAccountPasswordResetEmailModel} from '@app/models/user-account.model';
import {UserAccountService} from '@services/user-account.service';
import {EmailAddressLookupModel} from '@app/models/lookups.model';
import {LookupsService} from '@services/lookups.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContextModel} from '@app/models/context.model';
import {EnvironmentsService} from '@services/environments.service';
import {ContextService} from '@services/context.service';
import {Title} from '@angular/platform-browser';

@Component({
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.scss'],
  standalone: true,
  imports: [SharedImports]
})
export class PasswordResetRequestComponent implements OnInit, OnDestroy {
  prrForm: FormGroup;
  userAccountPasswordResetEmailModel: UserAccountPasswordResetEmailModel;
  signup = false;
  isLoading: boolean = false;
  emails: EmailAddressLookupModel[];
  tenant: string;
  logo: string;

  private ngUnsubscribe = new Subject();

  constructor(private userAccountService: UserAccountService, private router: Router,
              private lookupsService: LookupsService, private route: ActivatedRoute,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private environmentsService: EnvironmentsService,
              private contextService: ContextService, private titleService: Title) {
    this.prrForm = this.formBuilder.group({
      username: new FormControl(localStorage.getItem('rememberMe'), [Validators.required, Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      emailTarget: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.getBaseUrl().then(() => {
      if (this.prrForm.get('username').value) {
        this.prrForm.controls.emailTarget.enable();
      } else {
        this.prrForm.controls.emailTarget.disable();
      }
      this.onChanges();
      this.loadSelect(this.prrForm.controls.username.value);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onChanges(): void {
    this.prrForm.controls.username.valueChanges.subscribe({
      next: (val) => {
        if (val) {
          this.prrForm.controls.emailTarget.enable();
        } else {
          this.prrForm.controls.emailTarget.disable();
        }
        this.loadSelect(val);
      }
    });
  }

  getBaseUrl() {
    return new Promise((resolve, reject) => {

      this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
        if (rtnTenant) {
          this.setUrl(rtnTenant);
        }
      });

      // switch (true) {
      //   case environment.environmentName === 'dev':
      //   case environment.environmentName === 'staging':
      //   case environment.environmentName === 'demo':
      //     this.environmentsService.getEnvironment(environment.environmentName).then((rtnTenant) => {
      //       if (rtnTenant) {
      //         this.setUrl(rtnTenant);
      //       }
      //     });
      //     break;
      //   case environment.local
      //   && environment.environmentName !== 'dev'
      //   && environment.environmentName !== 'demo'
      //   && environment.environmentName !== 'staging':
      //     if (this.tenant) {
      //       this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //         if (rtnTenant) {
      //           this.setUrl(rtnTenant);
      //         }
      //       });
      //     }
      //     break;
      //   case !environment.local:
      //     this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //       if (rtnTenant) {
      //         this.setUrl(rtnTenant);
      //       }
      //     });
      //     break;
      // }


      // if(environment.environmentName === 'demo') {
      //   this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.environmentName === 'dev') {
      //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.local && environment.environmentName !== 'dev' && environment.environmentName !== 'demo') {
      //   this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //     if(rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(!environment.local) {
      //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // }
      resolve(null);
    });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.logo = this.contextService.contextObject.tenantLogo;
  }

  loadSelect(username: string) {
    this.lookupsService.getEmailAddressByUsernameLookup(username)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.emails = res;
        }
      });
  }

  resetPassword() {
    this.isLoading = true;
    const tenantid: number = this.emails.filter(x => x.EmailID === +this.prrForm.get('emailTarget').value)[0].TenantID;
    this.userAccountPasswordResetEmailModel = {
      UserName: this.prrForm.get('username').value,
      EmailID: this.prrForm.get('emailTarget').value,
      TenantID: (tenantid) ? tenantid : 0,
      TenantName: this.contextService.contextObject.tenantName
    };
    this.userAccountService.generatePasswordResetEmail(this.userAccountPasswordResetEmailModel)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (msg: string) => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: msg, life: 4500});
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 5000);
        }, error: (e) => {
          this.messageService.add({severity: 'error', summary: 'Failure', detail: e, life: 4500});
          this.isLoading = false;
        }
      });
  }
}
