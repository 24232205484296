import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {AddressesGridModel, AddressModel, CreateUpdateAddressModel, SmartyStreetsModel} from '@app/models/addresses.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getPersonAddressesPrimeNG(dataMode: string, personId: number, filterData): Observable<AddressesGridModel> {
    return this.http.post<AddressesGridModel>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/address/grid?mode=${dataMode}`, filterData);
  }

  getPersonAddress(personId: number, addressId: number): Observable<AddressModel> {
    return this.http.get<AddressModel>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/address/${addressId}`);
  }

  createPersonAddress(personId: number, modelData: CreateUpdateAddressModel): Observable<any> {
    return this.http.post<any>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/address`, modelData);
  }

  updatePersonAddress(personId: number, addressId: number, modelData: CreateUpdateAddressModel): Observable<any> {
    return this.http.put<any>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/address/${addressId}`, modelData);
  }

  deleteAddress(personId: number, addressId: number): Observable<any> {
    return this.http.delete<any>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/address/remove/${addressId}`);
  }

  getUserPersonAddressesPrimeNG(dataMode: string, filterData): Observable<AddressesGridModel> {
    return this.http.post<AddressesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/address/grid?mode=${dataMode}`, filterData);
  }

  getUserPersonAddress(addressId: number): Observable<AddressModel> {
    return this.http.get<AddressModel>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/address/${addressId}`);
  }

  createUserPersonAddress(modelData: CreateUpdateAddressModel): Observable<any> {
    return this.http.post<any>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/address`, modelData);
  }

  updateUserPersonAddress(addressId: number, modelData: CreateUpdateAddressModel): Observable<any> {
    return this.http.put<any>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/address/${addressId}`, modelData);
  }

  deleteUserAddress(addressId: number): Observable<any> {
    return this.http.delete<any>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/address/remove/${addressId}`);
  }

  getAddressSuggestions(addressLookupData: SmartyStreetsModel): Observable<any> {
    // United States address autocomplete
    // https://www.smarty.com/
    Userpilot.track('Address Lookup');
    const key = '12513235913956914';
    const addressFilter = addressLookupData.prefix !== null && addressLookupData.prefix !== undefined && addressLookupData.prefix !== '' ? '&search=' + encodeURIComponent(addressLookupData.prefix) : '';
    const cityFilter = addressLookupData.city_filter !== null && addressLookupData.city_filter !== undefined && addressLookupData.city_filter !== '' ? '&prefer_cities=' + encodeURIComponent(addressLookupData.city_filter) : '';
    const stateFilter = addressLookupData.state_filter !== null && addressLookupData.state_filter !== undefined && addressLookupData.state_filter !== '' ? '&prefer_states=' + encodeURIComponent(addressLookupData.state_filter) : '';
    const url: string = 'https://us-autocomplete-pro.api.smartystreets.com/lookup?key=' + key + addressFilter + cityFilter + stateFilter;
    return this.http.get<any>(url);
  }

  getInternationalAddressSuggestions(addressLookupData: SmartyStreetsModel): Observable<any> {
    // International address autocomplete
    // https://www.smarty.com/
    Userpilot.track('International Address Lookup');
    const key = '12513235913956914';
    const addressFilter = addressLookupData.prefix !== null && addressLookupData.prefix !== undefined && addressLookupData.prefix !== '' ? '&search=' + encodeURIComponent(addressLookupData.prefix) : '';
    let url: string;
    if (addressLookupData.address_id !== null && addressLookupData.address_id !== undefined) {
      url = 'https://international-autocomplete.api.smarty.com/v2/lookup/' + addressLookupData.address_id + '?key=' + key + '&country=' + addressLookupData.country + '&' + addressFilter + '&license=international-autocomplete-v2-cloud';
    } else {
      url = 'https://international-autocomplete.api.smarty.com/v2/lookup?key=' + key + '&country=' + addressLookupData.country + '&' + addressFilter + '&license=international-autocomplete-v2-cloud';
    }
    return this.http.get<any>(url);
  }
}
