import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {PDFExportModel} from '@app/models/pdf-export.model';
import {UserAccountService} from '@services/user-account.service';
import {TenantConfigDataService} from '@services/tenant-config-data.service';
import {ContextService} from '@services/context.service';
import {Userpilot} from 'userpilot';
import {DatePipe} from '@angular/common';

@Injectable()
export class PDFExportUtility {
  // https://www.npmjs.com/package/jspdf
  // https://www.npmjs.com/package/jspdf-autotable
  constructor(private userAccountService: UserAccountService, private tenantConfigDataService: TenantConfigDataService,
              private contextService: ContextService, private datePipe: DatePipe) {
  }

  public createListPDF(pdfParts: PDFExportModel, existingDoc?: jsPDF, firstPage?: boolean, lastPage?: boolean): Promise<jsPDF> {
    // pdfParts = parts of the report to be built
    // existingDoc = an existing jsPDF object, used for looping several records and pdf documents together
    // firstPage = is this the first page, used for looping several records and pdf documents together
    // lastPage = is this the last page, used for looping several records and pdf documents together
    return new Promise((resolve, reject) => {
      if (jsPDF) {
        if ((!existingDoc) || (lastPage)) {
          Userpilot.track(`PDF Report: ${pdfParts.Header1}`);
        }
        this.userAccountService.getUserBanner()
          .pipe(take(1))
          .subscribe({
            next: () => {
              let doc: jsPDF;
              if (existingDoc !== null && existingDoc !== undefined) {
                doc = existingDoc;
                if (firstPage === false) {
                  doc.addPage();
                  doc.setPage(doc.getNumberOfPages());
                }
              } else {
                doc = new jsPDF(pdfParts.Orientation, 'in', 'a4', true);
              }

              // set the logo
              let tenantName: string;
              if (sessionStorage.getItem('workingAs')) {
                tenantName = sessionStorage.getItem('workingAs').toLowerCase();
              } else {
                tenantName = this.contextService.contextObject.tenantName.toLowerCase();
              }

              // const logoImage: string = res.LogoURL ? res.LogoURL : 'assets/images/' + tenantName + '/default_post_image.png';
              const logoImage: string = 'assets/images/' + tenantName + '/default_post_image.png';
              const tempImage: HTMLImageElement = new Image();
              // let imageW: number;
              // let imageH: number;
              // // evaluate onload to get image sizes
              // tempImage.onload = function() {
              //   // add tenant logo
              //   if (tempImage.height > tempImage.width) {
              //     imageW = 0
              //     imageH = .650;
              //   } else {
              //     imageW = 1.65
              //     imageH = 0;
              //   }
              // };
              // // this must be done AFTER setting onload
              tempImage.src = logoImage;
              doc.addImage(tempImage, .500, .150, 0, .550);

              // add report header 1
              if (pdfParts.Header1) {
                doc.setFontSize(14);
                doc.text(pdfParts.Header1, doc.internal.pageSize.width / 2, .500, {align: 'center'});
              }

              // add report header 2
              if (pdfParts.Header2) {
                doc.setFontSize(10);
                doc.text(pdfParts.Header2, doc.internal.pageSize.width / 2, .750, {align: 'center'});
              }

              // add report header3
              if (pdfParts.Header3) {
                doc.setFontSize(8);
                doc.text(pdfParts.Header3, doc.internal.pageSize.width / 2, 1, {
                  align: 'center',
                  maxWidth: 6.000
                });
              }

              // set startY position based on the existence of filters
              const header3Length: number = pdfParts.Header3?.length - 95;
              const startYValue: number = (pdfParts.Header3 ? 1.350 : 1) + ((pdfParts.Header3?.length > 0) ? .155 * (header3Length / 95) : 0);

              // set table fontsize
              const tableFontSize: number = pdfParts.TableFontSize ? pdfParts.TableFontSize : 8;

              // set position of the line and footer elements
              let linex1: number;
              let liney1: number;
              let linex2: number;
              let liney2: number;
              let elementx: number;
              let elementy: number;
              switch (pdfParts.Orientation) {
                case 'l':
                  linex1 = .500;
                  liney1 = 7.800;
                  linex2 = 11.180;
                  liney2 = 7.800;
                  elementx = 12.450;
                  elementy = 8.000;
                  break;
                case 'p':
                  linex1 = .500;
                  liney1 = 11.300;
                  linex2 = 7.780;
                  liney2 = 11.300;
                  elementx = 8.950;
                  elementy = 11.500;
                  break;
              }

              autoTable(doc,
                {
                  theme: (pdfParts.Theme) ? pdfParts.Theme : 'striped',
                  headStyles: (pdfParts.Theme !== 'plain') ? {fillColor: '#4A4A4A'} : null,
                  head: [pdfParts.TableHead],
                  bodyStyles: (pdfParts.Theme === 'grid') ? {lineWidth: 0.0005} : null,
                  body: pdfParts.TableBody,
                  startY: startYValue,
                  showFoot: 'lastPage',
                  footStyles: (pdfParts.Theme !== 'plain') ? {fillColor: '#4A4A4A'} : null,
                  foot: [pdfParts.TableFoot],
                  styles: {fontSize: tableFontSize}
                }
              );

              if ((!existingDoc) || (lastPage)) {
                const today: Date = new Date();

                // get the application url for the report footer from the tenant config
                let CLIENTAPPURL = this.tenantConfigDataService.getStringValue('CLIENTAPPURL');
                CLIENTAPPURL = CLIENTAPPURL.replace('https://', '');
                if (CLIENTAPPURL.substring(CLIENTAPPURL.length - 1) === '/') {
                  CLIENTAPPURL = CLIENTAPPURL.substring(0, CLIENTAPPURL.length - 1);
                }

                // set a placeholder for the overall page count
                const totalPagesExp: string = '{total_pages_count_string}';

                // add page footer to each page
                let pageCount: number = 1;
                while (pageCount <= doc.getNumberOfPages()) {
                  // draw line for page footer separation
                  doc.setPage(pageCount).setLineWidth(0.005);
                  doc.setPage(pageCount).line(linex1, liney1, linex2, liney2);

                  // add date on left
                  doc.setPage(pageCount).setFontSize(8);
                  doc.setPage(pageCount).text(today.toDateString(), .5, elementy, {align: 'left'});

                  // add client application url in the center
                  doc.setPage(pageCount).setTextColor(0, 0, 255);
                  doc.setPage(pageCount).textWithLink(CLIENTAPPURL, doc.internal.pageSize.width / 2, elementy, {
                    align: 'center',
                    url: CLIENTAPPURL,
                    fontsize: 8
                  });

                  // add the page numbers on the right
                  doc.setPage(pageCount).setFontSize(8);
                  doc.setPage(pageCount).setTextColor(0, 0, 0);
                  doc.setPage(pageCount).text('Page ' + pageCount + ' of ' + totalPagesExp, elementx, elementy, {align: 'right'});

                  pageCount++;
                }

                // replace the placeholder with the actual number of pages
                if (typeof doc.putTotalPages === 'function') {
                  doc.putTotalPages(totalPagesExp);
                }

                // save the pdf document
                doc.save(pdfParts.Header1.replaceAll(' ', '-').toLowerCase() + '_' + this.datePipe.transform(new Date(), 'yyyyMMddhhmmss') + '.pdf');
              } else {
                resolve(doc);
              }
            }
          });
      }
    });
  }
}
