<div [ngClass]="{'optional-styling' : optionalStyling, 'centered-styling' : centeredStyling}">
  <nav id="nav" role="navigation" class="top-nav header-color">
    <div class="menu-container">
      <ul class="exo-menu">
        @for (item of activeItems; track item; let i = $index) {
          <li class="drop-down" (mouseover)="hideMenu = false">
            <a class="root-menu pointer" [ngClass]="{'active-menu': headerMenu === item.Label.toLowerCase()}">
              <!--      <span class="material-icons">{{item.iconName}} </span>-->
              <span class="menu-label"> {{ item.Label }}</span>
            </a>
            @if (item.Items) {
              <div class="drop-down-ul mega"
                   [ngClass]="{'closed' : hideMenu, 'report-menu-4' : (item.Label === 'Reporting' && i >= 4),  'report-menu-3' : (item.Label === 'Reporting' && i === 3)}">
                <div class="mega-container">
                  @for (subItem of item.Items; track subItem) {
                    <ul class="mega-category">
                      <div class="mega-header"> {{ subItem.Label }}</div>
                      <li>
                        @if(item.Label === 'Support' && subItem.Label === 'Help' && !_hideContactSupport()) {
                          <a (click)="openContanct()">
                            <span class="fas fa-user-headset"></span>
                            <span> Contact Support</span>
                          </a>
                        }
                      </li>
                      @for (subSubItem of subItem.Items; track subSubItem) {
                        <li>
                          @if (!subSubItem.Disabled) {
                            <a (click)="goToUrl(subSubItem.Route)">
                              <span class="{{subSubItem.Icon}}"></span>
                              <span> {{ subSubItem.Label }}</span>
                            </a>
                          }

                        </li>
                      }
                    </ul>
                  }
                </div>
              </div>
            }
          </li>
        }
      </ul>
    </div>
  </nav>
</div>
