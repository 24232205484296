import {Injectable} from '@angular/core';
import {setPageRowCountDefault100} from './component-manager-utility';
import {TenantConfigDataService} from '@services/tenant-config-data.service';
import {ContextService} from '@services/context.service';

@Injectable()
export class PageRowCountUtility {
  userDefault: number;
  tcDEFAULTPAGEROWCOUNT: string;
  tenantDefault: number = 25;
  public _setPageRowCountDefault100 = setPageRowCountDefault100;

  constructor(private tenantConfigDataService: TenantConfigDataService, private contextService: ContextService) {
  }

  public pageRowCount(setPageRowCount?: number): number {
    let rtn: number;
    if (this.contextService.contextObject.UserPreferences && this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_PageRowCount')?.Value !== undefined &&
      this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_PageRowCount')?.Value !== null &&
      this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_PageRowCount')?.Value !== '--') {
      this.userDefault = +this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_PageRowCount')?.Value;
    }
    this.tcDEFAULTPAGEROWCOUNT = this.tenantConfigDataService.getStringValue('DEFAULTPAGEROWCOUNT');
    if (this.tcDEFAULTPAGEROWCOUNT) {
      this.tenantDefault = +this.tcDEFAULTPAGEROWCOUNT;
    }
    switch (true) {
      case setPageRowCount !== undefined && setPageRowCount !== null:
        rtn = setPageRowCount;
        break;
      case this.userDefault !== undefined && this.userDefault !== null:
        rtn = this.userDefault;
        break;
      case this.tenantDefault !== undefined && this.tenantDefault !== null:
        rtn = (this._setPageRowCountDefault100()) ? 100 : this.tenantDefault;
        break;
      default:
        rtn = 25;
        break;
    }
    return rtn;
  }
}
