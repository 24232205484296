<form [formGroup]="mainForm" class="margin-top-10 padding-bottom-50">
  <div class="p-grid p-fluid">
      <div class="col-md-4 col-sm-2 col-xs-12 margin-top-10">
        <label>Name <sup class="req">*</sup></label>
        <input type="text" pInputText  formControlName="name">
<!--        <p-calendar [(ngModel)]="tempWorkMonthDate" appendTo="body" placeholder="mm/yyyy" view="month" dateFormat="mm/yy" [showIcon]="true" yearRange="1950:2030" [ngModelOptions]="{standalone: true}"></p-calendar>-->
      </div>
      <div class="col-md-4 col-sm-2 col-xs-12 margin-top-10">
        <label>Email <sup class="req">*</sup></label>
        <input type="text" pInputText  formControlName="email">
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12  margin-top-10">
        <label>Phone </label>
        <p-inputMask type="text" pInputText formControlName="phone" [className]="'form-input no-border'" mask="(999) 999-9999"
                     unmask="true" tabindex="0"></p-inputMask>
      </div>
    <div class="col-md-12 col-sm-2 col-xs-12 margin-top-10">
      <label>Subject <sup class="req">*</sup></label>
      <input type="text" pInputText  formControlName="subject">
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 input-container p-button-auto-width image-uploader">
      <p>*Files must be 30 MB or less in size. If necessary, change the name of your files appropriately prior to uploading them. Upload one file at a time.</p>
      <p-fileUpload accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.ppt,.pptx" #fileUploader name="uploadedFiles[]" (onSelect)="onUpload($event)"
                    [multiple]="true" (onClear)="clearUpload()" [showUploadButton]="false" maxFileSize="31457280">
<!--        <ng-template let-file pTemplate='file' let-clearCallback="clearCallback">-->
<!--          <div>-->
<!--&lt;!&ndash;            {{file.name}} - ({{file.size}} bytes)&ndash;&gt;-->
<!--          </div>-->
<!--        </ng-template>-->
      </p-fileUpload>
    </div>
    @if (showProgress) {
      <div class="col-md-12">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
    }
<!--    @for (file of freshDeskData?.attachments; track file;  let i = $index) {-->
<!--      <div class="col-md-3 col-sm-3 col-sm-4 relative">-->
<!--        <div class="close-button" (click)="deleteImage(i)"><i class="fas fa-times-circle pointer"></i></div>-->
<!--        <div [ngStyle]="{'background-image': 'url(' + file.attachment_url + ')', 'height': '100px', 'overflow': 'hidden', 'width': '100%'}"></div>-->
<!--      </div>-->
<!--    }-->
    <div class="col-md-12 col-sm-2 col-xs-12 margin-top-10">
      <label>Description <sup class="req">*</sup></label>
      <textarea type="text" pInputTextarea rows="8" formControlName="description"></textarea>
    </div>
    <div class="col-md-12 text-align-right margin-top-10">
      <div class="action-buttons float-right">
        <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times"
                pTooltip="Cancel" tooltipPosition="bottom"
                (click)="cancel()"></button>
        @if (!isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                  tooltipPosition="bottom"
                  (click)="processData()" [className]="'inline-button'"
                  [disabled]="mainForm.invalid || !mainForm.dirty"></button>
        }
        @if (isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    </div>
  </div>
</form>
