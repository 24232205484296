import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {SharedImports} from '@shared/shared-imports';
import {take, takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ContextModel} from '@app/models/context.model';
import {EnvironmentsService} from '@services/environments.service';
import {ContextService} from '@services/context.service';
import {ClassesModulesService} from '@services/classes.service';
import {ClassModel, ClassUnauthClassModel, ClassUnauthModel} from '@app/models/classes.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserRegistrationService} from '@services/user-registration.service';
import {GenericSelectModel} from '@app/models/generic-select.model';
import {LookupsDataService} from '@services/lookups-data.service';
import {OrganizationService} from '@services/organizations.service';
import {UserRegistrationClassModel} from '@app/models/user-registration.model';
import {intCandidatesModel, SmartyStreetsIntCandidatesModel, SmartyStreetsModel, SmartyStreetsSuggestionsModel, suggestionsModel, VerifyAddressModel} from '@app/models/addresses.model';
import {StateLookupModel} from '@app/models/lookups.model';
import {AddressesService} from '@services/addresses.service';
import {MapsService} from '@services/maps.service';
import {LookupsService} from '@services/lookups.service';
import {Title} from '@angular/platform-browser';
import {AutoComplete} from 'primeng/autocomplete';
import {TenantConfigDataService} from '@services/tenant-config-data.service';

@Component({
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss'],
  standalone: true,
  imports: [SharedImports]
})
export class PublicClassesComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  publicClasses: ClassUnauthModel[] = [];
  loading: boolean;
  isDirty: boolean;
  tenant: string;
  logo: string;
  pid: string;
  spid: number;
  tid: string;
  isEdit: boolean = false;
  isRegistering: boolean = false;
  selectedClass: ClassUnauthModel;
  selectedClassDetail: ClassUnauthClassModel;
  noClasses: boolean = false;
  selStateId: number = null;
  locationData: ClassModel[];
  // locationDisplay: string;
  locationDisplayWithName: string;
  international: boolean;
  internationalEntries: boolean;
  suggestAddress: boolean = false;
  suggestAddressJSON: SmartyStreetsModel;
  suggestions: SmartyStreetsSuggestionsModel[];
  intCandidateSuggestions: SmartyStreetsIntCandidatesModel[];
  autocompleteData = {} as suggestionsModel;
  autocompleteItemData: SmartyStreetsSuggestionsModel[] = [];
  intAutocompleteEntryData = {} as intCandidatesModel;
  intAutocompleteEntryItemData: SmartyStreetsIntCandidatesModel[] = [];
  inputObjState: GenericSelectModel;
  inputObjCountry: GenericSelectModel;
  setStateId: number;
  setCountryId: number;
  setCountryDescription: string;
  verifyAddress = {} as VerifyAddressModel;
  latitude: number;
  longitude: number;
  @ViewChild('autocomplete') autocompleteElement: AutoComplete;

  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private route: ActivatedRoute,
              private confirmationService: ConfirmationService, private tenantConfigDataService: TenantConfigDataService,
              private messageService: MessageService, private lookupsService: LookupsService,
              private environmentsService: EnvironmentsService, private activatedRoute: ActivatedRoute,
              private contextService: ContextService, private organizationService: OrganizationService,
              private classesService: ClassesModulesService, private lookupsDataService: LookupsDataService,
              private formBuilder: FormBuilder, private userRegistrationService: UserRegistrationService,
              private addressesService: AddressesService, private mapsService: MapsService,
              private titleService: Title
  ) {
    this.mainForm = this.formBuilder.group({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      phone: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      CountryID: new FormControl(null, [Validators.required]),
      Address1: new FormControl(null, [Validators.required]),
      City: new FormControl(null, [Validators.required]),
      StateProvinceID: new FormControl(null, [Validators.required]),
      Zip: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.suggestAddress = false;//this.tenantConfigDataService.getBooleanValue('SUGGESTADDRESS');
    this.activatedRoute.queryParams.pipe(take(2)).subscribe({
      next: (params) => {
        this.tenant = params.tenant;
        this.getBaseUrl().then(() => {
          this.route.params.pipe().subscribe({
            next: (res) => {
              if (this.router.url.includes('/public/classes/')) {
                this.getClasses(parseInt(res.id, 10));
              } else {
                this.getClasses();
              }
            }
          });
        });
      }
    });

    if (!this.setCountryId) {
      this.mainForm.get('Address1').disable();
      this.mainForm.get('City').disable();
      this.mainForm.get('StateProvinceID').disable();
      this.mainForm.get('Zip').disable();
    }
    this.mainForm.get('CountryID').valueChanges.subscribe({
      next: () => {
        if (!this.mainForm.get('CountryID').value && this.mainForm.get('CountryID').value !== '') {
          this.mainForm.get('Address1').disable();
          this.mainForm.get('City').disable();
          this.mainForm.get('StateProvinceID').disable();
          this.mainForm.get('Zip').disable();
        } else {
          this.mainForm.get('Address1').enable();
          this.mainForm.get('City').enable();
          this.mainForm.get('StateProvinceID').enable();
          this.mainForm.get('Zip').enable();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getBaseUrl() {
    return new Promise((resolve, reject) => {
      this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
        if (rtnTenant) {
          this.setUrl(rtnTenant);
        }
      });
      // switch (true) {
      //   case environment.environmentName === 'dev':
      //   case environment.environmentName === 'staging':
      //   case environment.environmentName === 'demo':
      //     this.environmentsService.getEnvironment(environment.environmentName).then((rtnTenant) => {
      //       if (rtnTenant) {
      //         this.setUrl(rtnTenant);
      //       }
      //     });
      //     break;
      //   case environment.local
      //   && environment.environmentName !== 'dev'
      //   && environment.environmentName !== 'demo'
      //   && environment.environmentName !== 'staging':
      //     if (this.tenant) {
      //       this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //         if (rtnTenant) {
      //           this.setUrl(rtnTenant);
      //         }
      //       });
      //     }
      //     break;
      //   case !environment.local:
      //     this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //       if (rtnTenant) {
      //         this.setUrl(rtnTenant);
      //       }
      //     });
      //     break;
      // }
      // if(environment.environmentName === 'demo') {
      //   this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.environmentName === 'dev') {
      //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.local && environment.environmentName !== 'dev' && environment.environmentName !== 'demo') {
      //   this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //     if(rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(!environment.local) {
      //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // }
      resolve(null);
    });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.logo = this.contextService.contextObject.tenantLogo;
    if (!this.tenant) {
      this.tenant = this.contextService.contextObject.tenantName;
    }
  }

  getClasses(id?: number): void {
    this.loading = true;
    this.route.queryParams.subscribe({
      next: () => {
        if (id) {
          this.classesService
            .getAvailableClassesOrgUnauthFlat(this.tenant, id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                let courseCnt: number = -1;
                let courseId: number = 0;
                res.forEach(x => {
                  if (x.CourseID !== courseId) {
                    this.publicClasses.push({
                      Cost: x.Cost,
                      CourseDescription: x.CourseDescription,
                      CourseID: x.CourseID,
                      CourseName: x.CourseName,
                      RequireMembership: x.RequireMembership,
                      Classes: []
                    });
                    courseId = x.CourseID;
                    courseCnt++;
                  }
                  this.publicClasses[courseCnt].Classes.push({
                    ClassID: x.ClassID,
                    CourseID: x.CourseID,
                    FilledSeats_N: x.FilledSeats_N,
                    Instructors: x.Instructors,
                    LocationAddress1: x.LocationAddress1,
                    LocationAddress2: x.LocationAddress2,
                    LocationCity: x.LocationCity,
                    LocationEmail: x.LocationEmail,
                    LocationName: x.LocationName,
                    LocationPhone: x.LocationPhone,
                    LocationStateProvince: x.LocationStateProvince,
                    LocationZip: x.LocationZip,
                    StartDate_D: x.StartDate_D,
                    StartTime_T: x.StartTime_T,
                    EndDate_D: x.EndDate_D,
                    EndTime_T: x.EndTime_T,
                    MaxSeats_N: x.MaxSeats_N,
                    TimezoneDescription: x.TimezoneDescription,
                    LanguageDescription: x.LanguageDescription,
                    SignupNotes: x.SignupNotes,
                    ComplexSchedule: x.ComplexSchedule,
                    Schedule: x.Schedule,
                    ScheduleArray: x.ScheduleArray,
                    AllowWaitListOption: x.AllowWaitListOption
                  });
                });
                this.noClasses = res.length <= 0;
                this.loading = false;
              }
            });
        } else {
          this.classesService
            .getAvailableClasses(this.tenant)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.publicClasses = res;
                this.noClasses = res.length <= 0;
                this.loading = false;
              }
            });
        }
      }
    });
  }

  classSelected(course: ClassUnauthModel, classDetail: ClassUnauthClassModel): void {
    this.isEdit = true;
    this.selectedClass = course;
    this.selectedClassDetail = classDetail;
    this.getLocationData(classDetail);
    this.initCountry(false);
  }

  register(): void {
    this.isRegistering = true;
    let newAddress;
    if (this.suggestAddress) {
      if (this.mainForm.get('Address1').value && typeof this.mainForm.get('Address1').value === 'object') {
        if (this.mainForm.get('Address1').value.street_line) {
          newAddress = this.mainForm.get('Address1').value.street_line;
        }
      } else {
        newAddress = this.mainForm.get('Address1').value;
      }
    } else {
      newAddress = this.mainForm.get('Address1').value;
    }
    const registration: UserRegistrationClassModel = {
      ClassID: this.selectedClassDetail.ClassID,
      TenantName: this.tenant,
      FirstName: this.mainForm.get('firstName').value,
      LastName: this.mainForm.get('lastName').value,
      PrimaryEmailAddress: this.mainForm.get('email').value,
      SecondaryPhonePhoneTypeEnum: 'MOBILE',
      SecondaryPhoneNumber: this.mainForm.get('phone').value,
      PrimaryCountryID: this.setCountryId,
      PrimaryAddress1: newAddress,
      PrimaryCity: this.mainForm.get('City').value,
      PrimaryStateProvinceID: this.mainForm.get('StateProvinceID').value,
      PrimaryZip: this.mainForm.get('Zip').value
    };
    this.userRegistrationService.classRegistration(registration)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          //this.messageService.add({severity:'success', summary: 'Registration Complete', detail: 'Thank you for registering. We\'ll see you soon!', life: 3500});
          this.isRegistering = false;
          // setTimeout(() => {
          //   location.reload();
          // }, 3500);
          this.confirmationService.confirm({
            message: res.ResultMessage,
            acceptLabel: 'Continue',
            rejectVisible: false,
            header: res.ResultCode === 0 ? 'Registration Success' : 'Error',
            icon: res.ResultCode === 0 ? 'pi pi-check-circle' : 'pi pi-check-times',
            accept: () => {
              location.reload();
            },
          });
        }, error: (e) => {
          if (e?.error?.Message) {
            this.confirmationService.confirm({
              message: e.error.Message.replace('UM: ', ''),
              acceptLabel: 'Continue',
              rejectVisible: false,
              header: 'Registration Prevented',
              icon: 'pi pi-exclamation-circle',
              accept: () => {
              },
            });
          } else {
            this.confirmationService.confirm({
              message: 'Your registration was not received. Please try again.',
              acceptLabel: 'Continue',
              rejectVisible: false,
              header: 'Registration Incomplete',
              icon: 'pi pi-exclamation-circle',
              accept: () => {
              },
            });
          }
        }
      });
  }

  initCountry(disable: boolean): void {
    this.inputObjCountry = {
      labelText: 'Country (Select to add Street Address)',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setCountryId,
      disabled: disable,
      canWrite: true
    };
    this.lookupsDataService.getCountriesUnauthLookupData(this.tenant).then((lookupData) => {
      this.inputObjCountry.data = lookupData;
      this.inputObjCountry = Object.assign({}, this.inputObjCountry);
    });
  }

  getCountryData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setCountryId = event[0].ID;
      this.setCountryDescription = event[0].Description;
    } else {
      this.setCountryId = null;
      this.setCountryDescription = null;
    }
    if (this.setCountryId !== null) {
      this.initState(false, this.tenant);
    }
    this.mainForm.get('CountryID').setValue(this.setCountryId);
    if (this.setCountryId === null) {
      this.setStateId = null;
      this.mainForm.get('StateProvinceID').setValue(this.setStateId);
      this.initState(true, this.tenant);
    }
    this.mainForm.markAsDirty();
  }


  initState(disable: boolean, tenantName: string): void {
    this.inputObjState = {
      labelText: (this.setCountryDescription === 'Canada') ? 'Province' : 'State',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setStateId,
      disabled: disable,
      emitChangeOnLoad: true
    };
    if (tenantName) {
      this.lookupsDataService.getStatesUnauthLookup(tenantName, this.setCountryId).then((lookupData) => {
        this.inputObjState.labelText = (this.setCountryDescription === 'Canada') ? 'Province' : 'State';
        this.inputObjState.data = lookupData;
        this.inputObjState = Object.assign({}, this.inputObjState);
      });
    }
  }

  getStateData(event: any): void {
    if (event && event[0]) {
      this.selStateId = (event[0].ID !== null) ? event[0].ID : null;
    } else {
      this.selStateId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('StateProvinceID').setValue(this.selStateId);
  }

  getLocationData(classDetail: ClassUnauthClassModel): void {
    // this.locationDisplay = '';
    this.locationDisplayWithName = '';
    this.locationDisplayWithName = classDetail.LocationName;
    if (classDetail.LocationAddress1) {
      // this.locationDisplay += `<br>${classDetail.LocationAddress1}`;
      this.locationDisplayWithName += `<br>${classDetail.LocationAddress1}`;
    }
    if (classDetail.LocationAddress2) {
      // this.locationDisplay += `<br>${classDetail.LocationAddress2}`;
      this.locationDisplayWithName += `<br>${classDetail.LocationAddress2}`;
    }
    if (classDetail.LocationCity) {
      // this.locationDisplay += `<br>${classDetail.LocationCity}, ${classDetail.LocationStateProvince} ${classDetail.LocationZip}`;
      this.locationDisplayWithName += `<br>${classDetail.LocationCity}, ${classDetail.LocationStateProvince} ${classDetail.LocationZip}`;
    }
  }

  initSelects() {
    this.initCountry(false);
    this.initState(false, this.tenant);
  }

  search(event: any): void {
    this.autocompleteItemData = [];
    if (this.suggestAddress && (this.setCountryId === 1 || this.setCountryId === 5)) {
      // this.mainForm.get('Address1').valueChanges
      //   .pipe(debounceTime(500))
      //   .subscribe({next:(value) => {
      const reAlpha = new RegExp('[A-Za-z]');
      const reNum = new RegExp('[0-9]');
      // this.autocompleteItemData = [];
      if (reAlpha.test(event.query) && reNum.test(event.query)) {
        this.suggestAddressJSON = {
          country: (this.setCountryId === 5) ? 'CAN' : 'USA',
          prefix: event.query,
          city_filter: this.mainForm.get('City').value,
          state_filter: null
        };
        this.addressesService.getAddressSuggestions(this.suggestAddressJSON)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.suggestions = res.suggestions;
              if (this.suggestions) {
                this.suggestions.forEach(suggestion => {
                  this.autocompleteItemData.push({
                    street_line: suggestion.street_line,
                    city: suggestion.city,
                    state: suggestion.state,
                    zipcode: suggestion.zipcode
                  });
                });
                this.autocompleteData.items = this.autocompleteItemData;
              }
            }
          });
      }
      //}});
    }
  }

  applySuggestion(event: any): void {
    if (event.value?.street_line) {
      this.mainForm.get('Address1').setValue(event.value?.street_line);
    }
    if (event.value?.city) {
      this.mainForm.get('City').setValue(event.value?.city);
    }
    if (event.value?.state) {
      let stSelected: StateLookupModel[];
      this.lookupsService.getStatesLookup(this.mainForm.get('CountryID').value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            stSelected = res.filter(x => x.Abbreviation === event.value?.state);
            if (stSelected && stSelected[0].ID) {
              this.setStateId = stSelected[0].ID;
              this.initState(false, this.tenant);
              this.mainForm.get('StateProvinceID').setValue(this.setStateId);
            }
          }
        });
    }
    if (event.value?.zipcode) {
      this.mainForm.get('Zip').setValue(event.value?.zipcode);
      if (this.setCountryId === 1) {
        this.verifyAddress.addressType = 'us-street-components';
        this.verifyAddress.street = encodeURIComponent(event.value?.street_line);
        this.verifyAddress.city = encodeURIComponent(event.value?.city);
        this.verifyAddress.state = encodeURIComponent(event.value?.state);
        this.verifyAddress.zipcode = encodeURIComponent(event.value?.zipcode);
        // this.mapsService.verifyAddress(this.verifyAddress)
        //   .pipe(takeUntil(this.ngUnsubscribe))
        //   .subscribe({next:(res) => {
        //     this.mainForm.get('county').setValue(res[0]?.metadata?.county_name);
        //     this.longitude = res[0]?.metadata?.longitude;
        //     this.latitude = res[0]?.metadata?.latitude;
        //   }});
      }
    }
    this.mainForm.markAsDirty();
    this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your address suggestion has been applied. Please review the new address in it\'s entirety for accuracy. Thanks so much!'});
    this.autocompleteData.items = [];
    this.autocompleteElement.hide();
    this.autocompleteElement.clear();
  }

  searchIntCandidates(event: any): void {
    this.intAutocompleteEntryItemData = [];
    if (this.suggestAddress && (this.setCountryId === 1 || this.setCountryId === 5)) {
      const reAlpha = new RegExp('[A-Za-z]');
      const reNum = new RegExp('[0-9]');
      if ((reAlpha.test(event.query) && reNum.test(event.query)) || (reAlpha.test(event.value?.address_text) && reNum.test(event.value?.address_text))) {
        if (event.query !== undefined) {
          this.suggestAddressJSON = {
            country: (this.setCountryId === 5) ? 'CAN' : 'USA',
            prefix: event.query,
            address_id: null
          };
        } else {
          this.suggestAddressJSON = {
            country: (this.setCountryId === 5) ? 'CAN' : 'USA',
            prefix: event.value?.address_text,
            address_id: event.value?.address_id
          };
        }
        this.addressesService.getInternationalAddressSuggestions(this.suggestAddressJSON)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.intCandidateSuggestions = [];
              if (res.candidates[0].entries !== undefined) {
                if (res.candidates) {
                  this.internationalEntries = (res.candidates[0].entries !== undefined);
                  res.candidates.forEach((x) => {
                    this.intCandidateSuggestions.push({
                      entries: x.entries,
                      address_text: x.address_text,
                      address_id: x.address_id
                    });
                  });
                }
                if (this.intCandidateSuggestions) {
                  this.intCandidateSuggestions.forEach(suggestion => {
                    this.intAutocompleteEntryItemData.push({
                      entries: suggestion.entries,
                      address_text: suggestion.address_text,
                      address_id: suggestion.address_id
                    });
                  });
                  this.intAutocompleteEntryData.items = this.intAutocompleteEntryItemData;
                }
              } else {
                this.applyIntSuggestion({
                  street: res.candidates[0]?.street,
                  locality: res.candidates[0]?.locality,
                  administrative_area: res.candidates[0]?.administrative_area,
                  postal_code: res.candidates[0]?.postal_code
                });
                this.intAutocompleteEntryData.items = [];
                this.autocompleteElement.hide();
                this.autocompleteElement.clear();
              }
            }
          });
      }
    }
  }

  applyIntSuggestion(event: any): void {
    this.internationalEntries = false;
    if (event.street) {
      this.mainForm.get('Address1').setValue(event.street);
    }
    if (event.locality) {
      this.mainForm.get('City').setValue(event.locality);
    }
    if (event.administrative_area) {
      let stSelected: StateLookupModel[];
      this.lookupsService.getStatesLookup(this.mainForm.get('CountryID').value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            stSelected = res.filter(x => x.Abbreviation === event.administrative_area);
            if (stSelected && stSelected[0].ID) {
              this.setStateId = stSelected[0].ID;
              this.initState(false, this.tenant);
              this.mainForm.get('StateProvinceID').setValue(this.setStateId);
            }
          }
        });
    }
    if (event.postal_code) {
      this.mainForm.get('Zip').setValue(event.postal_code);
      this.verifyAddress.addressType = 'us-street-components';
      this.verifyAddress.street = encodeURIComponent(event.street);
      this.verifyAddress.city = encodeURIComponent(event.locality);
      this.verifyAddress.state = encodeURIComponent(event.administrative_area);
      this.verifyAddress.zipcode = encodeURIComponent(event.postal_code);
      // this.mapsService.verifyAddress(this.verifyAddress)
      //   .pipe(takeUntil(this.ngUnsubscribe))
      //   .subscribe({next:(res) => {
      //     this.mainForm.get('county').setValue(res[0]?.metadata?.county_name);
      //     this.longitude = res[0]?.metadata?.longitude;
      //     this.latitude = res[0]?.metadata?.latitude;
      //   }});
    }
    this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your address suggestion has been applied. Please review the new address in it\'s entirety for accuracy. Thanks so much!'});
  }
}
