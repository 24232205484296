import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {LookupModel} from '@app/models/lookups.model';
import {GenericSelectModel} from '@app/models/generic-select.model';

@Component({
  selector: 'app-multi-select-languages',
  templateUrl: './multi-select-languages.component.html'
})
export class MultiSelectLanguagesComponent implements OnInit, OnChanges, AfterViewInit {
  showFilter: boolean = false;
  selectForm: FormGroup;
  selectData: LookupModel[];
  selectedData: LookupModel[] = [];
  virtScroll: boolean;
  selectAll: boolean;
  primaryChanged: boolean = false;
  @Input() inputObj: GenericSelectModel;
  @Input() newValue: any;
  @Input() preferredId: any;
  @Output() emitData = new EventEmitter<any>();
  @Output() emitBlur = new EventEmitter<any>();
  @Output() emitPreferred = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef) {
    this.selectForm = this.formBuilder.group({
      fcnSelect: [null]
    });
  }

  ngOnInit(): void {
    if (this.inputObj) {
      if (typeof this.inputObj.customSelect == 'undefined') {
        this.inputObj.customSelect = null;
      }
    }
  }

  // reload if params change
  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputObj) {
      if (typeof this.inputObj.customSelect == 'undefined') {
        this.inputObj.customSelect = null;


      }
      this.loadSelect();
      if (this.inputObj.canWrite) {
        if (this.inputObj.disabled === true) {
          this.selectForm.get('fcnSelect').disable();
        } else {
          this.selectForm.get('fcnSelect').enable();
        }
      } else {
        this.selectForm.get('fcnSelect').disable();
      }
    } else {
      this.selectForm.get('fcnSelect').disable();
    }
    if (changes.newValue) {
      if (this.inputObj && this.newValue && this.inputObj.initSelected !== this.newValue) {
        this.selectForm.get('fcnSelect').setValue(this.newValue);
        this.emitDataToParent(true);
      }
    }
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  // required to listen to form controls
  get registerFormControl(): any {
    return this.selectForm.controls;
  }

  setPreferred(id, index) {

    this.preferredId = this.preferredId !== id ? id : null;
    setTimeout(() => {
      this.inputObj.initSelected.unshift(id);
      this.emitData.emit(this.inputObj.initSelected);
      this.primaryChanged = true;
      this.changeDetector.detectChanges();
      this.emitPreferred.emit(this.preferredId);
    }, 300);
  }

  loadSelect(): void {
    if (this.inputObj.data) {
      this.selectData = this.inputObj.data;
      if (this.inputObj.data.length > 100) {
        this.virtScroll = true;
      }
    }
    if (this.inputObj.requiredField) {
      this.selectForm.get('fcnSelect').setValidators([Validators.required]);
    }
    if (this.selectData) {
      this.showFilter = Object.keys(this.selectData).length > 10;
      if (this.inputObj.emitChangeOnLoad === undefined) {
        this.emitDataToParent(false);
      } else {
        this.emitDataToParent(this.inputObj.emitChangeOnLoad);
      }
    }
    if (this.inputObj.canWrite === undefined) {
      this.inputObj.canWrite = true;
    }
    if (this.inputObj.initSelected?.length > 0 && this.preferredId) {
      const preferredIndex = this.inputObj.initSelected.findIndex(x => x === this.preferredId);
      const tempId = this.inputObj.initSelected[preferredIndex];
      this.inputObj.initSelected.splice(preferredIndex, 1);
      this.inputObj.initSelected.unshift(tempId);
    }
  }


  emitDataToParent(emit: boolean): void {
    if (emit && this.inputObj.initSelected) {
      if (this.inputObj.initSelected) {
        this.emitData.emit(this.inputObj.initSelected);
      } else {
        this.emitData.emit([]);
      }
    }
  }

  sendOnBlur(event: any) {
    this.emitBlur.emit();
  }
}
