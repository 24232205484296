import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Event, NavigationEnd, Router} from '@angular/router';
import {InterfaceObjectsService} from '@services/interface-objects.service';
import {takeUntil} from 'rxjs/operators';
import {ContextService} from 'src/app/services/context.service';
import {TenantConfigurations} from '@shared/tenant-configurations/configurations';
import {ConfirmationService} from 'primeng/api';
import {hideContactSupport} from '@shared/utilities/component-manager-utility';
import {ContactSupportDialogComponent} from '@shared/components/contact-support-dialog/contact-support-dialog.component';
import {DialogService} from 'primeng/dynamicdialog';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
  standalone: true,
  imports: [NgClass]
})
export class HeaderNavComponent implements OnInit, OnDestroy {

  activeItems: any[];
  itemMenuParent: any[] = [];
  hideMenu: boolean = false;
  // itemsUserMega: any[];
  // itemsSuperUserMega: any[];
  // itemsSuperMembershipMega: any[];
  // itemsSuperTrainingMega: any[];
  //
  // itemsUserGroup: any[];
  // itemsSuperUserGroup: any[];
  // itemsSuperMembershipGroup: any[];
  // itemsSuperTrainingGroup: any[];
  optionalStyling: boolean = true;
  centeredStyling: boolean = false;
  headerMenu: string = '';
  styleType: string = 'mega';
  headerColor: string;
  items2: any[] = [];
  @Input() selectedMenuView: string;
  tenantConfigurations = TenantConfigurations;
  worksitesLabel = 'Worksites';
  public _hideContactSupport = hideContactSupport;
  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private interfaceObjectService: InterfaceObjectsService, private dialogService: DialogService,
              private contextService: ContextService, private confirmationService: ConfirmationService) {

    // if(contextService.contextObject.tenantName === 'IUPAT') {
    //   this.headerColor = '#000000';
    // } else {
    //   this.headerColor = '#AD172B';
    // }
  }

  goToUrl(link: string): void {
    sessionStorage.removeItem('sessionViewType');
    this.confirmationService.close();
    this.hideMenu = true;
    if (link) {
      if (!link.includes('http')) {
        this.router.navigateByUrl(link);
      } else {
        window.open(link, '_blank');
      }
    }
    // this.getURL(link.split('/')[0]);
  }

  openContanct() {
    const ref = this.dialogService.open(ContactSupportDialogComponent, {
      header: 'Contact Support',
      width: '60%',
      height: '400'
    });
  }

  getURL(link): void {
    let cleanLink: string;
    if (link.includes('-landing')) {
      cleanLink = '/' + link.split('/')[1];
    } else {
      cleanLink = link;
    }
    if (this.itemMenuParent.find(x => '/' + x.Route === cleanLink) !== undefined) {
      this.headerMenu = this.itemMenuParent.find(x => '/' + x.Route === cleanLink)?.Parent.toString().toLowerCase();
    } else {
      this.headerMenu = '';
    }
    // switch(link) {
    //   case 'membership':
    //     this.headerMenu = link;
    //     break;
    //   case 'training':
    //     this.headerMenu = link;
    //     break;
    //   case 'organize':
    //     this.headerMenu = link;
    //     break;
    //   case 'users':
    //   case 'unlinked-users':
    //   case 'user-help':
    //   case 'privacy-policy':
    //   case 'about':
    //   case 'admin':
    //     this.headerMenu = 'support';
    //     break;
    //   case 'alerts':
    //   case 'dashboard':
    //   case 'page-not-found':
    //     this.headerMenu = '';
    //     break;
    //   case 'report-builder' :
    //     this.headerMenu = 'reporting';
    //     break;
    //   default: this.headerMenu = 'my account';
    // }
  }

  getAppList(): void {
    if (sessionStorage.getItem('menuData') === null || sessionStorage.getItem('menuData') === undefined) {
      this.interfaceObjectService.getMenuItems().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: (res) => {
          this.formatMenu(res);
        }
      });
    } else {
      // console.log(JSON.parse(sessionStorage.getItem('menuData')));
      this.formatMenu(JSON.parse(sessionStorage.getItem('menuData')));
    }

  }

  formatMenu(res) {
    let worksitesLabel: string = 'Worksites';
    if (this.contextService.contextObject.tenantName.toLowerCase() === 'iupat') {
      worksitesLabel = this.tenantConfigurations.IUPATWORKSITESLABEL;
    }
    const statisActiveItems: any[] = JSON.parse(JSON.stringify(res).replaceAll('Employer Locations', worksitesLabel));
    statisActiveItems.forEach(x => {
      let i: number = 0;
      x.Items?.forEach(y => {
        if (y.Label === 'Options') {
          x.Items?.splice(i, 1);
        }
        i += 1;
      });
      i = 0;
      x.Items?.forEach(y => {
        if (y.Label === 'Tenant Admin Options') {
          x.Items?.splice(i, 1);
        }
        i += 1;
      });
      i = 0;
      x.Items?.forEach(y => {
        if (y.Label === 'Printables' || y.Label === 'Predefined Reports') {
          x.Items?.splice(i, 1);
        }
        i += 1;
      });
      x.Items?.forEach(a => {
        a.Items?.forEach(b => {
          this.itemMenuParent.push({
            Parent: x.Label,
            Route: b.Route
          });
        });
      });
    });
    sessionStorage.setItem('menuData', JSON.stringify(res).replaceAll('Employer Locations', worksitesLabel));
    this.activeItems = statisActiveItems;
  }

  ngOnInit(): void {
    if (this.contextService.contextObject.tenantName.toLowerCase() === 'iupat') {
      this.worksitesLabel = this.tenantConfigurations.IUPATWORKSITESLABEL;
    }
    this.router.events.subscribe({
      next: (event: Event) => {
        sessionStorage.removeItem('savedReport'); //clear a report builder that might be abandoned
        if (event instanceof NavigationEnd) {
          switch (event.url.split('/')[1]) {
            case 'reports':
            case 'select-options':
            case 'tenant-admin':
              // this.getURL(event.url.split('/')[2]);
              this.getURL(event.url.split('/')[1]);
              break;
            default:
              //this.getURL(event.url.split('/')[1]);
              this.getURL(event.url);
              break;
          }
        }
      }
    });
    //this.setSuperUserMegaItems();
    this.getAppList();
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

}
