import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {SharedImports} from '@shared/shared-imports';
import {take, takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ContextModel} from '@app/models/context.model';
import {EnvironmentsService} from '@services/environments.service';
import {ContextService} from '@services/context.service';
import {EventInstancesService} from '@services/event-instances.service';
import {UnauthEventInstanceInstanceModel, UnauthEventInstanceLocationModel, UnauthEventInstanceModel} from '@app/models/event-instances.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserRegistrationService} from '@services/user-registration.service';
import {UserRegistrationEventModel} from '@app/models/user-registration.model';
import {GenericSelectModel} from '@app/models/generic-select.model';
import {LookupsDataService} from '@services/lookups-data.service';
import {OrganizationService} from '@services/organizations.service';
import {HttpClient} from '@angular/common/http';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import {Title} from '@angular/platform-browser';

@Component({
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  standalone: true,
  imports: [SharedImports]
})
export class PublicEventsComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  eventInstance: UnauthEventInstanceModel[];
  loading: boolean;
  isDirty: boolean;
  tenant: string;
  logo: string;
  pid: string;
  spid: number;
  tid: string;
  isEdit: boolean = false;
  isRegistering: boolean = false;
  selectedEvent: UnauthEventInstanceModel;
  selectedEventInstance: UnauthEventInstanceInstanceModel;
  noEvents: boolean = false;
  inputObjLocation: GenericSelectModel;
  selLocationId: number = null;
  locationData: UnauthEventInstanceLocationModel[] = [];
  locationDisplay: string;
  locationDisplayWithName: string;
  rootId: number;
  locationDataLoaded: boolean = false;
  // bgColor: string = '#dddddd';
  latitude: number;
  longitude: number;
  zoom: number = 15;
  title: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  markerPositions: google.maps.LatLngLiteral[] = [];
  center: google.maps.LatLngLiteral;
  apiLoaded: boolean = false;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private environmentsService: EnvironmentsService, private activatedRoute: ActivatedRoute,
              private contextService: ContextService, private organizationService: OrganizationService,
              private eventInstancesService: EventInstancesService, private lookupsDataService: LookupsDataService,
              private formBuilder: FormBuilder, private userRegistrationService: UserRegistrationService,
              httpClient: HttpClient, private titleService: Title
  ) {
    this.mainForm = this.formBuilder.group({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      phone: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      postalCode: new FormControl(null, [Validators.required]),
      location: new FormControl(null, [Validators.required])
    });
    httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBW4XnnP8Zfb0yoQGHSAImpox41I51DVd4', 'callback')
      .subscribe({
        next: () => {
          this.apiLoaded = true;
        }
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(2)).subscribe({
      next: (params) => {
        this.tenant = params.tenant;
        this.getBaseUrl().then(() => {
          this.route.params.pipe().subscribe({
            next: (res) => {
              if (this.router.url.includes('/public/events/')) {
                this.getEvents(parseInt(res.id, 10));
              } else {
                this.getEvents();
              }
            }
          });
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getBaseUrl() {
    return new Promise((resolve, reject) => {
      this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
        if (rtnTenant) {
          this.setUrl(rtnTenant);
        }
      });
      // switch (true) {
      //   case environment.environmentName === 'dev':
      //   case environment.environmentName === 'staging':
      //   case environment.environmentName === 'demo':
      //     this.environmentsService.getEnvironment(environment.environmentName).then((rtnTenant) => {
      //       if (rtnTenant) {
      //         this.setUrl(rtnTenant);
      //       }
      //     });
      //     break;
      //   case environment.local
      //   && environment.environmentName !== 'dev'
      //   && environment.environmentName !== 'demo'
      //   && environment.environmentName !== 'staging':
      //     if (this.tenant) {
      //       this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //         if (rtnTenant) {
      //           this.setUrl(rtnTenant);
      //         }
      //       });
      //     }
      //     break;
      //   case !environment.local:
      //     this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //       if (rtnTenant) {
      //         this.setUrl(rtnTenant);
      //       }
      //     });
      //     break;
      // }


      // if(environment.environmentName === 'demo') {
      //   this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.environmentName === 'dev') {
      //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.local && environment.environmentName !== 'dev' && environment.environmentName !== 'demo') {
      //   this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //     if(rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(!environment.local) {
      //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // }
      resolve(null);
    });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.logo = this.contextService.contextObject.tenantLogo;
    if (!this.tenant) {
      this.tenant = this.contextService.contextObject.tenantName;
    }
  }

  getEvents(id?: number): void {
    this.loading = true;
    this.route.queryParams.subscribe({
      next: () => {
        if (id) {
          this.eventInstancesService
            .getEventInstancesOrgUnauth(this.tenant, id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.eventInstance = res;
                this.noEvents = res.length <= 0;
                this.loading = false;
              }
            });
        } else {
          this.eventInstancesService
            .getEventInstancesUnauth(this.tenant)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.eventInstance = res;
                this.noEvents = res.length <= 0;
                this.loading = false;
              }
            });
        }
        this.organizationService
          .getUnauthRootOrganizationId(this.tenant, 'membership')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.rootId = res;
            }
          });
      }
    });
  }

  eventSelected(event: UnauthEventInstanceModel, eventInstance: UnauthEventInstanceInstanceModel): void {
    this.isEdit = true;
    this.selectedEvent = event;
    this.selectedEventInstance = eventInstance;
    this.eventInstancesService
      .getEventInstanceLocationsUnauth(this.tenant, this.selectedEventInstance.EventInstanceID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: UnauthEventInstanceLocationModel[]) => {
          res.forEach(x => {
            x.ID = x.OrganizationID;
            if (x.MaxSeats_N > x.FilledSeats_N) {
              this.locationData.push(x);
            }
          });
          this.locationDataLoaded = true;
          this.initLocation();
        }
      });
  }

  register(): void {
    this.isRegistering = true;
    const registration: UserRegistrationEventModel = {
      EventInstanceID: this.selectedEventInstance.EventInstanceID,
      TenantName: this.tenant,
      FirstName: this.mainForm.get('firstName').value,
      LastName: this.mainForm.get('lastName').value,
      PrimaryEmailAddress: this.mainForm.get('email').value,
      SecondaryPhonePhoneTypeEnum: 'MOBILE',
      SecondaryPhoneNumber: this.mainForm.get('phone').value,
      PrimaryZip: this.mainForm.get('postalCode').value,
      MembershipOrganizationID: this.rootId,
      LocationOrganizationID: this.mainForm.get('location').value
    };
    this.userRegistrationService.eventRegistration(registration)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Registration Complete', detail: 'Thank you for registering. We\'ll see you soon!', life: 3500});
          this.isRegistering = false;
          setTimeout(() => {
            location.reload();
          }, 3500);
        }, error: () => {
          this.messageService.add({severity: 'error', summary: 'Registration Incomplete', detail: 'Your registration was not received. Please try again.', life: 3500});
          this.isRegistering = false;
        }
      });
  }

  initLocation(): void {
    this.inputObjLocation = {
      labelText: 'Location(s)',
      optionValue: 'ID',
      optionLabel: 'LocationName',
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: null,
      data: this.locationData,
      disabled: false,
      emitChangeOnLoad: true
    };
  }

  getLocationData(event: any): void {
    this.apiLoaded = false;
    this.locationDisplay = '';
    this.locationDisplayWithName = '';
    if (event && event[0]) {
      this.selLocationId = (event[0].ID !== null) ? event[0].ID : null;
      this.locationDisplayWithName = event[0].LocationName;
      if (event[0].LocationAddress1) {
        this.locationDisplay += `<br>${event[0].LocationAddress1}`;
        this.locationDisplayWithName += `<br>${event[0].LocationAddress1}`;
      }
      if (event[0].LocationAddress2) {
        this.locationDisplay += `<br>${event[0].LocationAddress2}`;
        this.locationDisplayWithName += `<br>${event[0].LocationAddress2}`;
      }
      if (event[0].LocationCity) {
        this.locationDisplay += `<br>${event[0].LocationCity}, ${event[0].LocationStateProvince} ${event[0].LocationZip}`;
        this.locationDisplayWithName += `<br>${event[0].LocationCity}, ${event[0].LocationStateProvince} ${event[0].LocationZip}`;
      }
      // if (false) {
      //   this.markerPositions.push({
      //     lat: null,
      //     lng: null
      //   });
      //   this.center = {
      //     lat: null,
      //     lng: null
      //   };
      //   this.title = this.locationDisplayWithName;
      //   this.address = event[0].LocationAddress1;
      //   this.city = event[0].LocationCity;
      //   this.state = event[0].LocationStateProvince;
      //   this.zip = event[0].LocationZip;
      //   this.apiLoaded = true;
      // }
    } else {
      this.selLocationId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('location').setValue(this.selLocationId);
  }

  openInfoWindow(marker: MapMarker) {
    if (this.infoWindow !== undefined) this.infoWindow.open(marker);
  }
}
