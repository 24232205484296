import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {RosterDymoLabelModel, RosterProfileApplicantModel, RosterProfileDuplicateCheckModel, RosterProfileDuplicateCheckReturnModel, RosterProfileModel, RosterProfilePhotoDataUrlModel, RosterProfilePiiModel, RosterProfilePreCheckModel, RosterProfilePreCheckResultsModel} from '@app/models/roster-profile.model';
import {PointsGridModel, TotalPointsModel} from '@app/models/points.model';
import {UserProfileCommunicationsGridModel} from '@app/models/user-profile.model';
import {UpdateUserNameModel} from '@app/models/users.model';
import {SMSOptStatusModel} from '@app/models/sms.model';

@Injectable({
  providedIn: 'root',
})
export class RosterProfileService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  tagName: string;

  getRosterProfile(personId: number): Observable<RosterProfileModel> {
    this.tagName = 'getRosterProfile';
    return this.http.get<RosterProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}`);
  }

  updateRosterProfile(personId: number, modelData: RosterProfileModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}?ignoreaddressupdates=true`, modelData);
  }

  createRosterProfile(modelData: RosterProfileModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/create`, modelData);
  }

  getRosterProfilePerson(personId: number): Observable<RosterProfileModel> {
    this.tagName = 'getRosterProfile';
    return this.http.get<RosterProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/person`);
  }

  updateRosterProfilePerson(personId: number, modelData: RosterProfileModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/person?ignoreaddressupdates=true`, modelData);
  }

  preCheckRosterProfile(modelData: RosterProfilePreCheckModel): Observable<RosterProfilePreCheckResultsModel> {
    return this.http.post<RosterProfilePreCheckResultsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/create/precheck`, modelData);
  }

  deleteRosterProfile(personId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/remove/${personId}`);
  }

  duplicateCheckRosterProfile(modelData: RosterProfileDuplicateCheckModel): Observable<RosterProfileDuplicateCheckReturnModel> {
    return this.http.post<RosterProfileDuplicateCheckReturnModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/duplicatecheck`, modelData);
  }

  getRosterProfileCommunicationsPrimeNG(dataMode: string, personId: number, filterData): Observable<UserProfileCommunicationsGridModel> {
    const url: string = this.contextService.contextObject.selContext ? `?context=${this.contextService.contextObject.selContext}` : '';
    return this.http.post<UserProfileCommunicationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/communicationgrid${url}&mode=${dataMode}`, filterData);
  }

  getRosterProfilePointsPrimeNg(dataMode: string, personId: number, filterData): Observable<PointsGridModel> {
    return this.http.post<PointsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/pointsgrid?mode=${dataMode}`, filterData);
  }

  getRosterProfilePointsTotal(personId: number): Observable<TotalPointsModel> {
    return this.http.get<TotalPointsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/pointstotal`);
  }

  isRosterProfileEmailTaken(personId: number, email: string): Observable<RosterProfilePiiModel> {
    return this.http.get<RosterProfilePiiModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/isemailtaken?email=${email}`);
  }

  updateRosterProfileUserName(modelData: UpdateUserNameModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/updateusername`, modelData);
  }

  //TODO - handle form data file
  updateRosterProfileFile(personId: number, type: string, formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/image/${type}`, formData);
  }

  getRosterProfileFile(personId: number, type): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/image/${type}`);
  }

  updateRosterProfilePhotoUrl(personId: number, modelData: RosterProfilePhotoDataUrlModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/photo/fromdataurl`, modelData);
  }

  getRosterProfilePii(personId: number): Observable<RosterProfilePiiModel> {
    return this.http.get<RosterProfilePiiModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/pii`);
  }

  updateRosterProfilePii(personId: number, modelData: RosterProfilePiiModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/pii`, modelData);
  }

  rosterProfileHasDOB(personId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/hasdob`);
  }

  getOptInRosterPreferences(personId: number): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/optinpreferences`);
  }

  getDymoLabelDetails(personId: number): Observable<RosterDymoLabelModel> {
    return this.http.get<RosterDymoLabelModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/address/dymolabel`);
  }

  getRosterSMSOptOutStatus(mobileNumber: string): Observable<SMSOptStatusModel[]> {
    return this.http.get<SMSOptStatusModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/sms/optoutstatus/${mobileNumber}`);
  }

  getRosterApplicantData(personId: number): Observable<RosterProfileApplicantModel> {
    return this.http.get<RosterProfileApplicantModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/temporaryidentifier/person/${personId}/EAPP`);
  }
}
