import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FreshdeskTicketCreateModel} from '@app/models/freshdesk-ticket.model';
import {Platform} from '@angular/cdk/platform';
import {Router} from '@angular/router';
import {ContextService} from '@services/context.service';
import {FreshdeskService} from '@services/freshdesk.service';
import {MessageService} from 'primeng/api';
import {ResourcesService} from '@services/resources.service';
import {SharedImports} from '@shared/shared-imports';

@Component({
  templateUrl: './contact-support-dialog.component.html',
  styleUrls: ['./contact-support-dialog.component.scss'],
  standalone: true,
  imports: [SharedImports]
})

export class ContactSupportDialogComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  isSaving: boolean;
  browser: string;
  version: string;
  showProgress: boolean;
  uploadedFiles: any[] = [];
  fd: FormData = new FormData();
  freshDeskData = {} as FreshdeskTicketCreateModel;
  freshDeskDataString: string;
  customFieldString: string;
  attachmentsString: string = 'attachments[]';
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private formBuilder: FormBuilder,
              private platform: Platform, private router: Router, private contextService: ContextService, private changeDetectorRef: ChangeDetectorRef,
              private freshdeskService: FreshdeskService, private messageService: MessageService, private resourcesService: ResourcesService) {
    this.mainForm = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
      requester_id: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      phone: new FormControl(null),
      subject: new FormControl(null, Validators.required),
      type: new FormControl(null),
      status: new FormControl(null),
      priority: new FormControl(null),
      description: new FormControl(null, Validators.required),
      files: new FormControl(null),
      title: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.browser = this.getBrowserName();
    this.version = this.getBrowserVersion();
    this.freshDeskData.attachments = [];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getBrowserName() {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('Chrome') > -1) {
      return 'Chrome';
    } else if (userAgent.indexOf('Firefox') > -1) {
      return 'Firefox';
    } else if (userAgent.indexOf('Safari') > -1) {
      return 'Safari';
    } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
      return 'Internet Explorer';
    } else if (userAgent.indexOf('Edge') > -1) {
      return 'Microsoft Edge';
    } else {
      return 'Unknown Browser';
    }
  }

  getBrowserVersion() {
    const userAgent = navigator.userAgent;
    const regex = /(?:(?:MSIE|Trident\/[0-9.]+).*rv:([0-9.]+))|(?:Chrome\/([0-9.]+))|(?:Firefox\/([0-9.]+))|(?:Safari\/([0-9.]+))/;
    const match = userAgent.match(regex);

    if (match) {
      return match[1] || match[2] || match[3] || match[4];
    } else {
      return 'Unknown Version';
    }
  }

  cancel() {
    this.closeDialog();
  }

  onUpload(event: any): any {
    console.log(event);
    this.uploadedFiles = event.currentFiles;
    console.log(this.uploadedFiles);
  }

  deleteImage(index) {
    this.freshDeskData.attachments.splice(index, 1);
  }

  clearUpload(): void {
    this.uploadedFiles.length = 0;
    this.fd = new FormData();
    this.mainForm.markAsDirty();
    this.mainForm.get('files').setValue(null);
    this.mainForm.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }

  processData() {
    this.fd.append('custom_fields[cf_operating_system]', navigator.userAgent.toLowerCase());
    this.fd.append('custom_fields[cf_browser]', this.getBrowserName());
    this.fd.append('custom_fields[cf_browser_version]', this.getBrowserVersion());
    this.fd.append('custom_fields[cf_system_url]', window.location.href);
    this.fd.append('custom_fields[cf_tenant_name]', this.contextService.contextObject.tenantName);
    this.fd.append('custom_fields[cf_user_role]', 'N/A');
    this.fd.append('custom_fields[cf_package_level]', 'N/A');

    this.fd.append('name', this.mainForm.get('name').value);
    this.fd.append('email', this.mainForm.get('email').value);
    this.fd.append('phone', this.mainForm.get('phone').value);
    this.fd.append('subject', this.mainForm.get('subject').value);
    this.fd.append('description', this.mainForm.get('description').value);
    this.fd.append('status', '2');
    this.fd.append('priority', '1');
    this.fd.append('source', '2');
    this.fd.append('group_id', '35000247522');
    this.fd.append('product_id', '35000003898');
    this.fd.append('email_config_id', '35000073669');
    if (this.uploadedFiles?.length > 0) {
      this.uploadedFiles.forEach(file => {
        this.fd.append('attachments[]', file, file.name);
      });
    }


    console.log(this.fd);
    this.saveData();
  }

  saveData() {
    this.freshdeskService.createTicket(this.fd).subscribe({
      next: () => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your changes have been saved.'});
        this.mainForm.markAsPristine();
        this.isSaving = false;
        this.closeDialog();
      }, error: (e) => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
        this.isSaving = false;
        console.debug(e);
      }
    });
  }

  closeDialog(res?) {
    this.ref.close(res);
    this.ref.destroy();
  }
}
