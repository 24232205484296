import {Component, OnInit} from '@angular/core';
import {MemberVerificationHashedIdModel, MemberVerificationModel} from '@app/models/member-verification.model';
import {MemberVerificationService} from '@services/member-verification.service';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SharedImports} from '@shared/shared-imports';
import {ContextModel} from '@app/models/context.model';
import {EnvironmentsService} from '@services/environments.service';
import {Title} from '@angular/platform-browser';
import {ContextService} from '@services/context.service';

@Component({
  templateUrl: './member-verification.component.html',
  styleUrls: ['./member-verification.component.scss'],
  standalone: true,
  imports: [SharedImports]
})
export class MemberVerificationComponent implements OnInit {
  memberData = {} as MemberVerificationModel;
  memberHashId: string;
  memberVerification = {} as MemberVerificationHashedIdModel;
  tenant: string;
  private ngUnsubscribe = new Subject();

  constructor(private memberVerificationService: MemberVerificationService, private route: ActivatedRoute,
              private environmentsService: EnvironmentsService, private titleService: Title,
              private contextService: ContextService) {
  }

  ngOnInit(): void {
    this.getBaseUrl();
    this.route.queryParams.subscribe({
      next: (params) => {
        if (params && params.hashedId) {
          this.memberHashId = params.hashedId;
          // console.log(this.memberHashId);
          this.memberVerification.hashedId = this.memberHashId;
        }
      }
    });
  }

  getMemberData() {
    this.memberVerificationService.getMemberVerification(this.memberVerification, 'iupat')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.memberData = res;
        }
      });
  }

  getBaseUrl() {
    return new Promise((resolve, reject) => {
      this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
        if (rtnTenant) {
          this.setUrl(rtnTenant);
        }
      });
    });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    if (!this.tenant) {
      this.tenant = this.contextService.contextObject.tenantName;
    }
    this.getMemberData();
  }

  setDefaultPic() {
    this.memberData.ThumbnailUrl = 'assets/images/profile-generic.png';
  }

  getCert(url) {
    window.open(url, '_blank');
  }
}
