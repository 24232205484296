import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe, NgOptimizedImage, PercentPipe} from '@angular/common';
import {CardModule} from 'primeng/card';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputNumberModule} from 'primeng/inputnumber';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AccordionModule} from 'primeng/accordion';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DividerModule} from 'primeng/divider';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import {RadioButtonModule} from 'primeng/radiobutton';
import {MenubarModule} from 'primeng/menubar';
import {ListboxModule} from 'primeng/listbox';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {BadgeModule} from 'primeng/badge';
import {PanelModule} from 'primeng/panel';
import {ToolbarModule} from 'primeng/toolbar';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputMaskModule} from 'primeng/inputmask';
import {SliderModule} from 'primeng/slider';
import {PaginatorModule} from 'primeng/paginator';
import {CalendarModule} from 'primeng/calendar';
import {ToastModule} from 'primeng/toast';
import {TabViewModule} from 'primeng/tabview';
import {EditorModule} from '@tinymce/tinymce-angular';
// Services
import {DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FileUploadModule} from 'primeng/fileupload';
import {PickListModule} from 'primeng/picklist';
import {WebcamModule} from 'ngx-webcam';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {HighchartsChartModule} from 'highcharts-angular';
import {InputSwitchModule} from 'primeng/inputswitch';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {DistListLookupService} from '@services/dist-list-lookups.service';
import {FilterListLookupService} from '@services/filter-list-lookups.service';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {GoogleMapsModule} from '@angular/google-maps';
import {TimelineModule} from 'primeng/timeline';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {NgxPhotoEditorModule} from 'ngx-photo-editor';
// import { OrganizationChartModule } from 'primeng/organizationchart';
import {TreeModule} from 'primeng/tree';
import {FocusTrapModule} from 'primeng/focustrap';
import {SelectButtonModule} from 'primeng/selectbutton';


// Components
import {SelectComponent} from '@shared/components/select/select.component';
// Definitions
import {RosterDefinitions} from './definitions/roster.definition';
import {RosterStudentDefinitions} from './definitions/roster-student.definition';
import {ProspectDefinitions} from './definitions/prospects.definition';
import {DistributionListDefinitions} from './definitions/distribution-list.definitions';
import {DateRangePickerDefinitions} from './definitions/date-range-picker.definitions';
import {ExemptUsersDefinitions} from './definitions/exempt-users.definitions';
import {TenantFilterDefinitions} from './definitions/tenant-filter.definitions';

// Pipes
import {PhonePipe} from './pipes/phone.pipe';
import {ToFixedPipe} from './pipes/tofixed.pipe';
import {ToFixedCurrencyPipe} from './pipes/tofixed-currency.pipe';
import {CamelcaseSpacePipe} from './pipes/camelcase-space.pipe';
import {StringReplacePipe} from './pipes/stringreplace.pipe';
import {LocalizeDateTimePipe, ShortLocalizeDateTimePipe} from './pipes/localize-datetime.pipe';
import {StatePipe} from './pipes/state.pipe';
import {MatchTypeReplace} from './pipes/filter.pipes';
import {FirstLetter} from './pipes/first-letter.pipe';
import {YesNoPipe} from './pipes/boolean.pipe';
import {SafeHtmlPipe} from './pipes/html-sanitizer.pipte';

// Utilities
import {DialogUtility} from './utilities/dialog.utility';
import {PhoneValidationUtility} from './utilities/phone-validation.utility';
import {RouteContextUtility} from './utilities/route-context.utility';
import {ConfirmValidationUtility} from './utilities/confirm-validation.utility';
import {DymoLabelUtility} from './utilities/dymo-label.utility';
import {PageRowCountUtility} from './utilities/page-row-count.utility';
import {PDFExportUtility} from './utilities/pdf-export.utility';
import {DialogModule} from 'primeng/dialog';
import {PageService} from '@services/page.services';
import {ConvertAuxFiltersUtility} from './utilities/convert-aux-filters.utility';
// import {ClassTabDetailsComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-details/class-tab-details.component';
// import {ClassTabStudentsComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-students/class-tab-students.component';
// import {ClassStudentFormComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-students/add-edit-class-students/class-student-form.component';
// import {ClassGridComponent} from '../classes-modules/class-modules-grid/classes-grid.component';
// import {ClassConfirmMultipleDialogComponent} from '../classes-modules/classes-add-multiple/class-confirm-multiple-dialog/class-confirm-multiple-dialog.component';
// import {ClassTabVirtualComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-virtual/class-tab-virtual.component';
// import {ClassTabAttendanceComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-attendance/class-tab-attendance.component';
// import {ClassAttendanceFormComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-attendance/add-edit-class-attendance/class-attendance-form.component';
// import {ClassTabGradesComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-grades/class-tab-grades.component';
// import {ClassTabStaffComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-staff/class-tab-staff.component';
// import {ClassStaffFormComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-staff/add-edit-class-staff/class-staff-form.component';
// import {ClassesAddMultipleComponent} from '../classes-modules/classes-add-multiple/classes-add-multiple.component';
// import {StudentsCompleteDialogComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-students/students-complete-dialog/students-complete-dialog.component';
// import {AddCertificateDialogComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-grades/add-certificate-dialog/add-certificate-dialog.component';
import {ReportListDefinitions} from './definitions/reports-list.definitions';
import {CurrencyInputComponent} from './components/currency-input/currency-input.component';
import {BooleanCheckboxComponent} from './components/boolean-checkbox/boolean-checkbox.component';
import {MultiSelectFilterListComponent} from './components/multi-select-filter-list/multi-select-filter-list.component';
import {LastInLoopComponent} from './components/list-filter/last-in-loop.component';
import {InactiveStatusUtility} from './utilities/inactive-status.utility';
import {NgxCurrencyDirective} from 'ngx-currency';
import {SnapshotDefinitions} from './definitions/snapshot.definitions';
import {MultiSelectLanguagesComponent} from './components/multi-select-languages/multi-select-languages.component';
import {MultiSelectComponent} from '@shared/components/multi-select/multi-select.component';
import {DataIndicatorComponent} from '@shared/components/data-indicator/data-indicator.component';
import {DynamicCustomFieldsComponent} from '@shared/components/dynamic-custom-fields/dynamic-custom-fields.component';
import {LearnMoreComponent} from '@shared/components/learn-more/learn-more.component';
import {ListFilterComponent} from '@shared/components/list-filter/list-filter.component';
import {MultiSelectUniversalComponent} from '@shared/components/multi-select-universal/multi-select-universal.component';
import {PiiDuplicateDialogComponent} from '@shared/components/pii-duplicate-dialog/pii-duplicate-dialog.component';
import {RecordNotFoundComponent} from '@shared/components/record-not-found/record-not-found.component';
import {DistListSelectComponent} from '@shared/components/select/dist-list-select/dist-list-select.component';
import {FilterMyDataComponent} from '@shared/components/select/filter-my-data-select/filter-my-data.component';
import {YesNoSelectComponent} from '@shared/components/select/yes-no-select/yes-no-select.component';
import {UniversalDatePickerComponent} from '@shared/components/universal-date-picker/universal-date-picker.component';
import {TextEditorComponent} from '@shared/components/text-editor/text-editor.component';
// import {UserAccountService} from '@services/user-account.service';
import {InterfaceObjectHelpService} from '@services/interface-object-help.service';
import {TenantConfigService} from '@services/tenant-config.service';
import {InterfaceObjectsService} from '@services/interface-objects.service';
import {LookupsService} from '@services/lookups.service';
import {InterfaceConfigService} from '@services/interface-config.service';
import {TenantConfigDataService} from '@services/tenant-config-data.service';
import {MessagesModule} from 'primeng/messages';
import {RouterModule} from '@angular/router';
import {TooltipModule} from 'primeng/tooltip';
import {SidenavService} from '@app/core/services/sidenave-service';
import {OrganizationService} from '@services/organizations.service';
import {AlertsService} from '@app/core/services/alerts.service';
import {AuthService} from '@app/core/services/auth.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {HttpCacheService} from '@app/core/services/http-cache.service';
import {BlobStorageService} from '@app/core/services/blob-storage.service';
import {ApiInterceptor} from '@app/core/interceptors/api.interceptor';
import {StepsModule} from 'primeng/steps';
// import {TelerikeReportViewerComponent} from '@shared/components/telerik-report-viewer/telerik-report-viewer.component';
import {TelerikReportViewerComponent} from '@progress/telerik-angular-report-viewer/dist/cjs/telerik-report-viewer.component';

@NgModule({
  imports: [
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CheckboxModule,
    ConfirmDialogModule,
    DividerModule,
    ButtonModule,
    SplitButtonModule,
    RadioButtonModule,
    MenubarModule,
    ListboxModule,
    TableModule,
    DropdownModule,
    ProgressSpinnerModule,
    BadgeModule,
    PanelModule,
    ToolbarModule,
    CardModule,
    CommonModule,
    MultiSelectModule,
    SliderModule,
    InputMaskModule,
    CalendarModule,
    ToastModule,
    TabViewModule,
    DynamicDialogModule,
    FileUploadModule,
    PickListModule,
    PaginatorModule,
    WebcamModule,
    OverlayPanelModule,
    HighchartsChartModule,
    InputSwitchModule,
    AutoCompleteModule,
    DialogModule,
    VirtualScrollerModule,
    NgScrollbarModule,
    GoogleMapsModule,
    TimelineModule,
    HttpClientJsonpModule,
    // OrganizationChartModule,
    TreeModule,
    FocusTrapModule,
    NgxPhotoEditorModule,
    NgOptimizedImage,
    SelectButtonModule,
    NgxCurrencyDirective,
    EditorModule,
    HttpClientModule,
    BadgeModule, CommonModule, ToastModule, MessagesModule, RouterModule, AccordionModule,
    DropdownModule, FormsModule, ButtonModule, TooltipModule, NgOptimizedImage, AutoCompleteModule,
    StepsModule
  ],
  exports: [
    CommonModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CheckboxModule,
    ConfirmDialogModule,
    DividerModule,
    ButtonModule,
    SplitButtonModule,
    RadioButtonModule,
    MenubarModule,
    ListboxModule,
    TableModule,
    DropdownModule,
    ProgressSpinnerModule,
    BadgeModule,
    PanelModule,
    ToolbarModule,
    CardModule,
    MultiSelectModule,
    SliderModule,
    InputMaskModule,
    PaginatorModule,
    CalendarModule,
    ToastModule,
    PhonePipe,
    MatchTypeReplace,
    ToFixedPipe,
    StatePipe,
    FirstLetter,
    ToFixedCurrencyPipe,
    CamelcaseSpacePipe,
    StringReplacePipe,
    YesNoPipe,
    SafeHtmlPipe,
    TabViewModule,
    VirtualScrollerModule,
    DynamicDialogModule,
    FileUploadModule,
    PickListModule,
    WebcamModule,
    OverlayPanelModule,
    HighchartsChartModule,
    InputSwitchModule,
    AutoCompleteModule,
    SafeHtmlPipe,
    NgScrollbarModule,
    GoogleMapsModule,
    TimelineModule,
    HttpClientJsonpModule,
    TreeModule,
    FocusTrapModule,
    NgxPhotoEditorModule,
    NgOptimizedImage,
    SelectButtonModule,
    EditorModule,
    SelectComponent,
    MultiSelectComponent,
    BooleanCheckboxComponent,
    CurrencyInputComponent,
    DataIndicatorComponent,
    DynamicCustomFieldsComponent,
    LearnMoreComponent,
    ListFilterComponent,
    MultiSelectFilterListComponent,
    MultiSelectLanguagesComponent,
    MultiSelectUniversalComponent,
    PiiDuplicateDialogComponent,
    RecordNotFoundComponent,
    DistListSelectComponent,
    FilterMyDataComponent,
    YesNoSelectComponent,
    SelectComponent,
    UniversalDatePickerComponent,
    TextEditorComponent,
    HttpClientModule,
    LocalizeDateTimePipe,
    LastInLoopComponent,
    BadgeModule, CommonModule, ToastModule, MessagesModule, RouterModule, AccordionModule,
    DropdownModule, FormsModule, ButtonModule, TooltipModule, NgOptimizedImage, AutoCompleteModule,
    StepsModule,

  ],
  declarations: [
    PhonePipe,
    MatchTypeReplace,
    ToFixedPipe,
    ToFixedCurrencyPipe,
    CamelcaseSpacePipe,
    StringReplacePipe,
    YesNoPipe,
    StatePipe,
    SafeHtmlPipe,
    FirstLetter,
    SelectComponent,
    MultiSelectComponent,
    BooleanCheckboxComponent,
    CurrencyInputComponent,
    DataIndicatorComponent,
    DynamicCustomFieldsComponent,
    LearnMoreComponent,
    ListFilterComponent,
    MultiSelectFilterListComponent,
    MultiSelectLanguagesComponent,
    MultiSelectUniversalComponent,
    PiiDuplicateDialogComponent,
    RecordNotFoundComponent,
    DistListSelectComponent,
    FilterMyDataComponent,
    YesNoSelectComponent,
    UniversalDatePickerComponent,
    TextEditorComponent,
    LocalizeDateTimePipe,
    LastInLoopComponent
  ],
  providers: [
    DialogUtility,
    DialogService,
    DynamicDialogConfig,
    ConvertAuxFiltersUtility,
    DynamicDialogRef,
    RosterDefinitions,
    RosterStudentDefinitions,
    ProspectDefinitions,
    PhoneValidationUtility,
    InactiveStatusUtility,
    PhonePipe,
    DatePipe,
    CurrencyPipe,
    RouteContextUtility,
    PageService,
    DistributionListDefinitions,
    ConfirmValidationUtility,
    DateRangePickerDefinitions,
    ExemptUsersDefinitions,
    ToFixedCurrencyPipe,
    CamelcaseSpacePipe,
    StringReplacePipe,
    DymoLabelUtility,
    PageRowCountUtility,
    LocalizeDateTimePipe,
    ShortLocalizeDateTimePipe,
    DistListLookupService,
    FilterListLookupService,
    ReportListDefinitions,
    TenantFilterDefinitions,
    DecimalPipe,
    PercentPipe,
    PDFExportUtility,
    SnapshotDefinitions,
    InterfaceObjectHelpService,
    TenantConfigService,
    InterfaceObjectsService,
    LookupsService,
    InterfaceConfigService,
    TenantConfigDataService,
    DymoLabelUtility,
    HttpClient,
    TelerikReportViewerComponent,
    SidenavService, OrganizationService, AlertsService,
    AuthService, ConfirmationService, MessageService, HttpCacheService, BlobStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {provide: 'Window', useFactory: () => window}
  ]
})
export class SharedImports {
}
